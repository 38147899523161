import {
	LabelStatusThemesConfType,
} from '@components/label-status';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
const LabelStatusThemesConfs: LabelStatusThemesConfType[] = [
	{
		relatedText: null,
		icon: null,
		theme: EnumTheme.DEFAULT,
	},
	{
		relatedText: 'abandoned',
		icon: null,
		theme: EnumStatusTheme.ABANDONED,
	},
	{
		relatedText: 'archived',
		icon: null,
		theme: EnumStatusTheme.ABANDONED,
	},
	{
		relatedText: 'naked',
		icon: null,
		theme: EnumTheme.NAKED,
	},
	{
		relatedText: 'secondary',
		icon: null,
		theme: EnumTheme.SECONDARY,
	},
	{
		relatedText: 'info',
		icon: null,
		theme: EnumStatusTheme.INFO,
	},
	{
		relatedText: 'last_shipment',
		icon: null,
		theme: EnumStatusTheme.INFO,
	},
	{
		relatedText: 'new',
		icon: null,
		theme: EnumStatusTheme.INFO,
	},
	{
		relatedText: 'application_submitted',
		icon: null,
		theme: EnumStatusTheme.INFO,
	},
	{
		relatedText: 'bookmarked',
		icon: 'star',
		theme: EnumTheme.ELEVATION,
	},
	{
		relatedText: 'failed',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'application_refused',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'commercialized',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'error',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'refused',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'suspended',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'unavailable',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'visit_expired',
		icon: null,
		theme: EnumStatusTheme.ERROR,
	},
	{
		relatedText: 'active',
		icon: null,
		theme: EnumTheme.PRIMARY
	},
	{
		relatedText: 'application_accepted',
		icon: null,
		theme: EnumStatusTheme.SUCCESS,
	},
	{
		relatedText: 'application_signed',
		icon: null,
		theme: EnumStatusTheme.SUCCESS,
	},
	{
		relatedText: 'visit_planned',
		icon: null,
		theme: EnumStatusTheme.SUCCESS,
	},
	{
		relatedText: 'success',
		icon: null,
		theme: EnumStatusTheme.SUCCESS,
	},
	{
		relatedText: 'successful',
		icon: null,
		theme: EnumStatusTheme.SUCCESS,
	},
	{
		relatedText: 'waiting_for_visit',
		icon: null,
		theme: EnumStatusTheme.SUCCESS,
	},
	{
		relatedText: 'visit_visited',
		icon: null,
		theme: EnumTheme.TERTIARY,
	},
	{
		relatedText: 'ended',
		icon: null,
		theme: EnumTheme.TERTIARY,
	},
	{
		relatedText: 'updated',
		icon: null,
		theme: EnumStatusTheme.UPDATE,
	},
	{
		relatedText: 'pending',
		icon: null,
		theme: EnumStatusTheme.WAIT,
	},
	{
		relatedText: 'wait',
		icon: null,
		theme: EnumStatusTheme.WAIT,
	},
	{
		relatedText: 'visit_to_plan',
		icon: null,
		theme: EnumStatusTheme.WARNING,
	},
	{
		relatedText: 'application_to_submit',
		icon: null,
		theme: EnumStatusTheme.WARNING,
	},
	{
		relatedText: 'under_option',
		icon: null,
		theme: EnumStatusTheme.WARNING,
	},
	{
		relatedText: 'under_promise',
		icon: null,
		theme: EnumStatusTheme.WARNING,
	},
	{
		relatedText: 'warning',
		icon: null,
		theme: EnumStatusTheme.WARNING,
	},
];

export default LabelStatusThemesConfs;
