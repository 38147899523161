import * as React from 'react';

import {
	ReactNode,
} from 'react';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	children?: ReactNode;
	className?: string;
	'data-testid'?: string;
}

const Header = ({
	children,
	className,
	'data-testid': dataTestid,
}: HeaderProps): JSX.Element => {
	const classNames = [
		styles.Header
	];
	if (className) classNames.push(className);
	return (
		<div
			className={classNames.join(' ')}
			data-testid={dataTestid}
		>
			{children}
		</div>
	);
};

export {
	Header as default,
	HeaderProps,
};
