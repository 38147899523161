import * as React from 'react';

import {
	CSSProperties,
	MouseEvent,
	RefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import Map, {
	AttributionControl,
	GeolocateControl,
	Layer,
	MapRef,
	NavigationControl,
	Popup,
	Source,
} from 'react-map-gl';
import {
	Event,
	MapMouseEvent,
} from 'mapbox-gl';

import gjv from 'geojson-validation';

//TYPES
import {
	Offer,
} from '@@types/index';

import {
	GeoJsonProperties,
	Geometry,
} from 'geojson';

// STYLES
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './cartography.module.scss';

// CONFIGS
import getCirclesLayerConfig from '@static_data/layers/circles.layer.config';
import getCirclesShadowsLayerConfig from '@static_data/layers/circles-shadows.layer.config';
import getCirclesAlwaysActiveLayerConfig from '@static_data/layers/circles-always-active.layer.config';
import getCirclesClustersLayerConfig from '@static_data/layers/circles-clusters.layer.config';
import getCirclesClustersCountersLayerConfig from '@static_data/layers/circles-clusters-counters.layer.config';
import getTilesSourceConfig from '@static_data/layers/tiles.source.config';
import getTilesLayerConfig from '@static_data/layers/tiles.layer.config';

// MODULES
import mapUtils from '@modules/mapUtils';

// COMPONENTS
import CartographyPopup from '@components/cartography-popup';

interface ColorsProps {
	black?: string;
	electric_violet?: string;
	boulder?: string;
	mine_shaft?: string;
	white?: string;
}

interface LayerConfigSourceProps {
	id?: string;
	tiles?: Array<string>;
	tileSize?: number;
	type: 'raster';
}

interface FeatureProps {
	properties?: {
		offer_id?: number;
	};
	id?: string;
}

interface EventProps extends Event {
	features?: {
		[key: string]: FeatureProps;
	};
	point?: string;
}
interface CartographyProps {
	className?: string;
	'data-testid'?: string;
	config?: {
		accessToken?: string;
		coordinates: number[];
		style?: string;
		zoom?: number;
	};
	copyright?: {
		href?: string;
		text?: string;
	};
	geojsonSource?: GeoJsonProperties;
	innerRef?: RefObject<MapRef>;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	onClickMarker?: (event: EventProps) => void;
	onClickPopup?: (event: MouseEvent<HTMLElement>) => void;
	onClosePopup?: () => void;
	onMouseMove?: (event: EventProps) => void;
	onLoad?: (event: Event) => void;
	onRender?: (event: MouseEvent<HTMLElement>) => void;
	popupData?: Offer;
	style?: CSSProperties;
	tilesLayerData?: string[];
	tilesToDisplay?: string;
}

function Cartography({
	className,
	'data-testid': dataTestid,
	config = {
		zoom: 15,
		coordinates: [
		]
	},
	copyright,
	geojsonSource,
	innerRef,
	onClickMarker,
	onClickPopup,
	onClosePopup,
	onMouseMove,
	onLoad,
	popupData,
	style,
	tilesLayerData,
	tilesToDisplay,
}: CartographyProps) {
	const { getBounds } = mapUtils;
	const mapRefDefault = useRef(null);
	const mapRef = innerRef || mapRefDefault;
	// Var used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const geoJsonValidated = gjv.valid(geojsonSource?.data) ? geojsonSource.data : null;
	// Var used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const geoJsonValidatedType = geoJsonValidated ? geoJsonValidated.type : null;

	const [
		popupState,
		setPopupState
	] = useState(null);

	const [
		flyingEnding,
		setFlyingEnding
	] = useState(null);

	const colors = {
		black: '#000000',
		electric_violet: '#6200EE',
		boulder: '#797676',
		mine_shaft: '#393939',
		white: '#FFFFFF',
	};

	const layersIds = [
		`${geojsonSource?.name}_circles`,
		`${geojsonSource?.name}_clusters`
	];

	const classes = [
		styles.cartography
	];
	if (className) classes.push(className);

	const circlesLayer = getCirclesLayerConfig(geojsonSource?.name, colors);
	const circlesShadowsLayer = getCirclesShadowsLayerConfig(geojsonSource?.name, colors);
	const circlesAlwaysActiveLayer = getCirclesAlwaysActiveLayerConfig(geojsonSource?.name, colors);
	const circlesClustersLayer = getCirclesClustersLayerConfig(geojsonSource?.name, colors);
	const circlesClustersCountersLayer = getCirclesClustersCountersLayerConfig(geojsonSource?.name, colors);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const zoomToBounds = (featuresList: string) => {
		if (!Array.isArray(featuresList) || !featuresList.length) return;
		/* A reference to the mapbox map object. */
		if (mapRef.current) {
			const bounds = getBounds(featuresList);
			mapRef.current.fitBounds(bounds, {
				maxZoom: 20,
				padding: 100,
				linear: true
			});
		}
	};

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const activeMarker = (id: string) => {
		mapRef?.current?.removeFeatureState({
			source: geojsonSource.name
		});
		mapRef?.current?.setFeatureState({
			source: geojsonSource.name,
			id: id
		}, {
			active: true,
			hover: true
		});
	};

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const resetPositions = () => {
		mapRef?.current?.removeFeatureState({
			source: geojsonSource.name
		});
		setPopupState(null);
	};

	// Function to center the map on a single coordinate
	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const centerMapOnCoordinates = (coordinates: number[], popupActive = false, zoom = 15) => {
		// The mapRef does not exist before first render
		const flyToConfig = {
			center: coordinates,
			essential: true,
			duration: 500, // Animate over 0.5 seconds,
			offset: [
				0,
				popupActive ? 120 : 0,
			],
			zoom: zoom,
			padding: 50,
			easing(loading: number) {
				if (loading === 1) {
					setFlyingEnding(true);
				}
				return loading;
			}
		};
		if (mapRef.current) {
			mapRef.current.flyTo(flyToConfig);
		}
	};

	useEffect(() => {
		// Condition met only with the help of lib component props which can't be rendered in Jest JsDom env
		/* istanbul ignore next */
		if (popupData) {
			const coordinates = popupData?.building?.geo_json?.geometry?.coordinates || [
			];
			const lngLat = {
				lng: coordinates[0] || null,
				lat: coordinates[1] || null,
			};
			const newState = {
				properties: popupData || null,
				lngLat: lngLat
			};
			setPopupState(newState);
		} else {
			handleOnClosePopup();
		}
	}, [
		popupData
	]);

	useEffect(() => {
		// Condition met only with the help of lib component props which can't be rendered in Jest JsDom env
		/* istanbul ignore next */
		if (popupState?.lngLat?.lng) {
			centerMapOnCoordinates(popupState.lngLat, true);
		}
	}, [
		popupState
	]);

	useEffect(() => {
		// Condition met only with the help of lib component props which can't be rendered in Jest JsDom env
		/* istanbul ignore next */

		if (mapRef.current) {
			if (geoJsonValidatedType === 'Feature') {
				centerMapOnCoordinates(geoJsonValidated.geometry.coordinates);
			}
		}
	}, [
		geojsonSource,
	]);

	useEffect(() => {
		// Condition met only with the help of lib component props which can't be rendered in Jest JsDom env
		/* istanbul ignore next */
		if (popupState && flyingEnding) {
			setFlyingEnding(false);
			const features = mapRef.current.queryRenderedFeatures(null, {
				layers: [
					`${geojsonSource?.name}_circles`
				]
			});
			const featureList = features.filter((feature: FeatureProps) => feature.properties.offer_id === popupState.properties.id);
			if (featureList.length) activeMarker(featureList[0].id);
		}
	}, [
		flyingEnding
	]);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const handleOnClick = useCallback(() => {
		mapRef.current.once('click', `${geojsonSource?.name}_circles`, (event: EventProps) => {
			onClickMarker ? onClickMarker(event) : null;
		});
		mapRef.current.once('click', `${geojsonSource?.name}_clusters`, (event: EventProps) => {
			if (onClickMarker) {
				const features = mapRef.current.queryRenderedFeatures(event.point, {
					layers: [
						`${geojsonSource?.name}_clusters`
					]
				});
				const clusterId = features[0].properties.cluster_id;
				mapRef.current.getSource(geojsonSource?.name).getClusterExpansionZoom(
					clusterId,
					(err: string, zoom: number) => {
						if (err) return;

						mapRef.current.easeTo({
							center: features[0].geometry.coordinates,
							zoom: zoom
						});
					}
				);
			}
		});
	}, [
	]);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const handleOnClosePopup = () => {
		if (onClosePopup) onClosePopup();
		resetPositions();
	};

	let id: string = null;
	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const handleOnMouseMove = useCallback((e: MapMouseEvent | EventProps) => {
		if (id !== null) {
			const feature = mapRef?.current?.getFeatureState({
				source: geojsonSource.name,
				id: id
			});
			if (!feature.active) {
				mapRef?.current?.setFeatureState({
					source: geojsonSource.name,
					id: id
				}, {
					hover: false
				});
			}

			id = null;
		}
		if (mapRef && e?.features?.length && (e as EventProps)?.features[0]?.properties?.offer_id) {
			id = (e as EventProps).features[0].id;
			mapRef.current.setFeatureState({
				source: geojsonSource.name,
				id: id
			}, {
				hover: true
			});
		}
		if (onMouseMove) onMouseMove(e as EventProps);
	}, [
	]);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const handleOnLoad = (event: Event) => {
		// no zoom to bounds if a popup is opened
		if (mapRef.current) {
			if (popupState) {
				const features = mapRef.current.queryRenderedFeatures(null, {
					layers: [
						`${geojsonSource?.name}_circles`
					]
				});
				const featureList = features.filter((feature: FeatureProps) => feature.properties.offer_id === popupState.properties.id);
				if (featureList.length) activeMarker(featureList[0].id);
			} else {
				if (geoJsonValidatedType === 'FeatureCollection') {
					zoomToBounds(geoJsonValidated.features as string);
				} else if (geoJsonValidatedType === 'Feature') {
					centerMapOnCoordinates(geoJsonValidated.geometry.coordinates);
				}
			}
		}
		if (onLoad) onLoad(event);
	};

	// Function is triggered by the lib so we can't test it
	/* istanbul ignore next */
	const handleOnResize = () => {
		// no zoom to bounds if a popup is opened
		if (!popupState && geoJsonValidatedType === 'FeatureCollection') {
			zoomToBounds(geoJsonValidated.features as string);
		}
	};

	// Function is triggered by the lib so we can't test it
	/* istanbul ignore next */
	const handleOnClickPopup = (event: MouseEvent<HTMLElement>) => {
		onClickPopup(event);
	};

	// Component from lib which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const popupElement = popupState ? (
		<Popup
			anchor="bottom"
			latitude={popupState.lngLat.lat}
			longitude={popupState.lngLat.lng}
			onClose={handleOnClosePopup}
		>
			<CartographyPopup
				properties={popupState.properties}
				onClick={handleOnClickPopup}
			/>
		</Popup>
	) : null;

	// Element used into lib component which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const copyrightElement = copyright ? `<a href="${copyright?.href || '/'}" target="_blank">${copyright?.text || 'copyright'}</a>` : null;
	// Element used into lib component which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const attributionControlElement = copyrightElement ? <AttributionControl customAttribution={copyrightElement} /> : null;
	const sourcesLayerTiles = tilesLayerData?.length ? tilesLayerData.map((tile, index) => {
		const tileSourceConfig = getTilesSourceConfig(tile);
		const visible = tilesToDisplay?.includes(tile) ? 'visible' : 'none';
		const tileLayerConfig = getTilesLayerConfig(tile, visible);
		return (
			<Source
				{...tileSourceConfig}
				key={index}
			>
				<Layer
					{...tileLayerConfig}
				/>
			</Source>
		);
	}) : null;
	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			<Map
				attributionControl={false}
				data-testid={`${dataTestid}-map`}
				initialViewState={{
					longitude: config.coordinates?.length ? config.coordinates[0] : null,
					latitude: config.coordinates?.length ? config.coordinates[1] : null,
					zoom: config.zoom
				}}
				interactiveLayerIds={layersIds}
				mapboxAccessToken={config?.accessToken}
				mapStyle={config?.style}
				ref={mapRef}
				style={style}
				onClick={handleOnClick}
				onLoad={handleOnLoad}
				onMouseMove={handleOnMouseMove}
				onResize={handleOnResize}
			>
				{attributionControlElement}
				<GeolocateControl />
				<NavigationControl />
				{sourcesLayerTiles}
				<Source
					cluster={geoJsonValidatedType === 'FeatureCollection'}
					clusterMaxZoom={14}
					clusterRadius={50}
					data={geoJsonValidated as Geometry}
					generateId={true}
					id={geojsonSource?.name}
					type="geojson"
				>
					<Layer {...circlesShadowsLayer} />
					{ /* Var used into lib component props which can't be rendered in Jest JsDom env */}
					{/* istanbul ignore next */ geoJsonValidatedType === 'FeatureCollection' ? <Layer {...circlesLayer} /> : null}
					{/* istanbul ignore next */ geoJsonValidatedType === 'Feature' ? <Layer {...circlesAlwaysActiveLayer} /> : null}
					<Layer {...circlesClustersLayer} />
					<Layer {...circlesClustersCountersLayer} />
					{popupElement}
				</Source>
			</Map>
		</div>
	);
}

export {
	Cartography as default,
	CartographyProps,
	ColorsProps,
	EventProps,
	LayerConfigSourceProps,
};
