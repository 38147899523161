import {
	EnumFontStyle,
} from '@enums/font.enum';
import React, {
	MouseEvent,
} from 'react';

// GET the icon name list from JSON generated with 'npm run icons' command
import ICONS_light from '@assets/fonts/lpdi-webfont-icons-light/lpdi-webfont-icons-light.json';
import ICONS_regular from '@assets/fonts/lpdi-webfont-icons-regular/lpdi-webfont-icons-regular.json';
import ICONS_solid from '@assets/fonts/lpdi-webfont-icons-solid/lpdi-webfont-icons-solid.json';

import styles from './icon.module.scss';

const availableStyles = [
	{
		name: EnumFontStyle.LIGHT,
		map: ICONS_light,
	},
	{
		name: EnumFontStyle.REGULAR,
		map: ICONS_regular,
	},
	{
		name: EnumFontStyle.SOLID,
		map: ICONS_solid,
	}
];

export interface IconProps {
	'aria-hidden'?: string;
	'aria-label'?: string;
	animation?: string;
	className?: string;
	'data-testid'?: string;
	'data-toggled'?: boolean;
	name?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	fontStyle?: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
}

const Icon = ({
	'aria-hidden': ariaHidden,
	'aria-label': ariaLabel,
	animation,
	className,
	'data-toggled': dataToggled,
	'data-testid': dataTestid,
	name = 'question-square',
	onClick,
	fontStyle = EnumFontStyle.REGULAR,
}: IconProps): JSX.Element => {
	let iconExists = false;
	const styleFromAvailables = availableStyles.filter(availableStyle => availableStyle.name === fontStyle);
	const iconStyleWeight = styles[`icon__style_${fontStyle}`];
	const iconMap = styleFromAvailables[0]?.map;

	// Loop through the icon name list to check if it exists
	Object.keys(iconMap).forEach(iconName => {
		if (!iconExists) {
			iconExists = iconName === name;
		}
	});

	const cssClasses = [
		styles.icon,
		iconStyleWeight
	];

	if (name && iconExists) {
		cssClasses.push(styles[`icon__${name}__${fontStyle}`]);
	} else {
		// If the icon name doesn't exists we display a default icon with an interogation point in a square like in Mario Bros®
		cssClasses.push(styles[`${'icon__question-square__regular'}`]);
	}

	if (onClick) {
		cssClasses.push(styles.icon__pointer);
	}
	if (animation) {
		cssClasses.push(`animation__${animation}`);
	}

	if (className) cssClasses.push(className);

	return (
		<i
			aria-hidden={ariaHidden || 'true'}
			aria-label={ariaLabel || `icon ${name}`}
			className={`${cssClasses.join(' ')}`}
			data-testid={dataTestid}
			data-toggled={dataToggled}
			onClick={onClick}
		/>
	);
};

export default Icon;
