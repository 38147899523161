import {
	Contact,
	ContactJson,
	User,
	UserJson,
} from '@@types/index';

export interface SuggestedContactForVisitJson {
	broker: UserJson;
	visited_at: string;
}

export interface SuggestedContactForVisit {
	broker: string;
	visited_at: string;
}

export interface ContactDemandJson extends ContactJson {
	suggested_contact_for_visit?: SuggestedContactForVisitJson;
}

export class ContactDemand extends Contact {
	public contactDemand: ContactDemandJson;
	public language: string;

	constructor(
		contactDemand: ContactDemandJson,
		language: string
	) {
		super({
			civility_key: contactDemand.civility_key,
			company_name: contactDemand.company_name,
			email: contactDemand.email,
			enable: contactDemand.enable,
			firstname: contactDemand.firstname,
			full_name: contactDemand.full_name,
			id: contactDemand.id,
			job: contactDemand.job,
			lastname: contactDemand.lastname,
			logo: contactDemand.logo,
			mobile: contactDemand.mobile,
			phone: contactDemand.phone,
		});
		this.contactDemand = contactDemand;
		this.language = language;
	}

	get suggested_contact_for_visit(): SuggestedContactForVisit | undefined {
		return this.contactDemand.suggested_contact_for_visit ? {
			broker: new User(this.contactDemand.suggested_contact_for_visit.broker).full_name,
			visited_at: new Date(this.contactDemand.suggested_contact_for_visit.visited_at).toLocaleDateString(this.language),
		} : undefined;
	}

}
