// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fFmEwjCe{align-items:center;background-color:var(--color-white);box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);display:flex;min-height:4.28571429rem;padding:0.85714286rem 1.14285714rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.fFmEwjCe{border-radius:0.57142857rem;padding:1rem 1.42857143rem}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action_bar": `fFmEwjCe`
};
export default ___CSS_LOADER_EXPORT___;
