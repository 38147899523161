import {
	Price,
} from '@@types/Price';

export enum EnumPriceTypes {
	RENTAL = 'rental',
	SALE = 'sale',
}

import i18n from '@modules/i18n';

const getOfferPrice = (price: Price, state: string) => {
	let unitPriceText = null;
	if (price?.frequency) {
		if (price?.unit) {
			unitPriceText = i18n.t(`format.per.${price.frequency}`, {
				text: i18n.t(`format.per.${price.unit}`, {
					text: ''
				})
			});
		} else {
			unitPriceText = i18n.t(`format.per.${price.frequency}`, {
				text: ''
			});
		}
	} else if (price?.unit) {
		unitPriceText = i18n.t(`format.per.${price.unit}`, {
			text: ''
		});
	}

	let chargesText;
	if (price?.amount) {
		if (price?.are_charges_included !== undefined) {
			chargesText = ` ${i18n.t(`format.${price.are_charges_included ? 'included_charges' : 'excluded_charges'}`, {
				text: i18n.t(`format.tax_type.${price.tax_type}`, {
					text: ''
				})
			})}`;
		} else {
			chargesText = ` ${i18n.t(`format.tax_type.${price.tax_type}`, {
				text: ''
			})}`;
		}
	}
	const amountText = price?.amount ? (
		i18n.t('format.price', {
			val: price.amount
		})
	) : null;

	return {
		unit: unitPriceText,
		amount: amountText,
		chargesText: chargesText,
		state: amountText ? null : i18n.t('format.capitalize', {
			text: i18n.t(`general.${state}`)
		})
	};
};

export default getOfferPrice;
