import {
	createSlice,
} from '@reduxjs/toolkit';

export interface MapSliceInstance {
	instance: MapInstance;
}
export interface MapInstance {
	coordinates: {
		lat: number;
		lng: number;
	};
	displayCoordinates: boolean;
	isChanging: boolean;
	layers: object[];
	map?: object[];
	zoom: number;
}

const mapSlice = createSlice({
	name: 'device',
	initialState: {
		instance: {
			coordinates: {
				lat: 48.85635302576033,
				lng: 2.352894817894053,
			},
			displayCoordinates: false,
			isChanging: false,
			layers: {
			},
			map: null,
			zoom: 10,
		}
	},
	reducers: {
		mapIsChanging: (state, action) => {
			state.instance = {
				...state.instance,
				isChanging: action.payload
			};
		},
		mapUpdateCoordinates: (state, action) => {
			state.instance = {
				...state.instance,
				coordinates: action.payload
			};
		},
		mapUpdateLayers: (state, action) => {
			state.instance = {
				...state.instance,
				layers: action.payload
			};
		},
		mapUpdateZoom: (state, action) => {
			state.instance = {
				...state.instance,
				zoom: action.payload
			};
		},
		mapToggleCoordinatesDisplay: (state) => {
			state.instance = {
				...state.instance,
				displayCoordinates: !state.instance.displayCoordinates
			};
		},
	}
});

// Action creators are generated for each case reducer function
export const {
	mapIsChanging,
	mapToggleCoordinatesDisplay,
	mapUpdateCoordinates,
	mapUpdateLayers,
	mapUpdateZoom
} = mapSlice.actions;

export default mapSlice.reducer;
