import * as React from 'react';
import {
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

//COMPONENTS
import Button, {
	ButtonProps,
} from '@components/button';
import ButtonWithDropdown, {
	type ButtonWithDropdownProps,
} from '@components/button-with-dropdown';

//STYLES
import styles from './button-list.module.scss';

interface ButtonListProps {
	items?: ButtonProps[] | ButtonWithDropdownProps[];
	className?: string;
	'data-testid'?: string;
	tooltipIsActive?: boolean;
	onClick?: (event: MouseEvent<HTMLElement>, button: ButtonProps) => void;
}

const ButtonList = ({
	items,
	className,
	'data-testid': dataTestid,
	onClick,
	tooltipIsActive = false
}: ButtonListProps): JSX.Element => {
	let displayShowMore = null;
	let displayedButtons = null;
	let hiddenButtons = null;

	const classes = [
		styles.button_list
	];

	if (className) {
		classes.push(className);
	}

	const [
		showMore,
		setShowMore,
	] = useState(false);

	const handleClickOnMoreLessButton = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setShowMore(!showMore);
	};

	const handleOnClickButton = (event: MouseEvent<HTMLElement>, button: ButtonProps | ButtonWithDropdownProps) => {
		if (button.onClick) button.onClick(event);
		if (onClick) onClick(event, button);
	};

	if (items?.length) {
		const buttonWithAccess = (items as ButtonProps[]).filter((button: ButtonProps) => button.hasAccess);
		const buttonWithoutAccess = (items as ButtonProps[]).filter((button: ButtonProps) => !button.hasAccess);
		const displayAllDisabledButtons = buttonWithAccess.length === 0;
		const displayedButtonsConf = displayAllDisabledButtons ? items : buttonWithAccess;
		const hiddenButtonsConf = displayAllDisabledButtons ? [
		] : buttonWithoutAccess;

		displayShowMore = hiddenButtonsConf.length;

		displayedButtons = displayedButtonsConf.map((button: ButtonProps | ButtonWithDropdownProps, index: number) => {
			const classesDisplayedButtons = [
				styles.element
			];
			if (button.className) classesDisplayedButtons.push(button.className);
			const buttonElement = (

				<Button
					{...button as ButtonProps}
					className={classesDisplayedButtons.join(' ')}
					data-testid={`${dataTestid}-button`}
					key={index}
					tooltipText={tooltipIsActive ? button.tooltipText : undefined}
					onClick={(event) => handleOnClickButton(event, button)}
				/>
			);

			const buttonWithDropdownElement = (
				<ButtonWithDropdown
					{...button as ButtonWithDropdownProps}
					className={classesDisplayedButtons.join(' ')}
					data-testid={`${dataTestid}-button-with-dropdown`}
					key={index}
					tooltipText={tooltipIsActive ? button.tooltipText : undefined}
				/>
			);

			return (button as ButtonWithDropdownProps)?.items ? buttonWithDropdownElement : buttonElement;
		});

		hiddenButtons = hiddenButtonsConf.map((button: ButtonProps | ButtonWithDropdownProps, index: number) => {
			const classesHiddenButtons = [
				styles.element
			];
			if (button.className) classesHiddenButtons.push(button.className);
			const buttonElement = (

				<Button
					{...button}
					className={classesHiddenButtons.join(' ')}
					data-testid={`${dataTestid}-button`}
					key={index}
					tooltipText={tooltipIsActive ? button.tooltipText : undefined}
				/>
			);
			const buttonWithDropdownElement = (

				<ButtonWithDropdown
					{...button as ButtonWithDropdownProps}
					className={classesHiddenButtons.join(' ')}
					data-testid={`${dataTestid}-button-with-dropdown`}
					key={index}
				/>
			);
			return showMore ? ((button as ButtonWithDropdownProps)?.items ? buttonWithDropdownElement : buttonElement) : null;
		});
	}

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{displayedButtons}
			{hiddenButtons}
			{displayShowMore ? (
				<Button
					aria-pressed={showMore}
					corners={EnumButtonCorners.Square}
					data-testid={`${dataTestid}-showMore`}
					iconLeft={showMore ? 'minus' : 'plus'}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.TERTIARY}
					onClick={handleClickOnMoreLessButton}
				/>
			) : null}
		</div>
	);
};

export {
	ButtonList as default,
	ButtonListProps,
	ButtonWithDropdownProps,
};
