import * as React from 'react';
import {
	cloneElement,
	ComponentType,
	createElement,
	Dispatch,
	forwardRef,
	ChangeEvent,
	MouseEvent,
	MutableRefObject,
	ReactElement,
	ReactNode,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	ColumnDef,
	Cell,
	Table as TableDef,
	Column,
	getCoreRowModel,
	getExpandedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	Row,
	useReactTable,
	sortingFns,
	SortingState,
} from '@tanstack/react-table';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumInputSize,
} from '@enums/form.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

import {
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import ActionBar from '@components/action-bar';
import Button, {
	ButtonProps,
} from '@components/button';
import InputCheckbox, {
	InputCheckboxProps,
} from '@components/form/input-checkbox';
import Link from '@components/link';
import InputSelect, {
	InputSelectProps,
} from '@components/form/input-select';
import TableRow from '@components/table/row';

// STYLE
import styles from './table.module.scss';

type ComponentActions<TData> = (row: Row<TData>) => ReactElement;
type FilterFnCustom<TData> = (row: Row<TData>) => boolean;
interface ColumnFilter {
	id: string;
	value: string | number | boolean;
}
export type ColumnFilters = Array<ColumnFilter>;

interface TableProps<TData> {
	actionBarClassName?: string;
	actions?: {
		desktop?: {
			items?: ButtonProps[] | ComponentActions<TData>[];
			title?: string;
		};
		mobile?: {
			items?: ButtonProps[] | ComponentActions<TData>[];
			title?: string;
		};
	};
	className?: string;
	columnFilters?: ColumnFilters;
	columns?: ColumnProps<TData>[];
	configurationActionBar?: {
		type?: string;
		actions?: JSX.Element;
	};
	customNoResult?: ReactNode;
	customNoResultfilter?: ReactNode;
	data?: TData[];
	'data-testid'?: string;
	filterFns?: FilterFnCustom<TData>;
	getRowCanExpand?: (row: Row<TData>) => boolean;
	globalFilter?: string;
	identifier: string;
	onDataFiltered?: (data: Row<TData>[]) => void;
	options?: {
		canSelect?: boolean;
		hasHeader?: boolean;
		initialSort?: SortingState;
		mobile?: {
			nbDataToDisplay: number;
		};
		onSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
		pagination?: {
			nbItemsPerPage: number;
			onChange?: (event: MouseEvent<HTMLElement>) => void;
		};
		sort?: string[];
		topChildren?: ReactElement;
		topRightChildren?: ReactElement;
	};
	reinitSelection?: boolean;
	renderSubComponent?: (props: {
		canSelect?: boolean;
		checkboxElement?: ReactElement;
		index: number;
		isSelected?: boolean;
		key: number;
		original?: Row<TData>['original'];
		row?: Row<TData>;
	}) => ReactElement;
	setComponentSelectedIds?: Dispatch<SetStateAction<number[]>>;
	setReinitSelection?: Dispatch<SetStateAction<boolean>>;
	theme?: 'default' | 'column';
}

type Props = {
	className?: string;
	id?: string;
	indeterminate?: boolean;
	name?: string;
	title?: string;
};

type CellProps<TData> = {
	table: TableDef<TData>;
	row: Row<TData>;
	column: Column<TData>;
	cell: Cell<TData, unknown>;
}

type ColumnProps<TData> = ColumnDef<TData> & {
	legend?: string;
}

const Table = <TData extends object>({
	actionBarClassName,
	actions,
	className,
	columnFilters,
	columns = [
	],
	configurationActionBar,
	customNoResult,
	'data-testid': dataTestid,
	data = [
	],
	filterFns,
	getRowCanExpand,
	globalFilter,
	identifier,
	onDataFiltered,
	options = {
		canSelect: false,
		hasHeader: true,
		mobile: {
			nbDataToDisplay: 3,
		},
		pagination: {
			nbItemsPerPage: 10,
		},
		sort: [
		],
	},
	reinitSelection = false,
	renderSubComponent = null,
	setComponentSelectedIds,
	setReinitSelection,
	theme = 'default',
}: TableProps<TData>) => {
	const { t } = useTranslation();

	const localData = useMemo(() => data, [
		data
	]);

	options = {
		canSelect: false,
		hasHeader: true,
		mobile: {
			nbDataToDisplay: 3
		},
		pagination: {
			nbItemsPerPage: 10
		},
		sort: [
		],
		...options
	};

	const localColumns = useMemo(() => columns, [
	]);

	const initialSort = options?.initialSort ? options.initialSort : [
	] as SortingState;

	const [
		sorting,
		setSorting
	] = useState(initialSort);

	useEffect(() => {
		setSorting(initialSort);
	}, [
		options?.initialSort
	]);

	const [
		showCheckAllLink,
		setShowCheckAllLink
	] = useState(false);

	const [
		isSelectionChecked,
		setIsSelectionChecked
	] = useState(false);

	const [
		rowSelection,
		setRowSelection
	] = useState({
	});

	useEffect(() => {
		if (reinitSelection) {
			setReinitSelection(false);
			toggleAllRowsSelected(false);
		}
	}, [
		reinitSelection
	]);
	const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
		({
			indeterminate,
			...rest
		}, ref) => {
			const defaultRef = useRef();
			const resolvedRef = (ref || defaultRef) as MutableRefObject<HTMLInputElement>;

			useEffect(() => {
				resolvedRef.current.indeterminate = indeterminate;
			}, [
				resolvedRef,
				indeterminate
			]);

			return (
				<InputCheckbox
					{...rest}
					innerRef={resolvedRef}
					name={rest['name']}
					onChange={(event) => {
						(rest as InputCheckboxProps).onChange(event);

						if (options?.onSelect) options?.onSelect(event);
					}}
				/>
			);
		}
	);

	// function to generate action buttons/links/... in column
	const getActionContent = (elementProps: ButtonProps, row: Row<TData>, key: number) => {
		let newContent = null;
		const defaultTypeConf = {
			type: Button,
			name: 'button'
		};
		const allowedActionTypes = [
			defaultTypeConf,
			{
				type: Link,
				name: 'link'
			}
		];
		const elementType = elementProps.type || defaultTypeConf.name;
		const isType = allowedActionTypes.filter(allowedActionType => {
			return allowedActionType.name === elementType;
		});
		if (isType.length) {
			newContent = createElement(isType[0].type as ComponentType, {
				...{
					...elementProps,
					onClick: (e) => {
						elementProps.onClick(e, row);
					},
					key: `${row.index}__${key}`,
					id: key
				}
			});
		}
		return newContent;
	};

	const getColumns = (columns: TableProps<TData>['columns']) => {
		if (options.canSelect) {
			// Let's make a column for selection
			const selectionColumn = [
				{
					id: 'selection',
					type: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					header: ({ table }: CellProps<TData>) => {
						return (
							<div>
								<IndeterminateCheckbox
									{...{
										checked: table.getIsAllPageRowsSelected(),
										disabled: getPrePaginationRowModel().rows.length === 0,
										indeterminate: table.getIsSomeRowsSelected(),
										onChange: table.getToggleAllPageRowsSelectedHandler(),
									}}
									data-testid={`${dataTestid}_selection_all`}
									id={`selection_all_${identifier}`}
									name={`selection_all_${identifier}`}
								/>
							</div>
						);
					},
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					cell: ({ row }: CellProps<TData>) => {
						return (
							<div>
								<IndeterminateCheckbox
									{...{
										checked: row.getIsSelected(),
										disabled: !row.getCanSelect(),
										indeterminate: row.getIsSomeSelected(),
										onChange: row.getToggleSelectedHandler(),
									}}
									data-testid={`${dataTestid}_selection_${row.id}`}
									id={`selection_${identifier}_${row.id}`}
									name={`selection_${identifier}_${row.id}`}
									title={t('general.action.select')}
								/>
							</div>
						);
					},
				}
			];

			columns = [
				...selectionColumn,
				...(columns as TableProps<TData>['columns'])
			] as TableProps<TData>['columns'];
		}

		const addKeyProps = (component: ReactElement, props: { key: number }) => {
			return cloneElement(component, {
				...props
			});
		};

		// actions column for desktop display
		if (actions?.desktop?.items.length) {
			const desktopActionColumnConf = {
				id: 'desktopActions',
				type: 'desktopActions',
				accessorKey: 'desktopActions',
				header: actions?.desktop?.title || '',
				cell: ({ row }: CellProps<TData>) => {
					return (
						<span>
							{actions.desktop.items.map((desktopActionElement, key) => {
								let newDesktopActionElement = null;
								if (typeof desktopActionElement === 'function') {
									newDesktopActionElement = addKeyProps(desktopActionElement(row), {
										key: key
									});
								} else {
									newDesktopActionElement = getActionContent(desktopActionElement, row, key);
								}
								return newDesktopActionElement;
							})}
						</span>
					);
				},
			};

			const desktopActionsColumn = [
				desktopActionColumnConf
			];

			columns = [
				...columns,
				...desktopActionsColumn
			];
		}

		// actions column for mobile display
		if (actions?.mobile?.items.length) {
			const mobileActionsColumnConf = {
				id: 'mobileActions',
				type: 'mobileActions',
				value: actions?.desktop?.title || '',
				header: 'mobile',
				cell: ({ row }: CellProps<TData>) => {
					return (
						<span>
							{actions?.mobile?.items.map((mobileActionElement, key) => {
								let newMobileActionElement = null;
								if (typeof mobileActionElement === 'function') {
									newMobileActionElement = addKeyProps(mobileActionElement(row), {
										key: key
									});
								} else {
									newMobileActionElement = getActionContent(mobileActionElement, row, key);
								}
								return newMobileActionElement;
							})}
						</span>
					);
				},
			};

			const mobileActionsColumn = [
				mobileActionsColumnConf
			];

			columns = [
				...columns,
				...mobileActionsColumn
			];
		}
		return columns;
	};

	const {
		getCanNextPage,
		getCanPreviousPage,
		getHeaderGroups,
		getPageCount,
		getPrePaginationRowModel,
		getRowModel,
		getState,
		nextPage,
		previousPage,
		setPageIndex,
		setPageSize,
		toggleAllRowsSelected,
		toggleAllPageRowsSelected,
	} = useReactTable({
		autoResetPageIndex: false,
		data: localData,
		columns: getColumns(localColumns),
		state: {
			rowSelection,
			sorting,
			columnFilters: columnFilters,
			globalFilter: globalFilter,
		},
		filterFns: {
			customfilterFns: (row) => filterFns(row)
		},
		sortingFns: sortingFns,
		onSortingChange: setSorting,
		getRowCanExpand,
		enableRowSelection: options.canSelect,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		// debugTable: true,
	});

	useEffect(() => {
		if (onDataFiltered) {
			onDataFiltered(getPrePaginationRowModel().rows);
		}
	}, [
		columnFilters,
		globalFilter
	]);

	// START PAGINATION
	const paginationShow = [
		10,
		20,
		30,
		40,
		50
	];

	const nbItemsPerPage = options.pagination.nbItemsPerPage;
	const paginationNumberFormated = nbItemsPerPage;
	paginationShow.forEach((number, index) => {
		if (paginationNumberFormated < number && !paginationShow.includes(paginationNumberFormated)) {
			paginationShow.splice(index, 0, paginationNumberFormated);
		}
	});

	const didLogRef = useRef(false);

	useEffect(() => {
		/* istanbul ignore next */
		if (didLogRef.current === false) {
			didLogRef.current = true;
			setPageSize(paginationNumberFormated);
		}
	}, [
	]);

	const handleOnClickPagination = (event: MouseEvent<HTMLElement>) => {
		if (options?.pagination?.onChange) options.pagination.onChange(event);
	};

	// PAGINATION ACTIONS CALLBACKS
	const handleOnClickOnFirstPage = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		handleOnClickPagination(event);
		setPageIndex(0);
	};
	const handleOnClickOnPreviousPage = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		handleOnClickPagination(event);
		previousPage();
	};
	const handleOnClickOnNextPage = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		handleOnClickPagination(event);
		nextPage();
	};
	const handleOnClickOnLastPage = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		handleOnClickPagination(event);
		setPageIndex(getPageCount() - 1);
	};

	const itemsLength = getPrePaginationRowModel().rows.length;

	const firstPageItemIndex = getState().pagination.pageIndex * getState().pagination.pageSize + 1;

	let lastPageItemIndex = null;
	if (getCanNextPage()) {
		lastPageItemIndex = getState().pagination.pageSize * getState().pagination.pageIndex + getState().pagination.pageSize;
	} else {
		lastPageItemIndex = getPrePaginationRowModel().rows.length;
	}

	const optionsSelect = [
	] as InputSelectProps['options'];
	paginationShow.forEach((pageSizeItem, index) => {
		optionsSelect.push({
			value: `${pageSizeItem}`,
			label: `${pageSizeItem}`,
		});
		if (paginationShow.length === index + 1) {
			optionsSelect.push({
				value: `${itemsLength}`,
				label: t('general.all', {
					context: 'female'
				})
			});
		}
	});

	const paginationElement = getPrePaginationRowModel().rows.length / getState().pagination.pageSize < 1 ? undefined : (
		<div
			className={styles.pagination}
			data-testid={`${dataTestid}_pagination`}
		>
			<span className={styles.pagination__pagesize}>
				<InputSelect
					data-testid={`${dataTestid}_selectnbitemsperpage`}
					id='select-id'
					name='name_test'
					options={optionsSelect}
					size={EnumInputSize.SMALL}
					style={{
						bottom: 'calc(100% + 6px)',
						left: '0'
					}}
					onClickOption={/* istanbul ignore next */(event) => {
						const nbItemsToShow = Number((event.target as HTMLElement).dataset.selectValue);
						setPageSize(nbItemsToShow);
					}}
				/>
				<span>{t('component.table.lines_per_page')}</span>
			</span>
			<span className={styles.pagination__nav}>
				<Button
					corners={EnumButtonCorners.Square}
					data-testid={`${dataTestid}_first`}
					disabled={!getCanPreviousPage()}
					hasBoxShadow={false}
					iconLeft='chevron-double-left'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					onClick={handleOnClickOnFirstPage}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					data-testid={`${dataTestid}_previous`}
					disabled={!getCanPreviousPage()}
					hasBoxShadow={false}
					iconLeft='chevron-left'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					onClick={handleOnClickOnPreviousPage}
				/>
				<span>
					{t('component.table.page_counter', {
						firstPageItemIndex: firstPageItemIndex,
						lastPageItemIndex: lastPageItemIndex,
						itemCount: getPrePaginationRowModel().rows.length
					})}
				</span>
				<Button
					corners={EnumButtonCorners.Square}
					data-testid={`${dataTestid}_next`}
					disabled={!getCanNextPage()}
					hasBoxShadow={false}
					iconLeft='chevron-right'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					onClick={handleOnClickOnNextPage}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					data-testid={`${dataTestid}_last`}
					disabled={!getCanNextPage()}
					hasBoxShadow={false}
					iconLeft='chevron-double-right'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					onClick={handleOnClickOnLastPage}
				/>
			</span>
		</div>
	);
	// END PAGINATION

	// START HEADERS
	const tableHeaders = (
		<div
			className={styles.table__header}
			data-testid={`${dataTestid}_header_row`}
			role='rowgroup'
		>
			{getHeaderGroups().map((headerGroup) => (
				<TableRow
					aria-rowindex={headerGroup.id}
					key={headerGroup.id}
					options={options}
					row={headerGroup.headers}
					rowgroup='header'
					theme={theme}
				/>
			))}
		</div>
	);
	// END HEADERS

	// START RENDER
	const tableClasses = [
		styles.table,
	];
	if (className) tableClasses.push(className);
	if (options.canSelect && Object.keys(rowSelection).length) tableClasses.push(styles.selected_action_bar);

	useEffect(() => {
		if (options.canSelect) {
		// Show link to select/deselect all only when at least one checkbox is checked
			setShowCheckAllLink(Object.keys(rowSelection).length === 0 ? false : true);
			setIsSelectionChecked(Object.keys(rowSelection).length && Object.keys(rowSelection).length >= getRowModel().rows.length ? true : false);
			if (renderSubComponent) {
				const selectionRowIds = [
				] as number[];
				Object.values(getRowModel().rowsById).forEach(row => {
					if (row.getIsSelected()) {
						selectionRowIds.push((row.original as {
						id: number;
					}).id);
					}
				});
				setComponentSelectedIds(selectionRowIds);
			}
		}
	}, [
		rowSelection
	]);

	const emptyContent = (
		<div
			className={styles.table__empty}
			data-testid={`${dataTestid}_empty`}
		>{getPrePaginationRowModel().rows.length === 0 && (columnFilters?.length || globalFilter?.length) ? t('component.table.empty_data_filter') : t('component.table.empty_data')}</div>
	);

	const emptyContentCustom = customNoResult ? (
		<div
			className={styles.table__empty}
			data-testid={`${dataTestid}_empty`}
		>{customNoResult}</div>
	) : undefined;

	const filledContent = (
		<div
			aria-rowcount={itemsLength}
			className={styles.table__wrapper}
			data-page={getState().pagination.pageIndex}
			role='table'
		>
			{options.hasHeader ? tableHeaders : undefined }
			<div
				className={styles.table__body}
				role='rowgroup'
			>
				{getRowModel().rows.map((row) => {
					const checkboxElement = (
						<IndeterminateCheckbox
							{...{
								checked: row.getIsSelected(),
								disabled: !row.getCanSelect(),
								indeterminate: row.getIsSomeSelected(),
								onChange: row.getToggleSelectedHandler(),
							}}
							className={styles.table__checkbox}
							data-testid={`${dataTestid}_rowcomponent_checkbox`}
							id={`selection_${identifier}_${row.id}`}
							name={`selection_${identifier}_${row.id}`}
							title={t('general.action.select')}
						/>
					);
					const rowProps = {
						'aria-rowindex': row.id,
						id: row.id,
						index: row.index,
						key: row.index,
						options: options,
						selected: Object.keys(rowSelection).length ? Object.keys(rowSelection).includes(row.id) : null,
					};

					let rowContent = null;
					if (renderSubComponent) {
						rowContent = (
							renderSubComponent({
								canSelect: options.canSelect,
								checkboxElement: options.canSelect ? checkboxElement : null,
								index: row.index,
								key: row.index,
								original: row.original,
								isSelected: rowProps.selected,
							})
						);

					} else {
						rowContent = (
							<TableRow
								{...rowProps}
								getIsSelected={row.getIsSelected()}
								row={row.getVisibleCells()}
								rowgroup='body'
								theme={theme}
							/>
						);
					}

					return rowContent;
				})}
			</div>
		</div>
	);

	const cssClassesActionBar = [
		styles.action_bar__container,
	];

	if (actionBarClassName) cssClassesActionBar.push(actionBarClassName);

	const actionSelectedBarElement = (
		<div className={cssClassesActionBar.join(' ')}>
			<ActionBar
				className={styles.action_bar__content}
				data-testid={`${dataTestid}_action_bar`}
			>

				<div className={styles.selected_counter}>
					<div>
						{t('format.capitalize', {
							text: t('component.table.action_bar_counter', {
								'%type%': configurationActionBar?.type
							})
						})}&nbsp;
						<span className={styles.counter}>{Object.keys(rowSelection).length}/{itemsLength}</span>
					</div>
					<Link
						data-testid={`${dataTestid}_action_bar_unselectallresultslink`}
						theme={EnumThemeLink.NAKED}
						onClick={(e) => {
							e.preventDefault();
							toggleAllRowsSelected(false);
							setShowCheckAllLink(false);
							setIsSelectionChecked(false);
						}}
					>
						{t('general.action.cancel')}
					</Link>
				</div>
				{configurationActionBar?.actions}
			</ActionBar>
		</div>
	);

	const allCurrentPageRowsAreSelected = Object.keys(rowSelection).length === itemsLength;
	const selectedResultsLink = (
		<span>
			{t('component.table.selected_results', {
				selected: Object.keys(rowSelection).length
			})}
			<span>&nbsp;</span>
			<Link
				data-testid={`${dataTestid}_unselectallresultslink`}
				theme={EnumThemeLink.NAKED}
				onClick={(e) => {
					e.preventDefault();
					toggleAllRowsSelected(false);
					setShowCheckAllLink(false);
					setIsSelectionChecked(false);
				}}
			>
				{t('general.action.cancel')}
			</Link>
		</span>
	);
	const selectAllLink = (
		<span>
			{t('component.table.selected_results', {
				selected: Object.keys(rowSelection).length
			})}
			&nbsp;
			<Link
				data-testid={`${dataTestid}_selectallresultslink`}
				theme={EnumThemeLink.NAKED}
				onClick={(e) => {
					e.preventDefault();
					toggleAllRowsSelected(true);
				}}
			>
				<span>
					{t('component.table.select_all', {
						total: itemsLength
					})}
				</span>
			</Link>
		</span>
	);
	const selectAllResultsLink = (
		<div
			className={styles.table__checkall}
		>
			{allCurrentPageRowsAreSelected ? selectedResultsLink : selectAllLink}
		</div>
	);

	const handleOnChangeSelectionAll = () => {
		Object.keys(rowSelection).length >= getRowModel().rows.length ? toggleAllRowsSelected(false) : toggleAllPageRowsSelected(true);
	};
	const selectionTableComponent = renderSubComponent && options.canSelect ? (
		<InputCheckbox
			checked={isSelectionChecked}
			className={styles.table__selectall}
			data-testid={`${dataTestid}_selection`}
			disabled={getPrePaginationRowModel().rows.length === 0}
			id={`selection_all_${identifier}`}
			name='selection'
			onChange={handleOnChangeSelectionAll}
		/>
	) : null;

	const legendElement = [
	] as string[];

	let countLegend = 0;

	columns.forEach((column) => {
		if (column.legend) {
			countLegend++;
			legendElement.push('(' + countLegend + ') ' + column.legend);
		}
	});

	return (
		<div
			className={tableClasses.join(' ')}
			data-testid={dataTestid}
			id={identifier}
		>
			{options?.topChildren || (options?.hasHeader === false || selectionTableComponent) ? (
				<div className={styles.top}>
					{options?.hasHeader ? undefined : selectionTableComponent}
					{options?.topChildren ? options.topChildren : null}
				</div>
			) : null}
			{showCheckAllLink ? selectAllResultsLink : null}
			{getPrePaginationRowModel().rows.length ? filledContent : emptyContentCustom || emptyContent}

			{paginationElement || legendElement.length ? (
				<div className={styles.table__bottom}>
					<div
						className={styles.legend}
						data-testid={`${dataTestid}-legende`}
					>
						{legendElement.length ? legendElement.join(' - ') : null}
					</div>
					{paginationElement}
				</div>
			) : null}
			{options.canSelect && configurationActionBar && Object.keys(rowSelection).length ? actionSelectedBarElement : null}
		</div>
	);
	// END RENDER
};

export {
	Table as default,
	TableProps,
};
