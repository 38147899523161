export interface FilterMappingInterface {
	id: string;
	value: string | boolean;
}

const dataUtils = {
	filteredData(data: object[], dataList: object[]) {
		dataList = dataList && Array.isArray(dataList) ? dataList : [
		];
		const mergedData = [
		].concat(...data); // merge all sub arrays into one
		// First loop through the itemGroups
		mergedData.forEach((item) => {
			// Check if access on this item
			if (item.hasAccess !== false) {
				// Create a new object with item data
				const result = {
					...item
				};
				if (result.items?.length) {
					// Call recusrivley the function on item children items
					result.items = this.filteredData(result.items, [
					]);
				}
				// The result is pushed in the array given as the second function argument
				dataList.push(result);
			}

		});
		return dataList;
	},
	// eslint-disable-next-line
	flattenObject(obj: { [key: string]: any }, prefix = ''): { [key: string]: any } {
		// The use of `any` is necessary here because we cannot guarantee the type of values in the object.
		// The data structure may contain different types of values, and `any` is used as a means to handle this uncertainty.

		// eslint-disable-next-line
		const flatObject: { [key: string]: any } = {
		};
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
				Object.assign(flatObject, this.flattenObject(obj[key], prefix + key + '_'));
			} else if (Array.isArray(obj[key]) && typeof obj[key][0] === 'string') {
				const arrayFormated = [
					...obj[key]
				];
				flatObject[prefix + key] = arrayFormated;
			} else if (typeof obj[key] === 'boolean') {
				flatObject[prefix + key] = String(obj[key]);
			} else {
				flatObject[prefix + key] = obj[key];
			}
		}
		return flatObject;
	},
	format: {
		company: (data: {
			name?: string;
		}) => {
			const company = data ? {
				name: data?.name || null
			} : {
			};

			return company;
		},
	},
	getFiltersForSelectedValues: (
		filterMappings: FilterMappingInterface[],
		selectValues: (string | boolean)[]
	): FilterMappingInterface[] => {
		const filterValues: FilterMappingInterface[] = [
		];

		selectValues.forEach((value: string | boolean) => {
			const filterMapping = filterMappings.find((filter) => filter.value === value);
			if (filterMapping) {
				filterValues.push({
					id: filterMapping.id,
					value: filterMapping.value,
				});
			}
		});

		return filterValues;
	},
};

export default dataUtils;
