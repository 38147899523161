import * as React from 'react';

// STYLES
import styles from './badge-counter.module.scss';

interface BadgeCounterProps {
	className?: string;
	content: number;
	'data-testid'?: string;
	size?: string;
}

const BadgeCounter = ({
	className,
	'data-testid': dataTestid,
	content,
	size,
}: BadgeCounterProps): JSX.Element => {

	const cssClasses = [
		styles.badgecounter,
	];

	if (size === 'small') cssClasses.push(styles.size__small);

	if (className) cssClasses.push(className);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{content}
		</div>
	);
};

export {
	BadgeCounter as default,
	BadgeCounterProps,
};

