import * as React from 'react';
import {
	ReactNode,
} from 'react';

// STYLES
import styles from './action-bar.module.scss';

interface ActionBarProps {
	children: ReactNode;
	className?: string;
	'data-testid'?: string;
}

const ActionBar = ({
	'data-testid': dataTestid,
	children,
	className,
}: ActionBarProps): JSX.Element => {

	const cssClasses = [
		styles.action_bar
	];

	if (className) cssClasses.push(className);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{children}
		</div>
	);
};

export {
	ActionBar as default,
	ActionBarProps,
};
