import styles from './UserCard.module.scss';
const defaultUserCardThemeConfs = {
	name: 'default',
	className: styles.theme__default,
	format: 'thumbnail',
	rounded: false,
};
const userCardThemeConfs = [
	defaultUserCardThemeConfs,
	{
		name: 'square',
		className: styles.theme__square,
		format: 'profile',
		rounded: false,
		placeholder: {
			icon: 'user-alt'
		}
	},
	{
		name: 'round',
		className: styles.theme__round,
		format: 'profile',
		rounded: true,
		placeholder: {
			icon: 'user-alt'
		}
	}
];
export {
	defaultUserCardThemeConfs,
	userCardThemeConfs,
};
