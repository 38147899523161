import * as React from 'react';
import {
	MouseEvent,
} from 'react';
import {
	useNavigate,
} from 'react-router-dom';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonCounterTheme,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
	EnumFontStyleButton,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
	EnumThemeButton,
} from '@enums/theme.enum';

// COMPONENTS
import Badge from '@components/badge';
import BadgeCounter from '@components/badge-counter';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import Icon from '@components/icon';
import Tooltip from '@components/tooltip';

import styles from './button.module.scss';

export interface ButtonProps {
	'aria-pressed'?: boolean;
	badgeTheme?: EnumStatusTheme;
	className?: string;
	corners?: EnumButtonCorners;
	counter?: number;
	counterTheme?: EnumButtonCounterTheme;
	'data-testid'?: string;
	disabled?: boolean;
	hasAccess?: boolean;
	hasBoxShadow?: boolean;
	href?: string;
	iconClassName?: string;
	iconLeft?: string;
	iconRight?: string;
	iconStyle?: EnumFontStyleButton;
	isActive?: boolean;
	isDisplay?: boolean;
	items?: DropdownItemProps[] | ButtonProps[];
	label?: string;
	loader?: boolean;
	name?: string;
	onClick?: (event: MouseEvent<HTMLElement>, item?: object) => void;
	size?: EnumButtonSize;
	theme?: EnumThemeButton;
	to?: string;
	tooltipText?: string;
	type?: EnumButtonType;
}

const Button = ({
	'aria-pressed': ariaPressed = false,
	badgeTheme,
	className,
	corners = EnumButtonCorners.Default,
	counter,
	counterTheme = EnumButtonCounterTheme.DEFAULT,
	'data-testid': dataTestid,
	disabled = false,
	hasAccess = true,
	hasBoxShadow = true,
	href,
	iconLeft,
	iconRight,
	iconStyle = EnumFontStyle.REGULAR,
	isActive = false,
	isDisplay = true,
	label,
	loader = false,
	name,
	onClick,
	size = EnumButtonSize.DEFAULT,
	theme = EnumTheme.PRIMARY,
	to,
	tooltipText,
	type = EnumButtonType.BUTTON
}: ButtonProps): JSX.Element | undefined => {
	const navigate = useNavigate();

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		if (hasAccess) {
			if (href) {
				window.open(href);
			} else if (to) {
				navigate(to);
				event.preventDefault();
			}
		} else {
			event.preventDefault();
		}
		onClick ? onClick(event) : null;
	};

	let iconLeftElement = null;
	let iconRightElement = null;

	const cssClasses = [
		styles.button,
	];

	if (className && !tooltipText?.length) cssClasses.push(className);
	if (isActive) cssClasses.push(styles.active);

	if (iconLeft) {
		iconLeftElement = (
			<Icon
				aria-hidden="true"
				className={styles.icon}
				data-testid={`${dataTestid}-icon`}
				fontStyle={iconStyle}
				name={iconLeft}
			/>
		);
		cssClasses.push(styles.icon__left);
	}

	if (iconRight) {
		iconRightElement = (
			<Icon
				aria-hidden="true"
				className={styles.icon}
				data-testid={`${dataTestid}-icon`}
				fontStyle={iconStyle}
				name={iconRight}
			/>
		);
		cssClasses.push(styles.icon__right);
	}
	cssClasses.push(styles[`${'corners__' + corners}`]);
	cssClasses.push(styles[`${'size__' + size}`]);
	if ((iconLeft && iconRight) && !label) cssClasses.push(styles.icon__both__Nolabel);
	if (hasAccess) {
		cssClasses.push(styles[`${'theme__' + theme}`]);
	} else {
		// Class exist but on mixin-theme-button
		// eslint-disable-next-line css-modules/no-undef-class
		cssClasses.push(styles.theme__tertiary);
	}

	const isLoading = disabled && loader;
	if (isLoading) cssClasses.push(styles.loading);
	const isLoadingLeftWithoutLabel = !label && iconLeftElement && isLoading;
	const isLoadingRightWithoutLabel = !label && iconRightElement && isLoading;
	const isLoadingWithLabel = label && isLoading;
	if (hasBoxShadow) cssClasses.push(styles.box_shadow);

	const iconLoadingClasses = [
		styles.icon,
	];

	if (isLoadingWithLabel && iconRight) iconLoadingClasses.push(styles.loading__with_icon);

	const loaderIconElement = (
		<Icon
			animation="spin"
			aria-hidden="true"
			className={iconLoadingClasses.join(' ')}
			data-testid={`${dataTestid}-loader`}
			fontStyle={EnumFontStyle.REGULAR}
			name='spinner-third'
		/>
	);

	let labelButtonElement = null;
	if (counter && label) {
		labelButtonElement = counterTheme === EnumButtonCounterTheme.DEFAULT ? <>{`${label}\u00a0(${counter})`}</> : <>{`${label}\u00a0`} <BadgeCounter content={counter} /></>;
	} else if (label) {
		labelButtonElement = label;
	}

	if (!label) {
		if ((iconLeft && !iconRight) || (!iconLeft && iconRight)) {
			cssClasses.push(styles.iconOnly);
		}
	} else if (label.length === 1) {
		cssClasses.push(styles.oneLetter);
	}

	const buttonContent = (
		<>
			{isLoadingLeftWithoutLabel ? loaderIconElement : iconLeftElement}
			{labelButtonElement ? (
				<span
					className={styles.label}
					data-testid={counter ? `${dataTestid}-with-counter` : `${dataTestid}-without-counter`}
				>
					{labelButtonElement}
				</span>
			) : null}
			{isLoadingRightWithoutLabel ? loaderIconElement : iconRightElement}
			{isLoadingWithLabel ? loaderIconElement : null}
		</>
	);

	const buttonElement = (
		<button
			aria-pressed={ariaPressed}
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			disabled={disabled}
			name={name}
			type={type}
			onClick={disabled ? undefined : handleOnClick}
		>
			{badgeTheme ? (
				<Badge
					className={styles.badge}
					data-testid={`${dataTestid}-badge`}
					theme={badgeTheme}
				/>
			) : null}
			{buttonContent}
		</button>
	);

	return tooltipText && name && isDisplay ? (
		<Tooltip
			className={className}
			content={tooltipText}
			data-testid={`${dataTestid}-tooltip`}
			id={name}
		>
			{buttonElement}
		</Tooltip>
	) : isDisplay ? buttonElement : undefined;
};

Button.displayName = 'Button';

export default Button;
