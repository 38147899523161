import * as React from 'react';
import {
	MouseEvent,
	ReactNode,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumThemeLink,
	EnumTargetLink,
} from '@enums/link.enum';

// STYLES
import styles from './link.module.scss';
import {
	useNavigate,
} from 'react-router-dom';

interface LinkProps {
	children?: ReactNode;
	className?: string;
	'data-testid'?: string;
	disabled?: boolean;
	fontWeight?: EnumFontStyle;
	hasAccess?: boolean;
	href?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	target?: EnumTargetLink;
	theme?: EnumThemeLink;
}

const Link = ({
	children,
	className,
	'data-testid': dataTestid,
	disabled = false,
	fontWeight = EnumFontStyle.SEMIBOLD,
	href,
	onClick,
	target = EnumTargetLink.SELF,
	theme = EnumThemeLink.PRIMARY,
}: LinkProps): JSX.Element => {
	const navigate = useNavigate();

	const classes = [
		styles.link,
	];

	if (className) classes.push(className);
	if (disabled) classes.push(styles[`link__${'disabled'}`]);
	classes.push(styles[`link__${fontWeight}`]);
	classes.push(styles[`link__${theme}`]);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (disabled) {
			event.preventDefault();
			return;
		}
		if (target === EnumTargetLink.SELF) {
			event.preventDefault();
			navigate(href);
		}
		onClick ? onClick(event) : null;
	};

	return (
		<a
			aria-disabled={disabled}
			className={classes.join(' ')}
			data-testid={dataTestid}
			href={disabled ? undefined : href}
			target={disabled ? undefined : target}
			onClick={handleOnClick}
		>
			{children}
		</a>
	);
};

Link.displayName = 'Link';

export {
	Link as default,
	LinkProps,
};
