// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zh0Bg4yY{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;padding-bottom:2.28571429rem;padding-top:4.28571429rem;width:37.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.zh0Bg4yY{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.zh0Bg4yY{padding-bottom:2.85714286rem;padding-top:2.85714286rem}}.zh0Bg4yY .T_hY3_wG{font-size:1.28571429rem;font-weight:600;line-height:1.6em;line-height:1.57142857rem;margin-bottom:1.78571429rem;text-align:center}.zh0Bg4yY .T_hY3_wG:first-letter{display:block;text-transform:uppercase}@media screen and (min-width: 768Px)and (orientation: landscape){.zh0Bg4yY .T_hY3_wG{font-size:1.42857143rem}}.zh0Bg4yY .BwBk96Qb{text-align:center;width:100%}.zh0Bg4yY .kEYzIwhq{display:flex;justify-content:center;margin-bottom:1.42857143rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": `zh0Bg4yY`,
	"title": `T_hY3_wG`,
	"modal_content": `BwBk96Qb`,
	"modal_illustration": `kEYzIwhq`
};
export default ___CSS_LOADER_EXPORT___;
