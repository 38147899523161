import * as React from 'react';
import {
	ReactNode,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import Icon from '@components/icon';
import Link from '@components/link';

// STYLES
import styles from './paragraph.module.scss';

interface ParagraphProps {
	hasBorder?: boolean;
	className?: string;
	children?: ReactNode;
	'data-testid'?: string;
	editUrl?: string;
	icon?: string;
	theme?: EnumStatusTheme;
	title?: string;
	titleLevel?: number;
}

const Paragraph = ({
	hasBorder = false,
	children,
	className,
	'data-testid': dataTestid,
	editUrl,
	icon,
	theme,
	title,
	titleLevel = 2,
}: ParagraphProps): JSX.Element => {
	const classes = [
		styles.paragraph
	];

	if (theme) classes.push(styles[`${'theme__' + theme}`]);
	if (editUrl) classes.push(styles.paragraph__withEditIcon);

	if (hasBorder) classes.push(styles.border);
	if (className) classes.push(className);

	const Tag = `h${titleLevel}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

	const editButtonElement = editUrl ? (
		<Link
			className={styles.edit}
			data-testid={`${dataTestid}-editurl`}
			href={editUrl}
			target={EnumTargetLink.BLANK}
			theme={EnumThemeLink.NAKED}
		>
			<Icon
				name='edit'
			/>
		</Link>
	) : null;

	const paragraphIcon = (
		<div
			className={styles.paragraph_icon}
			data-testid={`${dataTestid}-icon`}
		>
			<Icon
				fontStyle={EnumFontStyle.LIGHT}
				name={icon}
			/>
		</div>
	);

	const classesContent = [
		styles.paragraph__content,
	];

	if (title) classesContent.push(styles.paragraph__content__withTitle);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{title ? (
				<Tag
					className={styles.title}
					data-testid={`${dataTestid}-title`}
				>
					{icon ? paragraphIcon : null}
					{title}
				</Tag>
			) : null}
			{children ? (
				<div
					className={classesContent.join(' ')}
					data-testid={`${dataTestid}-paragraph`}
				>
					{editButtonElement}
					{children}
				</div>
			) : null}

		</div>
	);
};

export {
	Paragraph as default,
	ParagraphProps,
};
