import {
	LayerProps,
} from 'react-map-gl';

const getTilesLayerConfig = (
	tileName: string,
	visible: 'visible' | 'none')
	: LayerProps => {
	return {
		id: `${tileName}-layer`,
		type: 'raster',
		paint: {
			'raster-opacity': 0.8,
		},
		layout: {
			'visibility': visible,
		},
	};
};

export default getTilesLayerConfig;
