import * as React from 'react';

import {
	useTranslation,
} from 'react-i18next';
import {
	EnumImageFormatQuery,
} from '@appConf/vars.conf';

// TYPES
import {
	Image,
} from '@@types/Image';
import {
	updateImageUrls,
} from '@modules/images';

// COMPONENTS
import {
	CartographyPopupProps,
} from '@components/cartography-popup';
import {
	LabelStatusProps,
} from '@components/label-status';
import PriceWithUnit from '@components/price-with-unit';
import Slider from '@components/slider';

// STYLING
import styles from './cartography-popup-layout-offer.module.scss';

function CartographyPopupLayoutOffer({
	'data-testid': dataTestid,
	properties,
}: CartographyPopupProps) {
	const { t } = useTranslation();
	const cssClasses = [
		styles.popup_offer
	];

	// list of states for slider
	const sliderStatesTopLeft = [
		'bookmarked',
	];

	const sliderStatesTopRight = [
		'seen',
	];

	const getSliderLabels = (definedLabels: string[], scopeLabels: string[]) => {
		const labelsFormated = [
		] as LabelStatusProps[];
		definedLabels?.filter((defineLabel: string) => {
			if (scopeLabels.includes(defineLabel.toLowerCase())) {
				const labelStatus = {
					context: 'female',
					text: defineLabel.toLowerCase()
				};
				labelsFormated.push(labelStatus);
			}
		});
		return labelsFormated;
	};

	const addressElement = (
		<div className={styles.address} >
			<div>{properties.building.address.full}</div>
		</div>
	);
	const salePriceElement = properties?.transaction_types.includes('sale') ? (
		<PriceWithUnit
			price={properties.sale_price_value?.amount}
			priceText={properties.sale_price_state}
			quantitiesAndFrequenciesText={t('format.per.square_meter', {
				text: ''
			})}
			transactionType='sale'
			vatUnitTranslationKey={properties.sale_price_value?.tax_type}
		/>
	) : null;

	let chargesUnitTranslationKey = undefined;
	if (properties.rental_price_value?.are_charges_included !== undefined) {
		if (properties.rental_price_value?.are_charges_included) {
			chargesUnitTranslationKey = 'included_charges';
		} else {
			chargesUnitTranslationKey = 'excluded_charges';
		}
	}
	const rentalPriceElement = properties?.transaction_types.includes('rent') ? (
		<PriceWithUnit
			chargesUnitTranslationKey={chargesUnitTranslationKey}
			price={properties.rental_price_value?.amount}
			priceText={properties.rental_price_state}
			quantitiesAndFrequenciesText={t('format.per.square_meter_per_year', {
				text: ''
			})}
			transactionType='rent'
			vatUnitTranslationKey={properties.rental_price_value?.tax_type}
		/>
	) : null;

	const areaElement = properties?.building?.area?.value ? (
		<div data-testid={`${dataTestid}-area`}>

			<span>
				{
					t('format.area', {
						'val': properties.building.area.value,
					})
				}
			</span>
		</div>
	) : null;

	const availableCategory = [
		'photo'
	];

	const photos = properties.images?.collection?.length ? properties.images.collection.filter(image => availableCategory.includes(image.category)) : null;

	const slider = (
		<Slider
			infinite={true}
			items={photos ? updateImageUrls(photos, EnumImageFormatQuery.CARTO ) as Image[] : null}
			labelsTopLeft={getSliderLabels(properties.states, sliderStatesTopLeft)}
			labelsTopRight={getSliderLabels(properties.states, sliderStatesTopRight)}
			size='pincard'
		/>
	);
	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{slider}
			<div className={styles.infos}>
				{addressElement}
				{rentalPriceElement}
				{salePriceElement}
				{areaElement}
			</div>
		</div>
	);
}

export { CartographyPopupLayoutOffer as default, };
