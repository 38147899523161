import {
	Image,
} from '@@types/Image';
import * as utils from '@modules/utils';

export const updateImageUrls = (photos: Image[] | string, query: string = ''): Image[] | string => {
	const queryParams = utils.getQueryParams(query);
	// const queryParamsResult = '';
	if (Array.isArray(photos)) {
		return photos.map(photo => {
			let photoUrl = photo.image.url.split('?')[0];
			let photoQueryParams = utils.getQueryParams(photo.image.url);
			photoQueryParams = photoQueryParams.concat(queryParams);
			if (photoQueryParams.length) {
				photoUrl = photoUrl + '?' + photoQueryParams.join('&');
			}
			const image = {
				...photo.image,
				url: photoUrl
			};
			return new Image(image);
		}) as Image[];
	} else {
		const photosWithoutGetParams = photos?.split('?')[0];
		let photosParams = utils.getQueryParams(photos);
		photosParams = photosParams.concat(queryParams);
		return photosWithoutGetParams + '?' + photosParams.join('&');
	}
};

export const convertFileToBase64 = async(file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			resolve(reader.result as string);
		};
		reader.onerror = () => {
			reject(reader.error);
		};
		reader.readAsDataURL(file);
	});
};
