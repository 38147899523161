import {
	ColorsProps,
} from '@components/cartography';

import {
	LayerProps,
} from 'react-map-gl';

const getCirclesAlwaysActiveLayerConfig = (
	sourceName: string,
	colors: ColorsProps
): LayerProps => {
	return {
		id: `${sourceName}_circles`,
		type: 'circle',
		source: sourceName,
		layout: {
			'visibility': 'visible',
		},
		'paint': {
			'circle-radius': 11,
			'circle-color': colors.white,
			'circle-stroke-width': 2,
			'circle-stroke-color': colors.mine_shaft,
		},
	};
};

export default getCirclesAlwaysActiveLayerConfig;
