// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A18uthU7{border-bottom:0.07142857rem solid var(--color-border-default);cursor:pointer;display:flex;flex-direction:column;margin-bottom:1.42857143rem;padding-bottom:1.42857143rem;position:relative}.A18uthU7:last-child{margin-bottom:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.A18uthU7{cursor:default;flex-direction:row;margin-bottom:0;padding:0 0 0}.A18uthU7:last-child{margin-bottom:0}}.lLq2g0gG{display:flex;flex:1 1 0;width:0}@media screen and (min-width: 768Px)and (orientation: landscape){.lLq2g0gG{display:inline;flex:inherit;width:inherit}}.BHZN5FQc{font-weight:600;margin-right:0.57142857rem}@media screen and (min-width: 768Px)and (orientation: landscape){.BHZN5FQc{display:none}}@media screen and (max-width: Px)and (orientation: portrait){.CQCfPW9W{display:none}}.vLk0CK3c{display:flex;flex:1 1 0;font-weight:600;width:0}@media screen and (min-width: 768Px)and (orientation: landscape){.vLk0CK3c{display:none}}.diN2l8VN:hover{background-color:#f5f5f5}.oArUXN9T{background:linear-gradient(hsla(var(--color-background-primary-soft-h), var(--color-background-primary-soft-s), var(--color-background-primary-soft-l), 0.05), hsla(var(--color-background-primary-soft-h), var(--color-background-primary-soft-s), var(--color-background-primary-soft-l), 0.05)),#fff}.oArUXN9T:hover{background:linear-gradient(hsla(var(--color-background-primary-soft-h), var(--color-background-primary-soft-s), var(--color-background-primary-soft-l), 0.05), hsla(var(--color-background-primary-soft-h), var(--color-background-primary-soft-s), var(--color-background-primary-soft-l), 0.05)),#fff}.nRkRYJbU{display:flex;flex-direction:column;margin-left:0.35714286rem}.PNMZwXmk{font-size:1em}.HCpBUcbp .sfOU_pfG{font-size:0.71428571rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `A18uthU7`,
	"row__cell": `lLq2g0gG`,
	"row__columnText": `BHZN5FQc`,
	"row__hidecell": `CQCfPW9W`,
	"row__header_hidecell": `vLk0CK3c`,
	"row__canselect": `diN2l8VN`,
	"row__selected": `oArUXN9T`,
	"sort": `nRkRYJbU`,
	"theme__column": `PNMZwXmk`,
	"theme": `HCpBUcbp`,
	"sup": `sfOU_pfG`
};
export default ___CSS_LOADER_EXPORT___;
