import {
	Instantiable,
} from '@@types/index';

export interface DocumentTemplatesProps {
	name: string;
	id: number;
}

export interface CollectionJson<T> {
	collection: Array<T>;
	count?: number;
}

export class Collection<T, P> {
	public collectionObject: CollectionJson<T>;
	public type_instance: Instantiable<P>;
	public language?: string;

	constructor(
		collectionJson: CollectionJson<T>,
		type_instance: Instantiable<P>,
		language?: string
	) {
		this.language = language || 'fr-FR';
		this.collectionObject = collectionJson;
		this.type_instance = type_instance;
	}

	get collection(): Array<P> {
		let newCollection: Array<P> = [
		];

		const TypeInstance: Instantiable<P> = this.type_instance;
		if (this.collectionObject?.collection?.length) {
			newCollection = this.collectionObject.collection.map(collectionItem => {
				return new TypeInstance(collectionItem, this.language);
			});
		}
		return newCollection;
	}

	get count(): number {
		return this.collectionObject?.count || 0;
	}
}
