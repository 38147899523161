// SOURCE  : https://stackoverflow.com/a/42234988/6995445
// SOURCE TS : https://usehooks-ts.com/react-hook/use-on-click-outside

import {
	RefObject,
	useEffect,
	MouseEvent as ReactMouseEvent,
} from 'react';

function useClickOutsideEffect<T extends HTMLElement = HTMLElement>(
	refs: RefObject<T>[],
	callback: ((event: MouseEvent) => void) | ((event: ReactMouseEvent<HTMLInputElement>) => void),
	mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (Array.isArray(refs) && refs.every(ref => ref.current && !ref.current.contains(event.target as Node))) {
				callback(event as MouseEvent & ReactMouseEvent<HTMLInputElement>);
			}
		}

		// Bind the event listener
		document.addEventListener(mouseEvent, handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener(mouseEvent, handleClickOutside);
		};
	}, [
		refs
	]);
}

export default useClickOutsideEffect;
