import * as React from 'react';
import {
	ReactElement,
	ReactNode,
} from 'react';

//STYLES
import styles from './cell.module.scss';

interface TableCell {
	className?: string;
	children?: ReactNode;
	id: string;
	name?: string;
	role?: string;
	theme?: 'column' | 'default';
	type?: string;
	getValue: () => string;
	getContext: () => object;
	row?: {
		getVisibleCells?: () => Array<object>;
	};
	column?: {
		getToggleSortingHandler?: () => void;
		getIsSorted?: () => string;
		getCanSort?: () => string;
		id?: string;
		dataName?: string;
		columnDef: {
			legend?: string;
			className?: string;
			dataType?: string;
			header: ReactElement;
			cell?: ReactElement;
			type?: string;
		};
	};
}
interface CellProps {
	className?: string;
	children?: ReactNode;
	id?: string;
	name?: string;
	role?: string;
	theme?: 'column' | 'default';
	type?: string;
}

const Cell = ({
	className,
	children,
	id,
	name,
	theme = 'default',
	type,
	...otherProps
}: CellProps): JSX.Element => {

	const dataTypesToDesign = [
		'area',
		'currency',
		'desktopActions',
		'mobileActions',
		'selection',
	];
	const classes = [
		styles.cell,
	];
	if (className) classes.push(className);
	classes.push(styles[`theme__${theme}`]);
	if (name) classes.push(styles[`name__${name}`]);
	if (type && dataTypesToDesign.includes(type)) classes.push(styles[`type__${type}`]);

	return (
		<div
			aria-sort='false'
			id={id}
			role='cell'
			{...otherProps}
			className={classes.join(' ')}
			data-name={name}
			data-type={type}
		>
			{children}
		</div>
	);
};

export {
	Cell as default,
	CellProps,
	TableCell,
};
