// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hr_fhiT2{color:currentcolor;height:100%;position:relative;width:100%}.Hr_fhiT2 .mapboxgl-canvas{min-width:100%;position:relative;vertical-align:middle}.Hr_fhiT2 .mapboxgl-ctrl-attrib-inner [aria-label=Mapbox],.Hr_fhiT2 .mapboxgl-ctrl-attrib-inner [aria-label=OpenStreetMap],.Hr_fhiT2 .mapboxgl-ctrl-attrib-inner [aria-label="Map feedback"]{display:none}.Hr_fhiT2 .mapboxgl-map{height:100%}.Hr_fhiT2 .mapboxgl-popup-close-button{z-index:2}.Hr_fhiT2 .mapbox-improve-map,.Hr_fhiT2 .mapboxgl-ctrl-logo{display:none}.Hr_fhiT2 .mapboxgl-ctrl-bottom-left>.mapboxgl-ctrl-attrib.mapboxgl-compact-show{padding:0 0.71428571rem 0 2rem}.Hr_fhiT2 .mapboxgl-ctrl-bottom-left,.Hr_fhiT2 .mapboxgl-ctrl-bottom-right,.Hr_fhiT2 .mapboxgl-ctrl-top-left,.Hr_fhiT2 .mapboxgl-ctrl-top-right{z-index:1}.Hr_fhiT2 .mapboxgl-popup-content{border-radius:0.57142857rem;padding:0}.Hr_fhiT2 .SbNLXD3E{background-color:var(--color-mine-shaft);border:3px solid var(--color-white);border-radius:50%;cursor:pointer;height:3.57142857rem;overflow:hidden;width:3.57142857rem}.vBCHymTL{left:1rem;position:absolute;top:1rem;z-index:1}.h2EPULbk{position:relative;z-index:2}.DAMkXEue{position:relative;z-index:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartography": `Hr_fhiT2`,
	"marker": `SbNLXD3E`,
	"tranport_lines_select": `vBCHymTL`,
	"source__geojson": `h2EPULbk`,
	"source__tiles": `DAMkXEue`
};
export default ___CSS_LOADER_EXPORT___;
