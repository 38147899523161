// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cLFeeoiO{align-items:center;color:var(--color-font-default);text-decoration:none}.dPCo5hLS{align-items:center;color:var(--color-link-default);display:inline-flex;font-weight:600;transition:opacity .05s ease-in-out}.dPCo5hLS:hover{filter:brightness(0.9);transition:filter .05s ease-in-out}.dPCo5hLS:hover:active{filter:brightness(1);transition:filter .05s ease-in-out}.Vg0Sxtyh{align-items:center;display:inline-flex;transition:opacity .05s ease-in-out}.Vg0Sxtyh:hover{color:var(--color-link-secondary);filter:brightness(0.9);transition:filter .05s ease-in-out}.Vg0Sxtyh:hover:active{filter:brightness(1);transition:filter .05s ease-in-out}.Rlt5I_Co{cursor:not-allowed;opacity:.5;pointer-events:none;transition:opacity .05s ease-in-out}.Rlt5I_Co i{cursor:not-allowed;pointer-events:none}.QfGFELfD{font-weight:700}.CpykNFxX{font-weight:400}.S81E5q4d{font-weight:300}.yyCatw2a{font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `cLFeeoiO`,
	"link__primary": `dPCo5hLS`,
	"link__secondary": `Vg0Sxtyh`,
	"link__disabled": `Rlt5I_Co`,
	"link__bold": `QfGFELfD`,
	"link__regular": `CpykNFxX`,
	"link__light": `S81E5q4d`,
	"link__semi-bold": `yyCatw2a`
};
export default ___CSS_LOADER_EXPORT___;
