interface FeaturesFlagsConf {
	[key: string]: {
		isEnabled: boolean;
		name: string;
		authorized_company_ids: number[];
		authorized_usernames: string[];
	};
}

const featuresFlagsConf: FeaturesFlagsConf = {
	hasCRM: {
		isEnabled: true,
		name: 'hasCRM',
		authorized_company_ids: [
			25,
			297
		],
		authorized_usernames: [
		]
	},
};

export default featuresFlagsConf;
