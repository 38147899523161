interface LayerProps {
	bbox?: string;
	key?: string;
	root_url?: string;
	srs?: string;
	tileSize?: number;
}

const layer = {
	bbox: '{bbox-epsg-3857}',
	key: '3c2b7cf230387dabacaa56d72d74024a',
	root_url: `${process.env.DOMAIN_CARTO}/mapcache/wms`,
	srs: 'EPSG%3A900913',
	tileSize: 256,
} as LayerProps;

export {
	layer,
	LayerProps,
};
