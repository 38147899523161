import * as React from 'react';
import {
	DetailedHTMLProps,
	HTMLAttributes,
	MouseEvent,
	ReactNode,
	useEffect,
	useState,
} from 'react';

import ReactModal from 'react-modal';
import {
	Props,
} from 'react-modal';

// ENUMS
import {
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// COMPONENTS
import Button, {
} from '@components/button';

// Some classes are used dynamically
// eslint-disable-next-line css-modules/no-unused-class
import styles from './modal.module.scss';

interface ModalProps extends Props {
	'data-testid'?: string;
	children?: ReactNode;
	closeLeft?: string;
	closeRight?: string;
	contentClassName?: string;
	hasHeader?: boolean;
	isDesktopResolution: boolean;
	onClose?: (event: MouseEvent<HTMLElement>) => void;
	onOpen?: () => void;
	overlayClassName?: string;
	portalClassName?: string;
	size?: EnumModalSize;
	title?: string;
}

const Modal = ({
	'data-testid': dataTestid,
	appElement,
	children,
	closeLeft,
	closeRight = 'times',
	contentClassName,
	hasHeader = true,
	isDesktopResolution,
	isOpen,
	onClose,
	onOpen,
	overlayClassName,
	portalClassName,
	size = EnumModalSize.DEFAULT,
	title,
}: ModalProps): JSX.Element => {

	const portalCssClasses = [
		styles.portal,
	];
	if (portalClassName) portalCssClasses.push(portalClassName);

	const overlayCssClasses = [
		styles.overlay,
	];
	if (overlayClassName) overlayCssClasses.push(overlayClassName);

	const [
		overlayCssClassesState,
		setOverlayCssClassesState
	] = useState([
		...overlayCssClasses,
		'animation__appearing'
	]);

	const contentCssClasses: string[] = [
		styles.content,
		styles[`size__${size}`],
	];
	if (contentClassName) contentCssClasses.push(contentClassName);

	const [
		contentCssClassesState,
		setContentCssClassesState
	] = useState([
		...contentCssClasses,
		isDesktopResolution ? 'animation__scale' : 'animation__btt'
	]);

	const [
		isOpenState,
		setIsOpenState
	] = useState(isOpen);

	useEffect(() => {
		if (isOpen) {
			setIsOpenState(isOpen);
			if (!isDesktopResolution) {
				const cssContentClasses: string[] = [
					...contentCssClasses.filter((cssClassName: string) => cssClassName !== 'animation__ttb')
				];

				setContentCssClassesState([
					...cssContentClasses,
					'animation__btt'
				]);
			}

			// ReactModal onAfterOpen props doesn't seems to work well so we use the state change on isOpenState to execute the callback
			if (onOpen) onOpen();

		} else {
			if (!isDesktopResolution) {
				const cssContentClasses: string[] = [
					...contentCssClasses.filter((cssClassName: string) => cssClassName !== 'animation__btt')
				];
				setContentCssClassesState([
					...cssContentClasses,
					'animation__ttb'
				]);
				setOverlayCssClassesState([
					...overlayCssClasses,
					'animation__appearing'
				]);
				setTimeout(() => {
					setIsOpenState(isOpen);
				}, APP_CONF_VARS.timeout.hide);
			} else {
				setIsOpenState(isOpen);
			}
		}
	}, [
		isOpen
	]);

	const handleOnClose = (event: MouseEvent<HTMLElement>) => {
		if (onClose) onClose(event);
	};

	const getCloseButton = (iconName: string) => {
		return (
			<Button
				className={styles.close}
				data-testid={closeLeft ? `${dataTestid}-closeLeft-${iconName}` : `${dataTestid}-closeRight-${iconName}`}
				hasBoxShadow={false}
				iconLeft={iconName}
				iconStyle={EnumFontStyle.LIGHT}
				size={EnumButtonSize.BIG}
				theme={EnumTheme.NAKED}
				onClick={handleOnClose}
			/>
		);
	};

	const hasCloseLeftElement = closeLeft ? (
		<div
			className={`${styles.header__item} ${styles.header__left}`}
			data-testid={`${dataTestid}-closeLeft`}
		>
			{getCloseButton(closeLeft)}
		</div>
	) : null;

	const hasCloseRightElement = (
		<div
			className={`${styles.header__item} ${styles.header__right}`}
			data-testid={`${dataTestid}-closeRight`}
		>
			{getCloseButton(closeRight)}
		</div>
	);

	const headerElement = (
		<div
			className={styles.header}
			data-testid={`${dataTestid}-header`}
		>
			{hasCloseLeftElement}
			<div className={`${styles.header__item} ${styles.header__center}`}>
				<h2
					className={styles.title}
					data-testid={`${dataTestid}-title`}
				>{title}</h2>
			</div>
			{hasCloseRightElement}
		</div>
	);

	const noHeaderClasses = [
		styles.noHeader
	];
	if (closeLeft) noHeaderClasses.push(styles.noHeader__left);
	noHeaderClasses.push(styles.noHeader__right);
	const noHeaderElement = (
		<div
			className={noHeaderClasses.join(' ')}
			data-testid={`${dataTestid}-noHeader`}
		>
			{hasCloseLeftElement}
			{hasCloseRightElement}
		</div>
	);

	const overlayElement = (props: Pick<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'key' | keyof HTMLAttributes<HTMLDivElement>>, contentElement: ReactNode) => {
		return (
			<div
				{...props}
				data-testid={`${dataTestid}-overlay`}
			>
				{contentElement}
			</div>
		);
	};

	const contentElement = (props: Pick<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'key' | keyof HTMLAttributes<HTMLDivElement>>, children: ReactNode) => {
		return (
			<div
				{...props}
				className={contentCssClassesState.join(' ')}
				data-testid={`${dataTestid}`}
			>
				{hasHeader ? headerElement : noHeaderElement}
				<div
					className={styles.body}
					data-testid={`${dataTestid}-body`}
				>
					{children}
				</div>
			</div>
		);
	};

	return (
		<ReactModal
			appElement={appElement}
			ariaHideApp={false}
			className={contentCssClassesState.join(' ')}
			contentElement={contentElement}
			isOpen={isOpenState}
			overlayClassName={overlayCssClassesState.join(' ')}
			overlayElement={overlayElement}
			portalClassName={portalCssClasses.join(' ')}
			preventScroll={true}
			testId={dataTestid}
			onRequestClose={handleOnClose}
		>{children}</ReactModal>
	);
};

export {
	Modal as default,
	ModalProps,
};
