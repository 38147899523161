import {
	createSlice,
} from '@reduxjs/toolkit';
import {
	StatusBannerProps,
} from '@components/status-banner';

export interface StoreStatusMsgsInstance {
	instances: StatusBannerProps[];
}

export const statusMsgsSlice = createSlice({
	name: 'status_msgs',
	initialState: {
		instances: [
		]
	},
	reducers: {
		addStatusMsg: (state, action) => {
			state.instances = [
				...state.instances,
				action.payload
			];
		},
		clearStatusMsgs: (state) => {
			state.instances = [
			];
		}
	}
});

// Action creators are generated for each case reducer function
export const { addStatusMsg, clearStatusMsgs } = statusMsgsSlice.actions;

export default statusMsgsSlice.reducer;
