// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O2B4CLpH{display:none;position:absolute;z-index:3}.cg9XbjMI{display:inline-flex;position:relative}.o5jcjAk1{width:max-content}.chAJ6uJH{width:100%}.NeYPgeEb{display:block}.DkJdZXek{display:inline-flex}.DkJdZXek.Nq33rH_U{background:var(--color-wild-sand);border-radius:0.57142857rem;color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5);cursor:not-allowed}.DkJdZXek.Nq33rH_U *{color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}.DkJdZXek.Nq33rH_U:hover{background-color:var(--color-wild-sand) !important;border-color:var(--color-border-default) !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `O2B4CLpH`,
	"withDropdown": `cg9XbjMI`,
	"size__default": `o5jcjAk1`,
	"size__full": `chAJ6uJH`,
	"show": `NeYPgeEb`,
	"trigger": `DkJdZXek`,
	"disabled": `Nq33rH_U`
};
export default ___CSS_LOADER_EXPORT___;
