import i18n from '@modules/i18n';

export interface PersonJson {
	email?: string;
	firstname?: string;
	lastname?: string;
	phone?: string;
	mobile?: string;
}
export class Person {
	public person: PersonJson;

	constructor(
		person: PersonJson
	) {
		this.person = person;
	}

	get email(): string | undefined {
		return this.person.email;
	}

	get firstname() {
		return this.person.firstname ? i18n.t('format.capitalize', {
			text: this.person.firstname
		}) : undefined;
	}

	get lastname() {
		return this.person.lastname ? i18n.t('format.uppercase', {
			text: this.person.lastname
		}) : undefined;
	}

	get full_name() {
		let fullname = undefined;
		if (this.person.firstname && this.person.lastname) {
			fullname = `${i18n.t('format.capitalize', {
				text: this.person.firstname
			})} ${i18n.t('format.uppercase', {
				text: this.person.lastname
			})}`;
		} else {
			if (this.person.firstname) {
				fullname = i18n.t('format.capitalize', {
					text: this.person.firstname
				});
			} else if (this.person.lastname) {
				fullname = i18n.t('format.uppercase', {
					text: this.person.lastname
				});
			}
		}
		return fullname;
	}

	get phone() {
		return this.person.phone ? i18n.t('format.tel', {
			'val': this.person.phone,
		}) : undefined;
	}
	get mobile() {
		return this.person.mobile ? i18n.t('format.tel', {
			'val': this.person.mobile,
		}) : undefined;
	}
}
