import {
	createSlice,
} from '@reduxjs/toolkit';
import {
	ToastProps,
} from '@components/toast';

export interface StoreToastMsgsInstance {
	instances: ToastProps[];
}

export const toastMsgsSlice = createSlice({
	name: 'toast_msgs',
	initialState: {
		instances: [
		]
	},
	reducers: {
		addToastMsg: (state, action) => {
			// state.instances.push(action.payload);
			state.instances = [
				...state.instances,
				action.payload
			];
		},
		clearToastMsgs: (state) => {
			state.instances = [
			];
		}
	}
});

// Action creators are generated for each case reducer function
export const { addToastMsg, clearToastMsgs } = toastMsgsSlice.actions;

export default toastMsgsSlice.reducer;
