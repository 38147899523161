import {
	ColorsProps,
} from '@components/cartography';

import {
	LayerProps,
} from 'react-map-gl';

const getCirclesShadowsLayerConfig = (
	sourceName: string,
	colors: ColorsProps
): LayerProps => {

	return {
		id: `${sourceName}_circles_shadow`,
		type: 'circle',
		layout: {
			'visibility': 'visible',
		},
		filter: [
			'!',
			[
				'has',
				'point_count'
			]
		],
		'paint': {
			'circle-radius': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'hover'
					],
					false
				],
				18,
				15,
			],
			'circle-color': colors.mine_shaft,
			'circle-blur': 1
		},
	};
};

export default getCirclesShadowsLayerConfig;
