import * as React from 'react';
import {
	ReactNode,
	MouseEvent,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';

// COMPONENTS
import Icon from '@components/icon';
import LabelStatus, {
	LabelStatusProps,
} from '@components/label-status';
import Link from '@components/link';
import Slider, {
	SliderProps,
} from '@components/slider';
import Tooltip from '@components/tooltip';

// STYLES
import styles from './card.module.scss';

interface CardProps {
	'data-testid'?: string;
	address: string;
	actions?: ReactNode;
	availability?: string;
	areaBuilding?: string;
	areaLot?: string;
	canSelect?: boolean;
	className?: string;
	checkboxElement?: ReactNode;
	dates?: string[];
	labels?: string[];
	reference?: {
		label?: string;
		url?: string;
	};
	iconsList?: {
		className?: string;
		id: string;
		name: string;
		style: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
		tooltip?: string;
	}[];
	isPinned?: boolean;
	isSelected?: boolean;
	transactionTypes?: string;
	mandate?: {
		type: string;
		theme: string;
	};
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	slider: SliderProps;
	price: ReactNode;
}

function Card({
	'data-testid': dataTestid,
	actions,
	address,
	areaBuilding,
	areaLot,
	availability,
	canSelect,
	checkboxElement,
	className,
	dates,
	iconsList,
	isPinned,
	isSelected,
	labels,
	mandate,
	onClick,
	price,
	reference,
	slider,
	transactionTypes,
	...otherProps
}: CardProps) {
	const cssClasses = [
		styles.card
	];

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.card__can_select);
	if (isPinned) cssClasses.push(styles.card__pinned);

	switch (isSelected) {
		case true:
			cssClasses.push(styles.card__selected);
			break;
		case false:
			cssClasses.push(styles.card__unselected);
			break;
	}

	const sliderPhotosComponent = (
		<Slider
			className={styles.slider}
			customPlaceholder={slider?.customPlaceholder}
			data-testid={`${dataTestid}-slider`}
			infinite={true}
			items={slider?.items}
			labelsTopLeft={slider?.labelsTopLeft}
			labelsTopRight={slider?.labelsTopRight}
			size='thumbnail'
			onClick={slider?.onClick}
		/>
	);

	const labelsElement = labels?.length ? (
		<div
			className={styles.labels}
			data-testid={`${dataTestid}-labels`}
		>
			<ul className={styles.labels_list}>
				{
					labels.map((label: string, index: number) => {
						return (
							<li
								className={styles.labels_item}
								key={index}
							>
								<LabelStatus
									context='female'
									data-testid={`${dataTestid}-status`}
									text={label}
								/>
							</li>
						);
					})
				}
			</ul>
		</div>
	) : null;

	return (
		<div
			{...otherProps}
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			onClick={onClick}
		>
			{checkboxElement}
			{sliderPhotosComponent}
			<div className={styles.container}>
				<div>
					<div className={styles.container_top} >
						{dates?.length ? (
							<div className={styles.dates}>
								{dates.map((date: string, index: number) => {
									return (
										<div
											className={styles.date_txt}
											key={index}
										>
											{date}
										</div>
									);
								})}
							</div>
						) : null}
						{labelsElement}
					</div>
					<ul className={styles.types}>
						{reference ? (
							<li
								className={styles.ref}
								data-testid={`${dataTestid}-ref`}
							>
								<Link
									href={reference.url}
									target={EnumTargetLink.BLANK}
								>{reference.label}&nbsp;-&nbsp;</Link>
							</li>
						) : null}
						<li className={styles.transaction_type}>
							{transactionTypes}
						</li>
						{mandate?.type ? (
							<li className={styles.mandate_type}>
								&nbsp;-&nbsp;
								<LabelStatus
									className={styles.label_status}
									data-testid={`${dataTestid}-mandate-type`}
									hasBackground={false}
									text={mandate.type}
									theme={mandate.theme as LabelStatusProps['theme']}
								/>
							</li>
						) : null}
					</ul>
					<div className={styles.address} >
						{address}
					</div>
					<div
						className={styles.price}
						data-testid={`${dataTestid}-price`}
					>
						{price}
					</div>
					<div className={styles.area}>
						{areaBuilding ? (
							<span className={styles.area_building}>
								{areaBuilding}
							</span>
						) : null}
						{areaLot ? (
							<span className={styles.area_lot}>
								{areaLot}
							</span>
						) : null}
					</div>
				</div>
				<div>
					<div className={styles.availability_icon}>
						<div
							className={styles.availability_at}
							data-testid={`${dataTestid}-availability-date`}
						>
							{availability}
						</div>
						{iconsList?.length ? (
							<div className={styles.icons_container}>
								{iconsList.map((icon, key: number) => {
									return (
										<Tooltip
											className={icon.className}
											content={icon.tooltip}
											id={icon.id}
											key={key}
										>
											<Icon
												data-testid={`${dataTestid}-${icon.id}`}
												fontStyle={icon.style}
												name={icon.name}
											/>
										</Tooltip>
									);
								})}
							</div>
						) : null}
					</div>
					<div className={styles.container_bottom}>
						{actions}
					</div>
				</div>
			</div>
		</div>
	);
}

export {
	Card as default,
	CardProps,
};
