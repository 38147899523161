import * as React from 'react';
import {
	MouseEvent,
	useEffect,
	useState,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';

// TYPES
import {
	Offer,
} from '@@types/Offer';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// Config
import {
	getItemsShareData,
} from '@static_data/button-with-dropdown-share.data';

// Components
import Button, {
} from '@components/button';
import ButtonWithDropdown from '@components/button-with-dropdown';

// Layouts
import PageOfferDetails from '@layouts/Pages/PageOfferDetails';

// Styles
import styles from './PanelDetails.module.scss';

interface PanelDetailsProps {
	[key: string]: string | boolean | object;
	className?: string;
	data?: Offer;
	onClickClose: (event: MouseEvent<HTMLElement>) => void;
	isPrivate: boolean;
	viewType?: string;
}

function PanelDetails({
	className,
	data,
	onClickClose,
	isPrivate,
	viewType = 'card',
	...otherProps
}: PanelDetailsProps) {
	const { t } = useTranslation();
	const sharedOfferUrl = window.location.href;

	const classNames = [
		styles.PanelDetails,
		'animation'
	];

	if (className) classNames.push(className);

	const [
		classNamesState,
		setClassNamesState
	] = useState(classNames);

	useEffect(() => {
		if (data) {
			setClassNamesState([
				...classNamesState,
				viewType === 'card' ? 'animation__ltr' : 'animation__rtl'
			]);
		} else {
			setClassNamesState(classNames);
		}
	}, [
		data
	]);

	const handleOnClickClose = (event: MouseEvent<HTMLElement>) => {
		onClickClose(event);
	};

	return (
		<div className={classNamesState.join(' ')}>
			<div className={styles.PanelDetails__header}>
				<Button
					hasBoxShadow={false}
					iconLeft={viewType === 'card' ? 'chevron-left' : 'chevron-right'}
					iconStyle={EnumFontStyle.LIGHT}
					theme={EnumTheme.NAKED}
					onClick={handleOnClickClose}
				/>
				<span>{t('format.capitalize', {
					text: t('general.action.back')
				})}</span>
				<span>&nbsp;-&nbsp;</span>
				<span>{t('format.capitalize', {
					text: t('page.demand.offer.detail.title')
				})}</span>
				{isPrivate ? null : (
					<ButtonWithDropdown
						className={styles.share}
						dropdownTheme={EnumTheme.SECONDARY}
						hasBoxShadow={false}
						iconLeft='share'
						isOpenAfterClick={true}
						items={getItemsShareData(sharedOfferUrl)}
						style={{
							right: '100%',
							top: '0'
						}}
						theme={EnumTheme.NAKED}
						title={{
							label: t('format.capitalize', {
								text: t('general.share.offer')
							})
						}}
					/>
				)}
			</div>
			<div className={styles.PanelDetails__body}>
				<PageOfferDetails
					data={data}
					isPrivate={isPrivate}
					{...otherProps}
				/>
			</div>
		</div>
	);
}

export default withTranslation()(PanelDetails);
