import {
	layer,
} from '@appConf/layers.conf';
import {
	LayerConfigSourceProps,
} from '@components/cartography';

const getTilesSourceConfig = (
	tileName: string): LayerConfigSourceProps => {
	return {
		id: `${tileName}-source`,
		type: 'raster',
		tiles: [
			`${layer.root_url}?LAYERS=${tileName}&TRANSPARENT=TRUE&FORMAT=image%2Fpng&KEY=${layer.key}&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=${layer.srs}&BBOX=${layer.bbox}&WIDTH=256&HEIGHT=256`,
		],
		tileSize: 256,
	};
};

export default getTilesSourceConfig;
