export interface ImageJson {
	category?: string;
	filename?: string;
	id?: string;
	main?: boolean;
	title?: string;
	url?: string;
}

export class Image {
	public image: ImageJson;

	constructor(
		image: ImageJson
	) {
		this.image = image;
	}

	get category(): string | undefined {
		return this.image.category;
	}

	get filename(): string | undefined {
		return this.image.filename;
	}

	get id(): string | undefined {
		return this.image.id;
	}

	get is_main(): boolean {
		return this.image.main || false;
	}

	get title(): string | undefined {
		return this.image.title;
	}

	get url(): string | undefined {
		return this.image.url;
	}
}
