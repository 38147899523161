import {
	Contact,
	ContactJson,
} from '@@types/index';

export interface ContactOfferJson extends ContactJson {
	contact_id?: number;
	comment?: string;
	is_mandated?: boolean;
	mandate_number?: string;
	original_mail_date?: string;
	reference?: string;
	role?: string;
	is_default?: boolean;
	web_reference?: string;
	web_title?: string;
}

export class ContactOffer extends Contact {
	public contactOffer: ContactOfferJson;

	constructor(
		contactOffer: ContactOfferJson
	) {
		super({
			civility_key: contactOffer.civility_key,
			company_name: contactOffer?.company_name,
			email: contactOffer?.email,
			enable: contactOffer?.enable,
			firstname: contactOffer?.firstname,
			full_name: contactOffer?.full_name,
			id: contactOffer?.id,
			job: contactOffer?.job,
			lastname: contactOffer?.lastname,
			logo: contactOffer?.logo,
			mobile: contactOffer?.mobile,
			phone: contactOffer?.phone,
		});
		this.contactOffer = contactOffer;
	}

	get contact_id(): number | undefined {
		return this.contactOffer?.contact_id;
	}
	get comment(): string | undefined {
		return this.contactOffer?.comment ? this.contactOffer.comment : undefined;
	}

	get is_default(): boolean | undefined {
		return this.contactOffer?.is_default || undefined;
	}

	get is_mandated(): boolean | undefined {
		return this.contactOffer?.is_mandated || undefined;
	}

	get mandate_number(): string | undefined {
		return this.contactOffer?.mandate_number ? this.contactOffer.mandate_number : undefined;
	}

	get original_mail_date(): string | undefined {
		return this.contactOffer?.original_mail_date ? this.contactOffer.original_mail_date : undefined;
	}

	get reference(): string | undefined {
		return this.contactOffer?.reference ? this.contactOffer.reference : undefined;
	}

	get role(): string | undefined {
		return this.contactOffer?.role ? this.contactOffer.role : undefined;
	}

	get web_reference(): string | undefined {
		return this.contactOffer?.web_reference ? this.contactOffer.web_reference : undefined;
	}

	get web_title(): string | undefined {
		return this.contactOffer?.web_title ? this.contactOffer.web_title : undefined;
	}
}
