import * as React from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	Cell as CellInterface,
	flexRender,
	Header,
} from '@tanstack/react-table';
import textUtils from '@modules/text';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';

//COMPONENTS
import Link from '@components/link';
import Cell, {
	TableCell,
} from '@components/table/cell';
import Icon from '@components/icon';

//STYLES
import styles from './row.module.scss';
interface TableRowProps<TData> {
	className?: string;
	'data-testid'?: string;
	'aria-rowindex'?: string;
	row?: CellInterface<TData, unknown>[] | Header<TData, unknown>[];
	rowgroup: string;
	selected?: boolean;
	getIsSelected?: boolean;
	options?: {
		canSelect?: boolean;
		mobile?: {
			nbDataToDisplay?: number;
		};
		sort?: string[];
	};
	theme?: 'column' | 'default';
}

const TableRow = <TData extends object>({
	className,
	'data-testid': dataTestid,
	'aria-rowindex': ariarowindex,
	row = [
	],
	rowgroup,
	selected = false,
	options = {
		mobile: {
			nbDataToDisplay: 3
		},
		sort: [
		]
	},
	theme = 'default',
}: TableRowProps<TData>): JSX.Element => {
	const { i18n } = useTranslation();

	const classes = [
		styles.row,
	];

	if (className) classes.push(className);
	classes.push(styles[`theme__${theme}`]);
	if (selected) classes.push(styles.row__selected);
	if (options.canSelect) classes.push(styles.row__canselect);
	let counterLegend = 0;
	let legendElement = null;

	const getHeaderCell = (cellData: Header<TData, unknown> & TableCell, cellDataIndex: number) => {
		const mustBeSortable = options?.sort?.length && options?.sort?.includes(cellData.id) ? true : false;
		if (cellData?.column.columnDef?.legend) {
			counterLegend++;
			legendElement = <sup className={styles.sup}> ({counterLegend})</sup>;
		} else {
			legendElement = null;
		}
		const sortingElement = mustBeSortable ? (
			<span className={styles.sort}>
				{cellData.column.getCanSort() && cellData.column.getIsSorted()
					? cellData.column.getIsSorted() === 'desc'
						? (
							<Icon
								fontStyle={EnumFontStyle.SOLID}
								name='sort-down'
							/>
						)
						: (
							<Icon
								fontStyle={EnumFontStyle.SOLID}
								name='sort-up'
							/>
						)
					: (
						<><Icon
							fontStyle={EnumFontStyle.SOLID}
							name='sort'
						/></>
					)}
			</span>
		) : null;

		return (
			<Cell
				aria-colindex={cellDataIndex}
				aria-sort={cellData.column.getIsSorted()}
				className={cellData.column.columnDef.className}
				data-testid={dataTestid}
				id={cellData.id}
				key={cellDataIndex}
				role='cellDataheader'
				type={cellData.column.columnDef.dataType || cellData.column.columnDef.type}
				{...{
					onClick: mustBeSortable ? cellData.column.getToggleSortingHandler() : null,
				}}
			>
				<span>{flexRender(cellData.column.columnDef.header, cellData.getContext())}{legendElement}</span>
				{sortingElement}
			</Cell>
		);
	};

	const getBodyCell = (cellData: TableCell, cellDataIndex: number) => {
		// First row cell is the one with no type and from the table without actions and selection cells
		const allCellData = cellData.row.getVisibleCells().filter((cell: TableCell) => !cell.column.columnDef.type);

		// nb cell data to display on mobile display mode
		const nbDataToDisplay = options?.mobile?.nbDataToDisplay;
		const firstXCellsDataIdsToDisplay = allCellData.slice(0, nbDataToDisplay).map((oneCellData: TableCell) => oneCellData.column.id);
		const type = cellData.column.columnDef.dataType || cellData.column.columnDef.type;
		let cellContent = null;
		if (cellData?.column.columnDef?.legend) {
			counterLegend++;
			legendElement =  <sup className={styles.sup}> ({counterLegend})</sup>;
		} else {
			legendElement = null;
		}
		switch (type) {
			case 'currency': {
				cellContent = textUtils.format(cellData.getValue(), type, i18n.language);
				break;
			}
			case 'ref': {
				cellContent = <><span className={styles.row__columnText}>{cellData.column.id}</span> <Link href='#'>{flexRender(cellData.column.columnDef.cell, cellData.getContext())}</Link></>;
				break;
			}
			case 'selection':
			case 'desktopActions':
			case 'mobileActions': {
				cellContent = <>{flexRender(cellData.column.columnDef.cell, cellData.getContext())}</>;
				break;
			}
			default: {
				cellContent = cellData.getValue();
			}
		}

		switch (theme) {
			case 'column': {
				cellContent = (
					<>
						<span className={styles.row__header_hidecell}>{cellData.column.columnDef.header}&nbsp;{legendElement}</span>
						<span className={styles.row__cell}>{cellContent}</span>
					</>
				);
				break;
			}
		}

		return (
			<Cell
				aria-colindex={cellDataIndex}
				aria-rowindex={ariarowindex}
				className={cellData.column.columnDef.dataType && firstXCellsDataIdsToDisplay.includes(cellData.column.id) ? null : (cellData.column.columnDef.dataType ? styles.row__hidecell : null)}
				key={cellDataIndex}
				name={cellData.column.dataName}
				theme={theme}
				type={type}
			>
				{cellContent}
			</Cell>
		);
	};

	let cells = null;
	if (rowgroup === 'header') {
		cells = row.map((cellData, cellDataIndex) => getHeaderCell(cellData as Header<TData, unknown> & TableCell, cellDataIndex));
	} else {
		cells = row.map((cellData, cellDataIndex) => getBodyCell(cellData as CellInterface<TData, unknown> & TableCell, cellDataIndex));
	}
	return (
		<div
			className={`${classes.join(' ')}`}
			data-testid={dataTestid}
			role='row'
		>
			{cells}
		</div>
	);
};

export {
	TableRow as default,
	TableRowProps,
};
