// SOURCE  : https://stackoverflow.com/a/42234988/6995445
import {
	useEffect,
	useState,
} from 'react';

function useMouseEnterLeaveEffect<T extends HTMLElement = HTMLElement>(
	ref: React.RefObject<T>,
	initialState : boolean
) {
	const [
		isActive,
		setIsActive
	] = useState(initialState);

	const handleMouseEntered = () => {
		setIsActive(true);
	};
	const handleMouseLeaved = () => {
		setIsActive(false);
	};

	useEffect(() => {
		ref?.current?.addEventListener('mouseenter', handleMouseEntered);
		ref?.current?.addEventListener('mouseleave', handleMouseLeaved);

		return () => {
			ref?.current?.removeEventListener('mouseenter', handleMouseEntered);
			ref?.current?.removeEventListener('mouseleave', handleMouseLeaved);
		};
	}, [
		isActive,
		ref
	]);

	return [
		isActive,
		ref
	];
}

export default useMouseEnterLeaveEffect;
