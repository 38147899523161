import {
	createSlice,
} from '@reduxjs/toolkit';

export enum DeviceDisplays {
	DESKTOP = 'desktop',
	TABLET = 'tablet',
  MOBILE = 'mobile',
}

export enum DeviceOrientations {
  LANDSCAPE = 'landscape-primary',
	PORTRAIT = 'portrait-primary',
}

export interface DeviceSliceInstance {
	instance: DeviceInstance;
}
export interface DeviceInstance {
	display: DeviceDisplays;
	orientation: DeviceOrientations;
}

const deviceSlice = createSlice({
	name: 'device',
	initialState: {
		instance: {
			display: 'mobile',
			orientation: 'portrait',
		}
	},
	reducers: {
		deviceUpdate: (state, action) => {
			state.instance = {
				...state.instance,
				display: action.payload.display,
				orientation: action.payload.orientation
			};
		},
	}
});

// Action creators are generated for each case reducer function
export const { deviceUpdate } = deviceSlice.actions;

const deviceSliceReducer = deviceSlice.reducer;

export default deviceSliceReducer;
