import * as React from 'react';
import {
	ReactNode,
} from 'react';

import styles from './ModalDefault.module.scss';

import Paragraph from '@components/paragraph';
import Image from '@components/image';

interface ModalDefaultProps {
	children?: ReactNode;
	illustration?: string;
	title?: string;
}

const ModalDefault = ({
	children,
	illustration,
	title
}: ModalDefaultProps) => {

	const modal_illustration = illustration ? (
		<Image
			className={styles.modal_illustration}
			src={illustration}
		/>
	) : null;

	return (
		<div className={`${styles.default}`}>
			{modal_illustration}
			{title ? <h2 className={styles.title}>{title}</h2> : null}
			<Paragraph
				className={styles.modal_content}
			>
				{children}
			</Paragraph>
		</div>
	);
};

export default ModalDefault;
