export interface EventHistoryJson {
	date?: string;
	event?: string;
}

export class EventHistory {
	public event_history: EventHistoryJson;
	public language: string;

	constructor(
		event_history: EventHistoryJson,
		language: string
	) {
		this.event_history = event_history;
		this.language = language;
	}

	get date(): string | undefined {
		return this.event_history.date ? new Date(this.event_history.date).toLocaleDateString(this.language) : undefined;
	}

	get event(): string | undefined {
		return this.event_history.event;
	}
}
