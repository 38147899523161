import {
	EnumFontStyle,
} from '@enums/font.enum';
import i18n from '@modules/i18n';
import {
	MouseEvent,
} from 'react';

export const getItemsShareData = (sharedLink: string) => {

	// TO_TEST : function can't be tested because it's on a child element and we don't have hasAccess to it in tests
	/* istanbul ignore next */
	const handleClickItem = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
	};

	const updateDropdownItemLabel = i18n.t('format.capitalize', {
		text: i18n.t('general.copied_link')
	});

	const originalDropdownItemLabel = i18n.t('format.capitalize', {
		text: i18n.t('general.copy_link')
	});

	const handleOnClickCopyLink = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		navigator.clipboard.writeText(sharedLink);
	};

	const itemsShare = [
		{
			hasAccess: true,
			fontWeight: EnumFontStyle.REGULAR,
			href: i18n.t('general.share.email.mailto', {
				'%subject%': i18n.t('format.capitalize', {
					text: i18n.t('page.demand.public.offer.email.subject')
				}),
				'%body%': i18n.t('format.capitalize', {
					text: i18n.t('page.demand.public.offer.email.body', {
						'%link%': sharedLink
					})
				})
			}),
			iconLeft: 'envelope',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.email')
			}),
			onClick: handleClickItem,
		},
		{
			hasAccess: true,
			fontWeight: EnumFontStyle.REGULAR,
			iconLeft: 'clone',
			labelEnabled: updateDropdownItemLabel,
			label: originalDropdownItemLabel,
			onClick: handleOnClickCopyLink,
			href: '/',
		}
	];

	return itemsShare;
};
