// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VojxVaQV{display:inline-flex}.lDjOIE6U div{max-height:28.57142857rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_with_dropdown": `VojxVaQV`,
	"short_dropdown": `lDjOIE6U`
};
export default ___CSS_LOADER_EXPORT___;
