import {
	createSlice,
} from '@reduxjs/toolkit';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// TYPES
import {
	CollectionJson,
	DocumentTemplatesProps,
} from '@@types/Collection';
import {
	ContactJson,
	UserJson,
} from '@@types/index';
import {
	ObservatoryReport,
} from '@@types/User';

export interface GlobalUser extends UserJson {
	auth_step?: string;
	authenticated?: boolean;
	expired_session?: number;
	observatory_reports?: ObservatoryReport[];
	otherUsers?: UserJson[];
	company?: {
		brokers?: CollectionJson<ContactJson>;
		document_templates?: CollectionJson<DocumentTemplatesProps>;
		id?: number;
		name?: string;
	};
}

export interface GlobalUserInstance {
	instance: GlobalUser;
}

// Define the initial state using that type
const initialState: GlobalUserInstance = {
	// État initial de notre reducer qui stock les formulaires
	instance: {
		authenticated: false,
		expired_session: null,
		otherUsers: [
		],
		modules: {
		},
		observatory_reports: [
		]
	}
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		userSetCompanyBrokers: (state, action) => {
			state.instance.company = {
				...state.instance.company,
				brokers: action.payload.brokers,
			};
		},
		userSetDocumentsTemplates: (state, action) => {
			state.instance.company = {
				...state.instance.company,
				document_templates: action.payload.document_templates,
			};
		},
		userLogin: (state, action) => {
			state.instance = {
				...state.instance,
				...action.payload,
				authenticated: true
			};
		},
		userLogout: (state) => {
			state.instance = {
				authenticated: false
			};
		},
		userNeedsAuth: (state, action) => {
			state.instance = {
				...state.instance,
				auth_step: action.payload,
			};
		},
		userUpdate: (state, action) => {
			state.instance = {
				...state.instance,
				...action.payload
			};
		},
		userUpdateSession: (state) => {
			const newExpiredDate = new Date(Date.now() + APP_CONF_VARS.session.lifetime).getTime();
			state.instance = {
				...state.instance,
				expired_session: newExpiredDate
			};
		},
		userClearOtherUsers: (state) => {
			state.instance = {
				...state.instance,
				otherUsers: [
				]
			};
		},
		userSetOtherUsers: (state, action) => {
			state.instance = {
				...state.instance,
				otherUsers: action.payload
			};
		},
		userNotifyOtherUser: (state, action) => {
			// On the user workflow with multiple connection
			// To store which other user has been notified

			// Get targeted other user data from actual state
			const targetedOtherUser = state.instance.otherUsers.filter(otherUser => otherUser.id === action.payload.id)[0];

			// Get other users array without targeted one
			const filteredOtherUsers = state.instance.otherUsers.filter(otherUser => otherUser.id !== action.payload.id);

			// Create new targeted user data state
			const newTargetedOtherUsers = {
				...targetedOtherUser,
				notified: true
			};

			// Enhance targeted user data
			filteredOtherUsers.push(newTargetedOtherUsers);

			// Update slice state
			state.instance = {
				...state.instance,
				otherUsers: filteredOtherUsers
			};
		},
		userUpdateModuleActivity: (state, action) => {
			state.instance = {
				...state.instance,
				modules: {
					...state.instance.modules,
					[action.payload.module]: {
						...state.instance.modules[action.payload.module],
						activity: {
							...state.instance.modules[action.payload.module].activity,
							[action.payload.activity]: action.payload.value,
						}
					}
				}
			};
		},
		userUpdateOservatoryReports: (state, action) => {
			state.instance = {
				...state.instance,
				observatory_reports: action.payload
			};
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	userSetCompanyBrokers,
	userClearOtherUsers,
	userSetDocumentsTemplates,
	userLogin,
	userLogout,
	userNeedsAuth,
	userNotifyOtherUser,
	userSetOtherUsers,
	userUpdate,
	userUpdateModuleActivity,
	userUpdateOservatoryReports,
	userUpdateSession
} = userSlice.actions;

export default userSlice.reducer;
