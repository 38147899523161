import {
	Counters,
} from '@@types/Settings';
import {
	AreaJson,
	Building,
	BuildingJson,
	Collection,
	CollectionJson,
	Contact,
	ContactJson,
	EventHistory,
	EventHistoryJson,
	FieldValue,
	FieldValueJson,
	Image,
	ImageJson,
	Lot,
	LotJson,
	Mandate,
	MandateJson,
	Price,
	PriceJson,
	PriceOrStateValue,
} from '@@types/index';

export interface DateTextJson {
	date?: string;
	date_txt?: string;
	state?: string;
}

export interface DateVisit {
	comment?: string;
	state: string;
	contact_id: number;
	date?: string;
	time?: string;
}
export interface LotsResume {
	count?: number;
	area_max?: number;
	area_min?: number;
}

export interface OfferJson {
	applied_at?: string;
	area?: AreaJson;
	availability?: DateTextJson;
	building?: BuildingJson;
	charge?: PriceJson;
	commercialization_step?: string;
	commercialized_at?: string;
	contacts?: CollectionJson<ContactJson>;
	counters?: Counters;
	created_at?: string;
	description?: string;
	energy_performance_diagnostic?: FieldValueJson[];
	event_history?: CollectionJson<EventHistoryJson>;
	financial_terms?: FieldValueJson[];
	id: number;
	ignored_at?: string;
	images?: CollectionJson<ImageJson>;
	is_added?: boolean;
	is_private?: boolean;
	is_search_criterias_matched?: boolean;
	legal_terms?: FieldValueJson[];
	lots?: CollectionJson<LotJson>;
	lots_resume?: LotsResume;
	mandate?: MandateJson;
	postal_code?: string;
	proposed_at?: string;
	refused_at?: string;
	rental_price?: PriceOrStateValue;
	rental_ref_price_unit?: string;
	sale_price?: PriceOrStateValue;
	send_date?: number;
	services?: FieldValueJson[];
	states?: string[];
	suspended_at?: string;
	title?: string;
	transaction_types: string[];
	transport_service?: string;
	updated_at?: string;
	visit?: {
		comment?: string;
		state: string;
		contact_id: number;
		date_time?: string;
	};
	visited_at?: string;
}

export class Offer {
	public offer: OfferJson;
	public language: string;

	constructor(
		offer: OfferJson,
		language?: string
	) {
		this.offer = offer;
		this.language = language || 'fr-FR';
	}

	get area(): number | undefined {
		return this.offer.building?.area?.value;
	}

	get applied_at(): string | undefined {
		return this.offer.applied_at ? new Date(this.offer.applied_at).toLocaleDateString(this.language) : undefined;
	}

	get availability(): DateTextJson {
		return {
			date: this.offer.availability?.date ? new Date(this.offer.availability?.date).toLocaleDateString(this.language) : undefined,
			date_txt: this.offer.availability?.date_txt,
			state: this.offer.availability?.state
		};
	}

	get building(): Building | undefined {
		return this.offer.building ? new Building(this.offer.building) : undefined;
	}

	get charge(): Price | undefined {
		return this.offer.charge?.amount ? new Price(this.offer.charge as PriceJson, this.language) : undefined;
	}

	get commercialization_step(): string | undefined {
		return this.offer.commercialization_step;
	}

	get commercialized_at(): string | undefined {
		return this.offer.commercialized_at ? new Date(this.offer.commercialized_at).toLocaleDateString(this.language) : undefined;
	}

	get contacts(): Collection<ContactJson, Contact> | undefined {
		return this.offer.contacts ? new Collection(this.offer.contacts, Contact, this.language) : undefined;
	}

	get counters(): Counters | undefined {
		return this.offer.counters;
	}

	get created_at(): string | undefined {
		return this.offer.created_at ? new Date(this.offer.created_at).toLocaleDateString(this.language) : undefined;
	}

	get description(): string | undefined {
		return this.offer.description;
	}

	get energy_performance_diagnostic(): FieldValue[] | undefined {
		const results: FieldValue[] = [
		];

		if (this.offer.energy_performance_diagnostic?.length) {
			this.offer.energy_performance_diagnostic.forEach((fieldValue: FieldValueJson) => {
				results.push(new FieldValue(fieldValue));
			});
		}

		return results.length ? results : undefined;
	}

	get event_history(): Collection<EventHistoryJson, EventHistory> | undefined {
		return this.offer.event_history ? new Collection(this.offer.event_history, EventHistory) : undefined;
	}

	get financial_terms(): FieldValue[] | undefined {
		const results: FieldValue[] = [
		];

		if (this.offer.financial_terms?.length) {
			this.offer.financial_terms.forEach((fieldValue: FieldValueJson) => {
				results.push(new FieldValue(fieldValue));
			});
		}

		return results.length ? results : undefined;
	}

	get id(): number {
		return this.offer.id;
	}

	get ignored_at(): string | undefined {
		return this.offer.ignored_at ? new Date(this.offer.ignored_at).toLocaleDateString(this.language) : undefined;
	}

	get images(): Collection<ImageJson, Image> | undefined {
		return this.offer.images ? new Collection(this.offer.images, Image) : undefined;
	}

	get is_private(): boolean {
		return this.offer.is_private ? true : false;
	}

	get is_added(): boolean {
		return this.offer.is_added ? true : false;
	}

	get is_search_criterias_matched(): boolean {
		return this.offer.is_search_criterias_matched ? true : false;
	}

	get legal_terms(): FieldValue[] | undefined {
		const results: FieldValue[] = [
		];

		if (this.offer.legal_terms?.length) {
			this.offer.legal_terms.forEach((fieldValue: FieldValueJson) => {
				results.push(new FieldValue(fieldValue));
			});
		}

		return results.length ? results : undefined;
	}

	get lots(): Collection<LotJson, Lot> | undefined {
		return this.offer.lots ? new Collection(this.offer.lots, Lot ,this.language) : undefined;
	}

	get lots_resume(): LotsResume | undefined {
		return this.offer.lots_resume ? {
			count: this.offer.lots_resume?.count,
			area_max: this.offer.lots_resume?.area_max,
			area_min: this.offer.lots_resume?.area_min,
		} : undefined;
	}

	get mandate(): Mandate | undefined {
		return this.offer.mandate ? new Mandate(this.offer.mandate) : undefined;
	}

	get postal_code(): string | undefined {
		return this.offer.building?.address?.postal_code;
	}

	get proposed_at(): string | undefined {
		return this.offer.proposed_at ? new Date(this.offer.proposed_at).toLocaleDateString(this.language) : undefined;
	}

	get refused_at(): string | undefined {
		return this.offer.refused_at ? new Date(this.offer.refused_at).toLocaleDateString(this.language) : undefined;
	}

	get rental_price_value(): Price | undefined {
		return this.offer.rental_price?.value ? new Price(this.offer.rental_price.value, this.language) : undefined;
	}

	get rental_price_state(): string | undefined {
		return this.offer.rental_price?.state;
	}

	get rental_ref_price_unit(): string | undefined {
		return this.offer.rental_ref_price_unit;
	}

	get sale_price_value(): Price | undefined {
		return this.offer.sale_price?.value ? new Price(this.offer.sale_price.value, this.language) : undefined;
	}

	get sale_price_state(): string | undefined {
		return this.offer.sale_price?.state;
	}

	get send_date(): number | undefined {
		return this.offer.proposed_at ? new Date(this.offer.proposed_at).getTime() : undefined;
	}

	get services(): FieldValue[] | undefined {
		const results: FieldValue[] = [
		];

		if (this.offer.services?.length) {
			this.offer.services.forEach((fieldValue: FieldValueJson) => {
				results.push(new FieldValue(fieldValue));
			});
		}

		return results.length ? results : undefined;
	}

	get states(): string[] | undefined {
		return this.offer.states;
	}

	set states(newStates: string[] | undefined) {
		interface statesDictionaryReplacement {
			[index: string]: string[];
		}

		if (newStates && this.offer.states) {
			let filteredStates = [
				...this.offer.states
			];

			const replacingStates: statesDictionaryReplacement = {
				'viewed': [
					'new',
					'updated',
				],
			};

			newStates.forEach(state => {
				if (replacingStates[state]) {
					replacingStates[state].forEach((toReplaceState: string) => {
						filteredStates = filteredStates.filter(state => state !== toReplaceState);
					});
				}
				if (!filteredStates.includes(state)) {
					filteredStates.push(state);
				}
			});
			this.offer.states = filteredStates;
		} else {
			this.offer.states = undefined;
		}
	}

	get suspended_at(): string | undefined {
		return this.offer.suspended_at ? new Date(this.offer.suspended_at).toLocaleDateString(this.language) : undefined;
	}

	get title(): string | undefined {
		return this.offer.title;
	}

	get transaction_types(): string[] {
		const availableTransactionTypes = [
			'sale',
			'rent'
		];
		const isValidTransactionType = (this.offer.transaction_types).some(type => availableTransactionTypes.includes(type));
		return isValidTransactionType ? this.offer.transaction_types : [
			'rent'
		];
	}

	get transport_service(): string | undefined {
		return this.offer.transport_service;
	}

	get updated_at(): string | undefined {
		return this.offer.updated_at ? new Date(this.offer.updated_at).toLocaleDateString(this.language) : undefined;
	}

	get visited_at(): string | undefined {
		return this.offer.visited_at ? new Date(this.offer.visited_at).toLocaleDateString(this.language) : undefined;
	}

	get visit(): DateVisit | undefined {
		const date_time = this.offer.visit?.date_time ? new Date(this.offer.visit.date_time) : undefined;
		const currentHours = date_time ? date_time.getHours() < 10 ? `0${date_time.getHours()}` : date_time.getHours() : undefined;
		const currentMinutes = date_time ? date_time.getMinutes() < 10 ? `0${date_time.getMinutes()}` : date_time.getMinutes() : undefined;

		return this.offer.visit ? {
			comment: this.offer.visit?.comment,
			state: this.offer.visit?.state,
			contact_id: this.offer.visit.contact_id,
			date: this.offer.visit?.date_time ? new Date(this.offer.visit.date_time).toLocaleDateString(this.language) : undefined,
			time: this.offer.visit?.date_time ? `${currentHours}:${currentMinutes}` : undefined,
		} : undefined;
	}
}
