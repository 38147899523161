// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VcI9SuNP{align-items:center;background-color:var(--color-toggle-background);border:1Px solid var(--color-toggle-border);border-radius:1em;display:flex;justify-content:left;min-height:1.71428571rem;min-width:3rem;position:relative}.VcI9SuNP:hover .gZNdClWr{background-color:hsla(var(--color-toggle-circle-h), var(--color-toggle-circle-s), var(--color-toggle-circle-l), 0.3)}.VcI9SuNP .gZNdClWr{align-items:center;border-radius:50%;cursor:pointer;display:flex;height:1.71428571rem;justify-content:center;transition:.4s;width:1.71428571rem}.VcI9SuNP .mmxfN6nZ{align-items:center;background-color:var(--color-toggle-circle);border-radius:50%;display:flex;height:1.14285714rem;justify-content:center;width:1.14285714rem}.VcI9SuNP .mmxfN6nZ i{color:var(--color-white);font-size:0.64285714rem;transition:.4s}.wRDtudn9{background-color:hsla(var(--color-toggle-background-active-h), var(--color-toggle-background-active-s), var(--color-toggle-background-active-l), 0.2);border:1Px solid var(--color-toggle-border-active)}.wRDtudn9:hover .gZNdClWr{background-color:hsla(var(--color-toggle-background-active-h), var(--color-toggle-background-active-s), var(--color-toggle-background-active-l), 0.3)}.wRDtudn9 .gZNdClWr{transform:translateX(1.21428571rem)}.wRDtudn9 .mmxfN6nZ{background-color:var(--color-toggle-background-active)}.IrYe9x_U{cursor:not-allowed;opacity:.5;pointer-events:none;transition:opacity .05s ease-in-out}.IrYe9x_U i{cursor:not-allowed;pointer-events:none}.IrYe9x_U:hover:before{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `VcI9SuNP`,
	"shadow": `gZNdClWr`,
	"circle": `mmxfN6nZ`,
	"toggle__checked": `wRDtudn9`,
	"toggle__disabled": `IrYe9x_U`
};
export default ___CSS_LOADER_EXPORT___;
