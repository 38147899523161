import * as React from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';

// COMPONENTS
import Icon from '@components/icon';

//STYLES
import styles from './toggle.module.scss';

interface ToggleProps {
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	toggleIcon?: boolean;
	'data-testid'?: string;
}

const Toggle = ({
	checked,
	className,
	disabled = false,
	toggleIcon = false,
	'data-testid': dataTestid,
}: ToggleProps): JSX.Element => {

	const cssClasses = [
		styles.toggle
	];

	if (className) {
		cssClasses.push(className);
	}

	if (checked) {
		cssClasses.push(styles.toggle__checked);
	}

	if (disabled) {
		cssClasses.push(styles.toggle__disabled);
	}

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			<div className={styles.shadow}>
				<div className={styles.circle}>
					{(toggleIcon) ? (
						<Icon
							fontStyle={EnumFontStyle.REGULAR}
							name={(checked) ? 'check' : 'times'}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export {
	Toggle as default,
	ToggleProps,
};
