class BaseError extends Error {

	constructor (message: string, stack?: string) {
		super(message);

		Object.setPrototypeOf(this, new.target.prototype);
		this.name = this.constructor.name;
		this.stack = stack;
	}
}

export default BaseError;
