import * as React from 'react';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// STYLES
import styles from './badge.module.scss';

interface BadgeProps {
	className?: string;
	'data-testid'?: string;
	theme?: EnumStatusTheme;
}

const Badge = ({
	className,
	'data-testid': dataTestid,
	theme = EnumStatusTheme.ERROR,
}: BadgeProps): JSX.Element => {

	const cssClasses = [
		styles.badge,
	];

	cssClasses.push(styles[`${'theme__' + theme}`]);

	if (className) cssClasses.push(className);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		></div>
	);
};

export {
	Badge as default,
	BadgeProps,
};

