import * as React from 'react';

import {
	MouseEvent,
	ReactElement,
	JSXElementConstructor,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonCounterTheme,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumThemeButton,
} from '@enums/theme.enum';

//HOOK
import useMouseEnterLeaveEffect from '@hooks/useMouseEnterLeaveEffect/hook.useMouseEnterLeaveEffect';
import useClickOutsideEffect from '@hooks/useClickOutsideEffect/hook.useClickOutsideEffect';

//STYLES
import styles from './with-dropdown.module.scss';

interface withDropdownprops {
	badgeCounter?: number;
	badgeTheme?: EnumStatusTheme;
	className?: string;
	classNameDropdown?: string;
	classNameTrigger?: string;
	children?: ReactElement;
	corners?: EnumButtonCorners;
	counter?: number;
	counterTheme?: EnumButtonCounterTheme;
	'data-testid'?: string;
	disabled?: boolean;
	dropdownIsActive?: boolean;
	hasAccess?: boolean;
	hasBoxShadow?: boolean;
	hasClear?: boolean;
	iconLeft?: string;
	iconRight?: string;
	iconName?: string;
	iconStyle?: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
	id?: string;
	isMultiSelect?: boolean;
	label?: string;
	loader?: boolean;
	name?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	onClickOutside?: (event: MouseEvent<HTMLElement>) => void;
	selectedIndex?: number;
	dropdownSize?: 'default' | 'full';
	size?: EnumButtonSize;
	style?: {
		bottom?: string;
		left?: string;
		right?: string;
		top?: string;
	};
	tabIndex?: number;
	theme?: EnumThemeButton;
	title?: {
		label?: string;
		titleLevel?: number;
	};
	tooltipText?: string;

}

function withDropdown(WrappedComponent: JSXElementConstructor<withDropdownprops>, dropdownElement: ReactElement) {

	return ({
		badgeTheme,
		children,
		className,
		classNameDropdown,
		classNameTrigger,
		corners,
		'data-testid': dataTestid,
		disabled,
		dropdownIsActive = false,
		dropdownSize,
		hasAccess,
		hasBoxShadow,
		hasClear,
		iconLeft,
		iconRight,
		iconName,
		iconStyle,
		id,
		isMultiSelect,
		label,
		name,
		onClick,
		onClickOutside,
		size,
		style,
		theme,
		tooltipText,
		...otherprops
	}: withDropdownprops): JSX.Element | undefined => {
		const dropdownRef = useRef(null);
		const [
			isOpen,
			setIsOpen
		] = useState(dropdownIsActive);

		const cssClasses = [
			styles.withDropdown
		];

		const cssClassesTrigger = [
			styles.trigger,
		];

		const cssClassesDropdown = [
			styles.dropdown,
		];

		cssClassesDropdown.push(styles[`size__${dropdownSize}`]);

		if (className) cssClasses.push(className);
		if (classNameTrigger) cssClassesTrigger.push(classNameTrigger);
		if (disabled) cssClassesTrigger.push(styles.disabled);
		if (classNameDropdown) cssClassesDropdown.push(classNameDropdown);
		if (isOpen) cssClassesDropdown.push(styles.show);

		const handleOnClick = (event: MouseEvent<HTMLElement>) => {
			event.preventDefault();
			setIsOpen(!isOpen);
			onClick ? onClick(event) : null;
		};

		const handleHideDropdown = (event: MouseEvent<HTMLElement>) => {
			isOpen && onClickOutside ? onClickOutside(event) : null;
			setIsOpen(false);
		};

		useClickOutsideEffect([
			dropdownRef
		], handleHideDropdown);

		const [
			isActive
		] = useMouseEnterLeaveEffect(dropdownRef, dropdownIsActive);

		useEffect(() => {
			const timer = !isActive && setTimeout(handleHideDropdown, APP_CONF_VARS.timeout.mouseleave);
			return () => {
				timer ? clearTimeout(timer) : undefined;
			};
		}, [
			isActive
		]);

		let dropdownMenuElement = undefined;
		const hasItems = dropdownElement && WrappedComponent !== null;
		if (hasItems) {
			dropdownMenuElement = (
				<div
					aria-expanded={isOpen ? 'true' : 'false'}
					className={`${cssClasses.join(' ')}`}
					data-testid={dataTestid}
					ref={dropdownRef}
				>
					<WrappedComponent
						badgeTheme={badgeTheme}
						className={cssClassesTrigger.join(' ')}
						corners={corners}
						data-testid={`${dataTestid}-trigger`}
						disabled={disabled}
						hasAccess={hasAccess}
						hasBoxShadow={hasBoxShadow}
						hasClear={hasClear}
						iconLeft={iconLeft}
						iconName={iconName}
						iconRight={iconRight}
						iconStyle={iconStyle}
						id={id}
						isMultiSelect={isMultiSelect}
						label={label}
						name={name}
						size={size}
						theme={theme}
						tooltipText={tooltipText}
						onClick={!disabled ? handleOnClick : undefined}
						{...otherprops}
					>{children}</WrappedComponent>
					<div
						className={cssClassesDropdown.join(' ')}
						data-testid={`${dataTestid}-dropdown`}
						style={style}
					>
						{dropdownElement}
					</div>
				</div>
			);
		}
		return dropdownMenuElement;
	};
}

export {
	withDropdown as default,
	withDropdownprops,
};

