// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V1CgAnpI{max-width:100%;position:relative;width:19.42857143rem}.V1CgAnpI .rcZj7mnZ{font-size:1rem;text-align:left;font-weight:600;font-size:14px}.V1CgAnpI .sM7Eqk3F{padding:1.14285714rem;text-align:right}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup_offer": `V1CgAnpI`,
	"address": `rcZj7mnZ`,
	"infos": `sM7Eqk3F`
};
export default ___CSS_LOADER_EXPORT___;
