// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V6LDh08d{font-size:1.4285714286em}.SRHaizxK{align-items:flex-start;display:flex}.PBmB7K6k{align-items:center;display:inline-flex;position:relative}.PBmB7K6k>.V6LDh08d{margin-right:0.57142857rem}.PBmB7K6k .SRHaizxK+.V6LDh08d{margin-left:0.57142857rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `V6LDh08d`,
	"text": `SRHaizxK`,
	"label": `PBmB7K6k`
};
export default ___CSS_LOADER_EXPORT___;
