import {
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';

const allowedDisplays = [
	'desktop',
	'mobile',
	'tablet'
];

export interface AppStateInstanceDevice {
	navIsOpen: false;
}

export interface AppStateInstanceLocalization {
	actualLanguage: string;
	defaultLanguage: string;
}

export interface AppStateInstanceRoutes {
	[key: string]: string;
	from: string;
}

export interface AppStateInstance {
	[key: string]: string | object[] | boolean | AppStateInstanceDevice | AppStateInstanceLocalization | AppStateInstanceRoutes;
	dataLayer: object[];
	desktop: AppStateInstanceDevice;
	isLoading: boolean;
	localization: AppStateInstanceLocalization;
	mobile: AppStateInstanceDevice;
	navIsOpen: boolean;
	tablet: AppStateInstanceDevice;
	routes: AppStateInstanceRoutes;
}

// Define a type for the slice state
export interface AppState {
	instance: AppStateInstance;
}

// Define the initial state using that type
const initialState: AppState = {
	instance: {
		routes: {
			from: null
		},
		dataLayer: [
			{
			}
		],
		desktop: {
			navIsOpen: false
		},
		isLoading: true,
		localization: {
			defaultLanguage: 'fr-FR',
			actualLanguage: 'fr-FR',
		},
		mobile: {
			navIsOpen: false
		},
		navIsOpen: false,
		tablet: {
			navIsOpen: false
		}
	}
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		appNavClose: (state, action: PayloadAction<string>) => {
			if (allowedDisplays.includes(action.payload)) {
				state.instance = {
					...state.instance,
					navIsOpen: false,
					[action.payload]: {
						...(state.instance[action.payload] as AppStateInstanceDevice),
						navIsOpen: false
					}
				};
			}
		},
		appNavOpen: (state, action) => {
			if (allowedDisplays.includes(action.payload)) {
				state.instance = {
					...state.instance,
					navIsOpen: true,
					[action.payload]: {
						...(state.instance[action.payload] as AppStateInstanceDevice),
						navIsOpen: true
					}
				};
			}
		},
		appNavSet: (state, action) => {
			// will set the nav state from the display state
			if (allowedDisplays.includes(action.payload)) {
				state.instance = {
					...state.instance,
					navIsOpen: (state.instance[action.payload] as AppStateInstanceDevice).navIsOpen
				};
			}
		},
		appNavToggle: (state, action) => {
			if (allowedDisplays.includes(action.payload)) {

				state.instance = {
					...state.instance,
					navIsOpen: !(state.instance[action.payload] as AppStateInstanceDevice).navIsOpen,
					[action.payload]: {
						...(state.instance[action.payload] as AppStateInstanceDevice),
						navIsOpen: !(state.instance[action.payload] as AppStateInstanceDevice).navIsOpen
					}
				};
			}
		},
		appSetRoute: (state, action) => {
			state.instance = {
				...state.instance,
				routes: {
					...state.instance.routes,
					[action.payload.name]: action.payload.url
				}
			};
		},
		appSwitchLanguage: (state, action) => {
			state.instance = {
				...state.instance,
				localization: {
					...state.instance.localization,
					actualLanguage: action.payload
				}
			};
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	appNavClose,
	appNavOpen,
	appNavSet,
	appNavToggle,
	appSetRoute,
	appSwitchLanguage
} = appSlice.actions;

export default appSlice.reducer;
