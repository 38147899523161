import {
	Area,
	AreaJson,
	DateTextJson,
	Price,
	PriceOrStateValue,
} from '@@types/index';

export interface LotJson {
	area?: AreaJson;
	availability?: DateTextJson;
	building?: string;
	floor?: string;
	id?: number;
	natures?: Array<string>;
	reference?: string;
	rental_price?: PriceOrStateValue;
	sale_price?: PriceOrStateValue;
}

export class Lot {
	public lot: LotJson;
	public language: string;

	constructor(
		lot: LotJson,
		language: string
	) {
		this.lot = lot;
		this.language = language;
	}

	get area(): Area | undefined {
		return this.lot.area ? new Area(this.lot.area) : undefined;
	}

	get availability(): DateTextJson | undefined {
		return this.lot.availability ? {
			date: this.lot.availability?.date ? new Date(this.lot.availability?.date).toLocaleDateString(this.language) : undefined,
			date_txt: this.lot.availability.date_txt,
			state: this.lot.availability.state
		} : undefined;
	}

	get building(): string | undefined {
		return this.lot.building;
	}

	get floor(): string | undefined {
		return this.lot.floor;
	}

	get id(): number | undefined {
		return this.lot.id;
	}

	get natures(): string[] | undefined {
		return this.lot.natures;
	}

	get reference(): string | undefined {
		return this.lot.reference;
	}

	get rental_price(): Price | undefined {
		return this.lot.rental_price?.value ? new Price(this.lot.rental_price.value, this.language) : undefined;
	}

	get sale_price(): Price | undefined {
		return this.lot.sale_price?.value ? new Price(this.lot.sale_price.value, this.language) : undefined;
	}
}
