// ENUMS
import {
	EnumSwitchView,
} from '@enums/button.enum';

// TYPES
import {
	Area,
	AreaJson,
	Collection,
	CollectionJson,
	Company,
	CompanyJson,
	Contact,
	ContactJson,
	Offer,
	OfferJson,
	Price,
	PriceJson,
	User,
	UserJson,
} from '@@types/index';

import {
	GeoJsonObject,
} from 'geojson';
import {
	Counters,
} from '@@types/Settings';

export interface PageDemandOutletContextType {
	demandGlobalState?: DemandJsonPrivate | DemandJsonPublic;
	demandIsLoaded: boolean;
	isDesktopResolution: boolean;
}

export interface UserDateJson {
	date?: string;
	user?: UserJson;
}

export interface UserDate {
	date?: string;
	user?: User;
}

export interface RecipientJson {
	company?: CompanyJson;
	contacts?: CollectionJson<ContactJson>;
}

export interface Recipient {
	company?: Company;
	contacts?: Collection<ContactJson, Contact>;
}

export enum AddressDisplayType {
	COMPLETE_ADDRESS = 'complete_address',
	POSTAL_CODE_AND_CITY = 'postal_code_and_city',
}

export interface SearchCriteriasJson {
	address_display_type?: AddressDisplayType;
	area?: {
		max?: AreaJson;
		min?: AreaJson;
	};
	budget?: {
		max?: PriceJson;
		min?: PriceJson;
	};
	budget_m2?: {
		max?: PriceJson;
		min?: PriceJson;
	};
	demand_types?: Array<string>;
	locations?: Array<string>;
	natures?: Array<string>;
}

export interface SearchCriterias {
	address_display_type?: AddressDisplayType;
	area?: {
		max?: Area;
		min?: Area;
	};
	budget?: {
		max?: Price;
		min?: Price;
	};
	budget_m2?: {
		max?: Price;
		min?: Price;
	};
	demand_types?: Array<string>;
	locations?: Array<string>;
	natures?: Array<string>;
}

interface GeoJsonObjectProps extends GeoJsonObject {
	features?: object[];
}

export interface DemandJson {
	broker_company?: CompanyJson;
	created_infos?: UserDateJson;
	counters?: Counters;
	geo_json?: GeoJsonObjectProps;
	new_offers_count?: number;
	offers?: CollectionJson<OfferJson>;
	recipient?: RecipientJson;
	search_criterias?: SearchCriteriasJson;
	settings?: {
		notifications?: {
			enable_new_matching?: boolean;
		};
		display?: {
			view_mode?: EnumSwitchView;
		};
	};
	sources?: string[];
	state?: string;
	offer_states?: string[];
	updated_infos?: UserDateJson;
	viewed_at?: string;
}

export interface DemandJsonPublic extends DemandJson {
	id: number;
	shared_demand_token?: string;
}

export interface DemandJsonPrivate extends DemandJson {
	id?: number;
	shared_demand_token: string;
}

export class Demand {
	public demand: DemandJsonPublic | DemandJsonPrivate;
	public language: string;

	constructor(
		demand: DemandJsonPublic | DemandJsonPrivate,
		language?: string
	) {
		this.demand = demand;
		this.language = language || 'fr-FR';
	}

	get broker_company(): Company | undefined {
		return this.demand.broker_company ? new Company(this.demand.broker_company, this.language) : undefined;
	}

	get counters(): Counters | undefined {
		return this.demand.counters;
	}

	get created_infos(): UserDate | undefined {
		return {
			date: this.demand.created_infos?.date ? new Date(this.demand.created_infos.date).toLocaleDateString(this.language) : undefined,
			user: this.demand.created_infos?.user ? new User(this.demand.created_infos.user) : undefined
		};
	}

	get geo_json(): GeoJsonObject | undefined {
		return this.demand.geo_json;
	}

	get id(): number | undefined {
		return this.demand.id;
	}

	get new_offers_count(): number | undefined {
		return this.demand.new_offers_count;
	}

	get offers(): Collection<OfferJson, Offer> | undefined {
		return this.demand.offers ? new Collection(this.demand.offers, Offer) : undefined;
	}

	get recipient(): Recipient | undefined {
		return {
			company: this.demand.recipient?.company ? new Company(this.demand.recipient.company, this.language) : undefined,
			contacts: this.demand.recipient?.contacts ? new Collection(this.demand.recipient.contacts, Contact, this.language) : undefined,
		};
	}

	get search_criterias(): SearchCriterias | undefined {
		return {
			address_display_type: this.searchCriteriasAddressDisplayType,
			area: {
				max: this.searchCriteriasAreaMax,
				min: this.searchCriteriasAreaMin,
			},
			budget: {
				max: this.searchCriteriasBudgetMax,
				min: this.searchCriteriasBudgetMin,
			},
			budget_m2: {
				max: this.searchCriteriasBudgetM2Max,
				min: this.searchCriteriasBudgetM2Min,
			},
			demand_types: this.searchCriteriasDemandTypes,
			locations: this.searchCriteriasLocations,
			natures: this.searchCriteriasNatures
		};
	}

	private get searchCriteriasAddressDisplayType(): AddressDisplayType | undefined {
		return this.demand.search_criterias?.address_display_type;
	}

	private get searchCriteriasAreaMax(): Area | undefined {
		return this.demand.search_criterias?.area?.max ? new Area(this.demand.search_criterias.area.max) : undefined;
	}

	private get searchCriteriasAreaMin(): Area | undefined {
		return this.demand.search_criterias?.area?.min ? new Area(this.demand.search_criterias.area.min) : undefined;
	}

	private get searchCriteriasBudgetMax(): Price | undefined {
		return this.demand.search_criterias?.budget?.max ? new Price(this.demand.search_criterias.budget.max, this.language) : undefined;
	}

	private get searchCriteriasBudgetMin(): Price | undefined {
		return this.demand.search_criterias?.budget?.min ? new Price(this.demand.search_criterias.budget.min, this.language) : undefined;
	}

	private get searchCriteriasBudgetM2Max(): Price | undefined {
		return this.demand.search_criterias?.budget_m2?.max ? new Price(this.demand.search_criterias.budget_m2.max, this.language) : undefined;
	}

	private get searchCriteriasBudgetM2Min(): Price | undefined {
		return this.demand.search_criterias?.budget_m2?.min ? new Price(this.demand.search_criterias.budget_m2.min, this.language) : undefined;
	}

	private get searchCriteriasDemandTypes(): Array<string> | undefined  {
		return this.demand.search_criterias?.demand_types;
	}

	private get searchCriteriasLocations(): Array<string> | undefined {
		return this.demand.search_criterias?.locations;
	}

	private get searchCriteriasNatures(): Array<string> | undefined {
		return this.demand.search_criterias?.natures;
	}

	get settings(): DemandJson['settings'] | undefined {
		return this.demand.settings;
	}

	get shared_demand_token(): string | undefined {
		return this.demand.shared_demand_token;
	}

	get sources(): Array<string> | undefined {
		return this.demand.sources;
	}

	get state(): string | undefined {
		return this.demand.state;
	}

	get offer_states(): string[] | undefined {
		return this.demand.offer_states;
	}

	get updated_infos(): UserDate | undefined {
		return {
			date: this.demand.updated_infos?.date ? new Date(this.demand.updated_infos?.date).toLocaleDateString(this.language) : undefined,
			user: this.demand.updated_infos?.user ? new User(this.demand.updated_infos?.user) : undefined
		};
	}

	get viewed_at(): string | undefined {
		return this.demand.viewed_at ? new Date(this.demand.viewed_at).toLocaleDateString(this.language) : undefined;
	}
}
