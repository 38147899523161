// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LtWRS7yQ{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;height:45rem;max-height:100%;padding:4.28571429rem 1.14285714rem 2.28571429rem 1.14285714rem;width:63.57142857rem}@media screen and (min-width: 768Px)and (orientation: landscape){.LtWRS7yQ{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.LtWRS7yQ{padding:2.85714286rem}}.LtWRS7yQ .W1sJmZQ6{text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `LtWRS7yQ`,
	"modal_content": `W1sJmZQ6`
};
export default ___CSS_LOADER_EXPORT___;
