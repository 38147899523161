// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FadJ2ECI{background-color:var(--color-background-default);box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);display:flex;height:5rem;padding-bottom:0.28571429rem;position:relative;width:100%;z-index:6}@media screen and (min-width: 768Px)and (orientation: landscape){.FadJ2ECI{height:4.57142857rem}}.FadJ2ECI:after{background-color:var(--color-background-primary);bottom:0;content:"";display:block;height:0.28571429rem;left:0;position:absolute;width:100%}.TYDy9c22{position:absolute}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `FadJ2ECI`,
	"position_absolute": `TYDy9c22`
};
export default ___CSS_LOADER_EXPORT___;
