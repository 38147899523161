import * as React from 'react';

// COMPONENTS
import BadgeCounter from '@components/badge-counter';
import Button, {
	ButtonProps,
} from '@components/button';

// STYLES
import styles from './button-with-counter.module.scss';

interface ButtonWithCounterProps extends ButtonProps  {
	badgeCounter?: number;
}

const ButtonWithCounter = ({
	badgeCounter,
	'data-testid': dataTestid,
	...otherProps
}: ButtonWithCounterProps): JSX.Element => {

	const badgeCounterElement = badgeCounter ? (
		<BadgeCounter
			className={styles.badge_counter}
			content={badgeCounter}
			data-testid={`${dataTestid}-badge-counter`}
			size={'small'}
		/>
	) : null;

	return (
		<div
			className={styles.button_with_counter}
		>
			<Button
				{...otherProps}
				data-testid={dataTestid}
			/>
			{badgeCounterElement}
		</div>
	);
};

export {
	ButtonWithCounter as default,
	ButtonWithCounterProps,
};
