export enum EnumButtonCorners {
	Default = 'default',
	Round = 'round',
	Square = 'square',
	SemiSquareRight = 'semi-square-right'
}

export enum EnumButtonCounterTheme {
	DEFAULT = 'default',
	ROUND = 'round',
}

export enum EnumButtonSize {
	SMALL = 'small',
	DEFAULT = 'default',
	BIG = 'big',
}

export enum EnumButtonType {
	BUTTON = 'button',
	SUBMIT = 'submit',
	RESET = 'reset',
}

export enum EnumSwitchView {
	TABLE = 'table',
	CARD = 'card',
}

export enum EnumToggleButton {
	DEFAULT = 'default',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',
}

