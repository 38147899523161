export interface PriceJson {
	amount: number;
	are_charges_included: boolean;
	currency: string;
	frequency?: string;
	tax_type?: string;
	unit?: string;
}
export interface PriceOrStateValue {
	state?: string;
	value?: PriceJson;
}

export class Price {
	public price: PriceJson;
	public language: string;

	constructor(
		price: PriceJson,
		language: string
	) {
		this.price = price;
		this.language = language;
	}

	get amount(): number {
		return this.price?.amount || 0;
	}

	get are_charges_included(): boolean {
		return this.price?.are_charges_included;
	}

	get currency(): string {
		return this.price?.currency || 'EUR';
	}

	get frequency(): string | undefined {
		return this.price?.frequency;
	}

	get tax_type(): string {
		return this.price?.tax_type;
	}

	get unit(): string | undefined {
		return this.price?.unit;
	}
}
