// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X1QYHOCB{height:auto;line-height:1;max-width:100%;position:relative;width:100%}.cUvQQPg9{height:100%}.KQk6D7tN{overflow:hidden}.KQk6D7tN img{height:100%;object-fit:cover}.N8_Hs883{height:2.85714286rem;width:2.85714286rem}.N8_Hs883 .HOnb6Oyt{border-radius:0.57142857rem;min-width:2.85714286rem}.N8_Hs883 .HOnb6Oyt>i{font-size:.8em}.vRhhivX_{height:3.92857143rem;width:3.92857143rem}.vRhhivX_ .HOnb6Oyt{border-radius:0.57142857rem;min-width:3.92857143rem}.vRhhivX_ .HOnb6Oyt>i{font-size:1.5em}.N8_Hs883,.vRhhivX_{align-items:center;display:flex;justify-content:center;overflow:hidden}.N8_Hs883 img,.vRhhivX_ img{object-fit:cover;width:100%}.jJC8O4mO,.pTZOX5E3{border-radius:50%;overflow:hidden}.HOnb6Oyt{align-items:center;background-color:var(--color-background-image-placeholder);display:flex;height:100%;justify-content:center;width:100%}.HOnb6Oyt>i{font-size:2em;opacity:.5}.K2JN3Bg6{border-radius:50%;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `X1QYHOCB`,
	"image__with_placeholder": `cUvQQPg9`,
	"format__fit-cover": `KQk6D7tN`,
	"format__profile": `N8_Hs883`,
	"placeholder": `HOnb6Oyt`,
	"format__thumbnail": `vRhhivX_`,
	"format__profilerounded": `jJC8O4mO`,
	"format__thumbnailrounded": `pTZOX5E3`,
	"rounded": `K2JN3Bg6`
};
export default ___CSS_LOADER_EXPORT___;
