import React, {
	memo,
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';

// COMPONENTS
import {
	ButtonProps,
} from '@components/button';
import ButtonWithCounter from '@components/button-with-counter';
import Dropdown, {
	DropdownProps,
} from '@components/dropdown';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';

// HOC
import withDropdown, {
	withDropdownprops,
} from '@hoc/withDropdown';

// STYLES
import styles from './button-with-dropdown.module.scss';

interface inlineStylesProps {
	top?: string;
	bottom?: string;
	left?: string;
	right?: string;
}

interface ButtonWithDropdownProps extends withDropdownprops {
	hasAccess?: boolean;
	dropdownTheme?: DropdownProps['theme'];
	isOpenAfterClick?: boolean;
	items?: DropdownItemProps[] | ButtonProps[];
	onClickItem?: (event: MouseEvent<HTMLElement>, itemData: DropdownItemProps) => void;
	onClickClear?: (event: MouseEvent<HTMLElement>, args?: object) => void;
	sharedEmail?: string;
	shortDropdown?: boolean;
}

const ButtonWithDropdown = memo(({
	badgeTheme,
	badgeCounter,
	classNameDropdown,
	classNameTrigger,
	hasBoxShadow = true,
	className,
	'data-testid': dataTestid,
	corners,
	counter,
	counterTheme,
	disabled,
	dropdownTheme,
	hasClear = false,
	iconLeft,
	iconRight,
	iconStyle = EnumFontStyle.LIGHT,
	isOpenAfterClick = false,
	items = [
	],
	label,
	loader,
	name,
	onClick,
	onClickClear,
	selectedIndex,
	style = {
		top: 'calc(100% + 6px)',
		left: '0'
	},
	shortDropdown = false,
	size,
	theme,
	title,
	tooltipText
}: ButtonWithDropdownProps): JSX.Element => {
	const inlineStyles: inlineStylesProps = {
	};
	const [
		dropdownIsActiveState,
		setDropdownIsActiveState
	] = useState(false);

	const cssClasses = [
		styles.button_with_dropdown
	];

	if (className) cssClasses.push(className);
	if (style?.top) inlineStyles.top = style.top;
	if (style?.right) inlineStyles.right = style.right;
	if (style?.bottom) inlineStyles.bottom = style.bottom;
	if (style?.left) inlineStyles.left = style.left;

	if (!style?.top && !style?.bottom) inlineStyles.top = '100%';
	if (!style?.left && !style?.right) inlineStyles.left = '0';

	if (shortDropdown) cssClasses.push(styles.short_dropdown);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		isOpenAfterClick ? setDropdownIsActiveState(!dropdownIsActiveState) : null;
		if (onClick) onClick(event);
	};

	const handleOnClickOutside = () => {
		setDropdownIsActiveState(false);
	};

	const dropdownElement = (
		<Dropdown
			data-testid={`${dataTestid}-list`}
			hasClear={hasClear}
			items={items}
			selectedIndex={selectedIndex}
			theme={dropdownTheme}
			title={title}
			onClickClear={onClickClear}
		/>
	);
	const ButtonWithDropdown = withDropdown(ButtonWithCounter, dropdownElement);
	return (
		<ButtonWithDropdown
			badgeCounter={badgeCounter}
			badgeTheme={badgeTheme}
			className={cssClasses.join(' ')}
			classNameDropdown={classNameDropdown}
			classNameTrigger={classNameTrigger}
			corners={corners}
			counter={counter}
			counterTheme={counterTheme}
			data-testid={dataTestid}
			disabled={disabled}
			dropdownIsActive={dropdownIsActiveState}
			hasBoxShadow={hasBoxShadow}
			iconLeft={iconLeft}
			iconRight={iconRight}
			iconStyle={iconStyle}
			label={label}
			loader={loader}
			name={name}
			size={size}
			style={inlineStyles}
			theme={theme}
			tooltipText={tooltipText}
			onClick={handleOnClick}
			onClickOutside={handleOnClickOutside}
		/>
	);
});

export {
	ButtonWithDropdown as default,
	ButtonWithDropdownProps,
};
