export enum EnumInputType {
	BUTTON         = 'button',
    CHECKBOX       = 'checkbox',
    DATETIME_LOCAL = 'datetime-local',
    EMAIL          = 'email',
    HIDDEN         = 'hidden',
    NUMBER         = 'number',
    PASSWORD       = 'password',
    RADIO          = 'radio',
    TEL            = 'tel',
    TEXT           = 'text',
    RESET          = 'reset',
	SELECT         = 'select',
    SUBMIT         = 'submit',
    TEXTAREA       = 'textarea',
    TIME           = 'time',
}

export enum EnumInputPosition {
	LEFT = 'left',
	RIGHT = 'right',
}

export enum EnumInputTheme {
    CHECKBOX = 'checkbox',
    TOGGLE = 'toggle',
}

export enum EnumInputIconPosition {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum EnumInputSize {
	SMALL = 'small',
	DEFAULT = 'default',
	BIG = 'big',
}

export enum EnumFormMode {
    ON_SUBMIT = 'onSubmit',
    ON_BLUR = 'onBlur',
    ON_CHANGE = 'onChange',
}

