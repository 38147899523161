import React from 'react';
import ContentLoader from 'react-content-loader';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import globalStyles from '@styles/styles.module.scss';
import styles from './PageOfferDetails.module.scss';

const Loader = (props: object) => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));

	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			className={styles.loader}
			height={device.display === 'mobile' ? 620 : 425}
			width="100%"
		>
			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={25}
				id="0"
				rx={4}
				ry={4}
				width="65%"
				x={0}
				y={0}
			/>
			<rect
				height={device.display === 'mobile' ? 250 : 336}
				id="1"
				rx={device.display === 'mobile' ? 0 : 4}
				ry={device.display === 'mobile' ? 0 : 4}
				width={device.display === 'mobile' ? '100%' : '65%'}
				x={0}
				y={device.display === 'mobile' ? 0 : 22 + 20}
			/>
			<rect
				display={device.display === 'mobile' ? 'true' : 'none'}
				height={50}
				id="0"
				rx={4}
				ry={4}
				width="70%"
				x='5%'
				y={250 + 20}
			/>
			<rect
				height={265}
				id="2"
				rx={4}
				ry={4}
				width={device.display === 'mobile' ? '90%' : '33%'}
				x={device.display === 'mobile' ? '5%' : '67%'}
				y={device.display === 'mobile' ? 250 + 20 + 50 + 20 : 22 + 20}
			/>
		</ContentLoader>
	);
};

export default Loader;
