import * as React from 'react';
import {
	MouseEvent,
	ReactNode,
} from 'react';
import {
	NavLink,
} from 'react-router-dom';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import Icon from '@components/icon';

// STYLES
import styles from './link-nav.module.scss';

interface LinkNavProps {
	hasAccess?: boolean;
	children?: ReactNode;
	className?: string;
	classNameActive?: string;
	'data-testid'?: string;
	disabled?: boolean;
	fontWeight?: EnumFontStyle;
	href?: string;
	iconLeft?: string;
	iconRight?: string;
	iconStyle?: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
	id?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	target?: EnumTargetLink;
	theme?: EnumThemeLink;
}

const LinkNav = ({
	hasAccess = true,
	children,
	className,
	classNameActive,
	'data-testid': dataTestid,
	disabled = false,
	fontWeight = EnumFontStyle.SEMIBOLD,
	href,
	iconLeft,
	iconRight,
	iconStyle = EnumFontStyle.REGULAR,
	id,
	onClick,
	target,
	theme = EnumThemeLink.PRIMARY,
}: LinkNavProps): JSX.Element => {

	const classes = [
		styles.link_nav,
	];

	if (className) classes.push(className);

	classes.push(styles[`link__${theme}`]);
	classes.push(styles[`link__${fontWeight}`]);
	if (disabled) classes.push(styles[`link__${'disabled'}`]);

	let icon = null;
	if (iconLeft) {
		icon = (
			<Icon
				fontStyle={iconStyle}
				name={iconLeft}
			/>
		);
		classes.push(styles['icon__left']);
	}

	if (iconRight) {
		icon = (
			<Icon
				fontStyle={iconStyle}
				name={iconRight}
			/>
		);
		classes.push(styles['icon__right']);
	}

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (hasAccess) {
			if (href && target === EnumTargetLink.BLANK) {
				event.preventDefault();
				window.open(href);
			}
		} else {
			event.preventDefault();
		}
		onClick ? onClick(event) : null;
	};

	return (
		<NavLink
			className={(navData) => {
				const currentClassNameActive = classNameActive;
				if (navData?.isActive) classes.push(currentClassNameActive);
				return classes.join(' ');
			}}
			data-testid={dataTestid}
			id={id}
			target={target}
			to={href}
			onClick={disabled ? undefined : handleOnClick}
		>
			{iconLeft ? icon : null}
			{children ? <span className={styles.children}>{children}</span> : null}
			{iconRight ? icon : null}
		</NavLink>
	);

};

export {
	LinkNav as default,
	LinkNavProps,
};
