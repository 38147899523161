export enum EnumTheme {
	DEFAULT = 'default',
	ELEVATION = 'elevation',
	NAKED = 'naked',
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',
}

export enum EnumStatusTheme {
	ABANDONED = 'abandoned',
	ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
	UPDATE = 'update',
	WAIT = 'wait',
	WARNING = 'warning',
}

export type EnumThemeLabel = EnumStatusTheme | Exclude<EnumTheme, EnumTheme.NAKED>;
export type EnumThemeButton = EnumStatusTheme | Exclude<EnumTheme, EnumTheme.ELEVATION>;
export type EnumThemeAccordion = EnumStatusTheme | EnumTheme.DEFAULT;
