import TagManager from 'react-gtm-module';
import {
	initializedLogger,
} from '@modules/logger';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

interface eventEmitterProps {
	_events: {
		[key: string]: (() => void)[];
	};
	subscribe: (event: string, callback: () => void) => void;
	unsubscribe: (event: string) => void;
	dispatch: (event: string, ...args: string[] | object[]) => void;
}

const eventEmitter = {
	_events: {
	},

	dispatch(event, ...args) {
		if (this._events[event]) {
			this._events[event].forEach((callback: (...args: string[]) => void) => callback(...args as string[]));

			const data = {
				dataLayer: {
					event: event,
					...args
				}
			};
			TagManager.dataLayer(data);
		} else {
			// ONLY FOR DEBUG PURPOSE
			/* istanbul ignore next */
			if (APP_CONF_VARS.debug.eventEmitter.logs) {
				initializedLogger.log(`eventEmitter - event does not exists : ${event}`);
			}
		}
	},

	subscribe(event, callback) {
		if (!this._events[event]) this._events[event] = [
		];
		if (!callback) {
			// ONLY FOR DEBUG PURPOSE
			/* istanbul ignore next */
			callback = () => {
				if (APP_CONF_VARS.debug.eventEmitter.logs) {
					initializedLogger.log(`EVENT EMITTER - event dispatched "${event}"`);
				}
			};
		}
		this._events[event].push(callback);
	},

	unsubscribe(event) {
		delete this._events[event];
	},
} as eventEmitterProps;

export default eventEmitter;
