const formUtils = {
	rulesByType: {
		default: /^(?!\s*$).+/,
		email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		password_format: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,})$/,
		number: /^[0-9]*$/,
	},

	isInputValid(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | Array<string>) {
		let valid = true;
		if ((element as HTMLInputElement).dataset.checkValue?.length) {
			const checkInput = document.getElementById(`${(element as HTMLInputElement).dataset.checkValue}`);
			valid = (element as HTMLInputElement).value === (checkInput as HTMLInputElement).value;
		} else if ((element as HTMLInputElement).autocomplete === 'new-password') {
			const rule = (element as HTMLInputElement).pattern || this.rulesByType['password_format'];
			const pattern = new RegExp(rule);
			valid = pattern.test((element as HTMLInputElement).value);
		} else {
			const rule = (element as HTMLInputElement).pattern || this.rulesByType[(element as HTMLInputElement).type] || this.rulesByType.default;
			const pattern = new RegExp(rule);
			valid = pattern.test((element as HTMLInputElement).value);
		}
		return valid;
	},
};

export default formUtils;
