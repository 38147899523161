import * as Bowser from 'bowser';

import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

interface bowserProps {
	getParser?: () => void;
	getOS?: () => void;
	getBrowser?: () => {
		name?: string;
		version?: string;
	};
	parsedResult?: {
		browser?: {
			name?: string;
		};
	};
	satisfies?: (checkTree: object) => boolean;
}

class Device {
	breakpoints: {
		[key: string]: number;
	};
	bowser: bowserProps;
	orientation: string;
	initialScreenHeight: number;
	initialScreenWidth: number;
	screenHeight: number;
	screenWidth: number;
	type: string;
	display: string;
	os: bowserProps['getOS'] | {
		name?: string;
	};
	browser: () => void;

	constructor() {
		this.breakpoints = APP_CONF_VARS.breakpoints;
		this.bowser = Bowser.getParser(window.navigator.userAgent);
		this.orientation = window.screen.orientation?.type || 'landscape-primary';
		this.onResize = this.onResize.bind(this);

		this.init();
	}

	init() {
		this.initialScreenHeight = window.innerHeight;
		this.initialScreenWidth = window.innerWidth;
		this.initDevice();
	}

	initDevice() {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
		this.orientation = this.getScreenOrientation();
		this.type = this.getType();
		this.display = this.getDisplay();
		this.os = this.bowser.getOS() as unknown;
		this.browser = this.bowser.getBrowser() as () => void;
	}

	getBreakpointWidth(breakPointName: string) {
		return this.breakpoints[breakPointName];
	}

	getBreakpointNameFromWidth(width: number) {
		let breakPointName = '';
		Object.keys(this.breakpoints).forEach((key) => {
			if (!breakPointName && width <= this.breakpoints[key]) {
				breakPointName = key;
			}
		});
		return breakPointName;
	}

	getDisplay() {
		let deviceDisplay;
		if (this.isDisplayMobile()) {
			deviceDisplay = 'mobile';
		} else if (this.isDisplayTablet()) {
			deviceDisplay = 'tablet';
		} else {
			deviceDisplay = 'desktop';
		}
		return deviceDisplay;
	}

	getKeyName() {
		const screenWidth = this.orientation === 'landscape-primary' ? this.screenHeight : this.screenWidth;
		let breakPointKeyName = '';
		for (const key of Object.keys(this.breakpoints)) {
			breakPointKeyName = screenWidth >= this.breakpoints[key] ? key : breakPointKeyName;
		}
		return breakPointKeyName;
	}

	getScreenOrientation() {
		this.initialScreenHeight = window.screen.availHeight;
		this.initialScreenWidth = window.screen.availWidth;
		return window.screen.orientation?.type || 'landscape-primary';
	}

	getType() {
		let deviceType;
		if (this.isMobileType()) {
			deviceType = 'mobile';
		} else if (this.isTabletType()) {
			deviceType = 'tablet';
		} else {
			deviceType = 'desktop';
		}
		return deviceType;
	}

	isChrome() {
		return this.browser.name.toLowerCase() === 'chrome';
	}

	isIE11() {
		const browserInfos = this.bowser.getBrowser();
		return browserInfos.name === 'Internet Explorer' && Number(browserInfos.version) <= 11;
	}

	isIos() {
		const osName = this.os.name;
		return osName.toLowerCase() === 'ios';
	}

	isMicrosoftBrowser() {
		const userBrowserIsEdge = this.bowser.parsedResult.browser.name === 'Microsoft Edge';
		const userBrowserIsIE = this.bowser.parsedResult.browser.name === 'Internet Explorer';
		return (userBrowserIsEdge || userBrowserIsIE);
	}

	isMobileType() {
		return this.isDisplayMobile() && this.isTouch();
	}

	isDisplayMobile() {
		return this.screenWidth <= this.breakpoints.md;
	}

	isDisplayTablet() {
		return this.screenWidth > this.breakpoints.md && this.screenWidth <= this.breakpoints.lg;
	}

	isSafari() {
		return this.browser.name.toLowerCase() === 'safari';
	}

	isTabletType() {
		return this.isDisplayTablet() && this.isTouch();
	}

	isTouch() {
		return (('ontouchstart' in window) ||
		(navigator.maxTouchPoints > 0));
	}

	isValidBrowser() {
		return this.bowser.satisfies({
			'internet explorer': '>=11',
			firefox: '>31',
			opera: '>=22',
			chrome: '>20',
			safari: '>10.1',
		});
	}

	onResize() {
		this.initDevice();
	}

	mustUseNativeUi() {
		return this.isTouch() && (this.isTabletType() || this.isMobileType());
	}

	refresh() {
		this.initDevice();
	}
}
export default Device;
