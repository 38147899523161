import {
	createSlice,
} from '@reduxjs/toolkit';
import {
	DemandJsonPrivate,
	DemandJsonPublic,
	OfferJson,
	CollectionJson,
} from '@@types/index';
import {
	EnumSwitchView,
} from '@enums/button.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	Feature,
} from 'geojson';

export interface StoreDemands {
	instance: CollectionJson<DemandJsonPrivate | DemandJsonPublic>;
}

// Define the initial state using that type
const initialState: StoreDemands = {
	// État initial de notre reducer qui stock les formulaires
	instance: {
		collection: [
		],
		count: 0,
	}
};

export const demandsSlice = createSlice({
	name: 'demands',
	initialState,
	reducers: {
		clearDemands: (state) => {
			state.instance = {
				collection: [
				],
				count: 0,
			};
		},
		demandsDelete: (state, action) => {
			const demands = state.instance.collection.filter((demand: DemandJsonPublic | DemandJsonPrivate) => !action.payload.demand_ids.includes(demand.id));
			state.instance.collection = [
				...demands
			];
			state.instance.count = demands.length;
		},
		demandsSetList: (state, action) => {
			state.instance.collection = [
				...action.payload.collection
			];
			state.instance.count = action.payload.collection.length;
		},
		demandUpdate: (state, action) => {
			const findDemand = state.instance.collection.filter((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return demand as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return demand as DemandJsonPublic;
				}
			});
			if (findDemand.length) {
				const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
					if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
						return action.payload.demand as DemandJsonPrivate;
					} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
						return action.payload.demand as DemandJsonPublic;
					} else {
						return demand;
					}
				});
				state.instance.collection = demands;
			} else {
				state.instance.count = state.instance.count + 1;
				state.instance.collection = [
					action.payload.demand,
					...state.instance.collection,
				];
			}
		},

		demandUpdateOfferState: (state, action) => {

			const demandsToUpdate = action.payload.demands;
			const offerStatesToUpdate = action.payload.offer_state;

			const demands = state.instance.collection.map((demand) => {
				if (demandsToUpdate.includes(demand.id)) {
					return {
						...demand,
						offer_states: offerStatesToUpdate
					};
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},

		demandUpdateViewedAt: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						viewed_at: action.payload.viewed_at
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						viewed_at: action.payload.viewed_at
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandSwitchViewMode: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						settings: {
							...demand.settings,
							display: {
								...demand.settings?.display,
								view_mode: demand.settings?.display?.view_mode === EnumSwitchView.CARD ? EnumSwitchView.TABLE : EnumSwitchView.CARD
							}
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						settings: {
							...demand.settings,
							display: {
								...demand.settings?.display,
								view_mode: demand.settings?.display?.view_mode === EnumSwitchView.CARD ? EnumSwitchView.TABLE : EnumSwitchView.CARD
							}
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOffersUpdate: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					const offers = action.payload.offers.collection.length ? action.payload.offers.collection.map((offer: OfferJson) => {
						const geoJson = demand?.geo_json?.features?.length ? demand.geo_json.features.filter((feat: Feature) => feat.properties.offer_id === offer.id) : undefined;
						const states = offer && offer?.states && offer?.states?.length > 0 ? [
							...offer.states
						] : [
						];

						return {
							...offer,
							states: states.sort(),
							building: {
								...offer.building,
								geo_json: geoJson?.length ? geoJson[0] : undefined
							},
						} as OfferJson;
					}) : [
					];

					return {
						...demand,
						offers: {
							...demand.offers,
							collection: offers
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					const offers = action.payload.offers.collection.length ? action.payload.offers.collection.map((offer: OfferJson) => {
						const geoJson = demand?.geo_json?.features?.length ? demand.geo_json.features.filter((feat: Feature) => feat.properties.offer_id === offer.id) : undefined;

						const states = offer && offer?.states && offer?.states?.length > 0 ? [
							...offer.states
						] : [
						];

						return {
							...offer,
							states: states.sort(),
							building: {
								...offer.building,
								geo_json: geoJson?.length ? geoJson[0] : undefined
							},
						} as OfferJson;
					}) : [
					];

					return {
						...demand,
						offers: {
							...demand.offers,
							collection: offers
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandGeoJsonUpdate: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						geo_json: action.payload.geo_json
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						geo_json: action.payload.geo_json
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOffersRemove: (state, action) => {
			const demandOffersIdsToRemove = action.payload?.offer_ids?.length ? action.payload.offer_ids : [
			];
			const currentCommercializationStep = action.payload.current_commercialization_step;

			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					const demandTargetedOffersFiltered = demand?.offers?.collection?.length ? demand.offers.collection.filter((item: OfferJson) => {
						return !demandOffersIdsToRemove.includes(item.id) ? item : null;
					}) : [
					];

					return {
						...demand,
						counters: {
							...demand.counters,
							steps: {
								...demand.counters.steps,
								[`${action.payload.new_commercialization_step}`]: (demand.counters.steps[`${action.payload.new_commercialization_step as EnumSteps}`] + demandOffersIdsToRemove.length) as number,
								[`${currentCommercializationStep}`]: (demand.counters.steps[`${currentCommercializationStep as EnumSteps}`] - demandOffersIdsToRemove.length) as number
							}
						},
						offers: {
							...demand.offers,
							collection: [
								...demandTargetedOffersFiltered,
							]
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					const demandTargetedOffersFiltered = demand?.offers?.collection?.length ? demand.offers.collection.filter((item: OfferJson) => {
						return !demandOffersIdsToRemove.includes(item.id) ? item : null;
					}) : [
					];

					return {
						...demand,
						counters: {
							...demand.counters,
							steps: {
								...demand.counters.steps,
								[`${action.payload.new_commercialization_step}`]: (demand.counters.steps[`${action.payload.new_commercialization_step as EnumSteps}`] + demandOffersIdsToRemove.length) as number,
								[`${currentCommercializationStep}`]: (demand.counters.steps[`${currentCommercializationStep as EnumSteps}`] - demandOffersIdsToRemove.length) as number
							}
						},
						offers: {
							...demand.offers,
							collection: [
								...demandTargetedOffersFiltered,
							]
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOfferAddDetail: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									const geoJson = demand?.geo_json?.features?.length ? demand.geo_json.features.filter((feat: Feature) => feat.properties.offer_id === offer.id) : undefined;

									const states = action.payload.offer?.states && action.payload.offer?.states?.length > 0 ? [
										...action.payload.offer.states
									] : [
									];

									return {
										...action.payload.offer,
										states: states.sort(),
										building: {
											...action.payload.offer.building,
											geo_json: geoJson?.length ? geoJson[0] : undefined
										},
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									const geoJson = demand?.geo_json?.features?.length ? demand.geo_json.features.filter((feat: Feature) => feat.properties.offer_id === offer.id) : undefined;

									const states = action.payload.offer?.states && action.payload.offer?.states?.length > 0 ? [
										...action.payload.offer.states
									] : [
									];

									return {
										...action.payload.offer,
										states: states.sort(),
										building: {
											...action.payload.offer.building,
											geo_json: geoJson?.length ? geoJson[0] : undefined
										},
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOfferAddViewed: (state, action) => {
			const hiddenLabel = [
				'updated',
				'new',
				'viewed',
			];

			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									const states = offer && offer?.states && offer?.states?.length	? [
										'viewed',
										...offer.states.filter(state => !hiddenLabel.includes(state))
									] : [
										'viewed'
									];
									return {
										...offer,
										states: states.sort()
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {

									const states = offer && offer?.states && offer?.states?.length	? [
										'viewed',
										...offer.states.filter(state => !hiddenLabel.includes(state))
									] : [
										'viewed'
									];

									return {
										...offer,
										states: states.sort()
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOfferAddHistory: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									return {
										...offer,
										event_history: action.payload.event_history
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									return {
										...offer,
										event_history: action.payload.event_history
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOfferAddVisit: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									const states = action.payload.states && action.payload.states.length > 0 ? [
										...action.payload.states
									] : [
									];

									return {
										...offer,
										states: states.sort(),
										visit: action.payload.visit,
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									const states = action.payload.states && action.payload.states.length > 0 ? [
										...action.payload.offer.states
									] : [
									];

									return {
										...offer,
										states: states.sort(),
										visit: action.payload.visit,
									} as OfferJson;
								} else {
									return offer;
								}
							})
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOfferAddContacts: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									return {
										...offer,
										contacts: action.payload.contacts
									} as OfferJson;
								} else {
									return offer;
								}

							})
						}
					} as DemandJsonPrivate;
				} else if (demand.shared_demand_token && action.payload.shared_demand_token && demand.shared_demand_token === action.payload.shared_demand_token) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									return {
										...offer,
										contacts: action.payload.contacts
									} as OfferJson;
								} else {
									return offer;
								}

							})
						}
					} as DemandJsonPublic;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
		demandOfferAddLots: (state, action) => {
			const demands = state.instance.collection.map((demand: DemandJsonPublic | DemandJsonPrivate) => {
				if (demand.id && action.payload.demand_id && demand.id === action.payload.demand_id) {
					return {
						...demand,
						offers: {
							...demand.offers,
							collection: demand.offers.collection.map((offer: OfferJson) => {
								if (offer.id === action.payload.offer_id) {
									return {
										...offer,
										lots: action.payload.lots
									} as OfferJson;
								} else {
									return offer;
								}

							})
						}
					} as DemandJsonPrivate;
				} else {
					return demand;
				}
			});

			state.instance.collection = demands;
		},
	}
});

export const {
	clearDemands,
	demandsDelete,
	demandGeoJsonUpdate,
	demandOffersRemove,
	demandOffersUpdate,
	demandOfferAddContacts,
	demandOfferAddLots,
	demandOfferAddDetail,
	demandOfferAddHistory,
	demandOfferAddViewed,
	demandOfferAddVisit,
	demandsSetList,
	demandUpdate,
	demandUpdateOfferState,
	demandUpdateViewedAt,
	demandSwitchViewMode,
} = demandsSlice.actions;

export default demandsSlice.reducer;
