import * as React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	EnumImageFormatQuery,
} from '@appConf/vars.conf';

//COMPONENTS
import Slider, {
	SliderProps,
} from '@components/slider';

// MODULES
import {
	updateImageUrls,
} from '@modules/images';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';

// TYPES
import {
	Image,
} from '@@types/Image';

//STYLES
import styles from './ModalSlider.module.scss';
import {
	EnumImageFormat,
} from '@enums/image.enum';

function ModalSlider({ items }: SliderProps) {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);
	return (
		<div className={`${styles.slider}`}>
			<Slider
				className={styles.modal_content}
				counter={true}
				displayTitle={true}
				imagesFormat={EnumImageFormat.ORIGINAL}
				infinite={true}
				items={items?.length ? updateImageUrls(items, isDesktopResolution ? EnumImageFormatQuery.SLIDER_MODAL : EnumImageFormatQuery.SLIDER_MODAL_MOBILE) as Image[] : null}
				pagination={false}
			/>
		</div>
	);
}

export default ModalSlider;
