import * as React from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
	EnumThemeLabel,
} from '@enums/theme.enum';

// DATA
import LabelStatusThemesConfs from '@static_data/label-status.data';

// COMPONENTS
import Icon from '@components/icon';

// STYLES
import styles from './label-status.module.scss';

interface LabelStatusThemesConfType {
	relatedText?: string;
	icon?: string;
	theme: string;
}

interface LabelStatusProps {
	className?: string;
	context?: string;
	'data-testid'?: string;
	hasBackground?: boolean;
	iconRight?: string;
	text?: string;
	theme?: EnumThemeLabel;
}

function LabelStatus({
	theme = EnumTheme.DEFAULT,
	className,
	context,
	hasBackground = true,
	iconRight,
	text,
	...otherProps
}: LabelStatusProps) {

	const { i18n, t } = useTranslation();
	const formatedText = text?.toString().toLowerCase();

	const confDefault: LabelStatusThemesConfType = {
		relatedText: undefined,
		icon: undefined,
		theme: EnumTheme.DEFAULT,
	};

	// checks if the label name is associated with a configuration
	const conf = LabelStatusThemesConfs.filter(conf => conf.relatedText === formatedText);
	const confExists = conf.length;
	const actualConf = confExists ? conf[0] : confDefault;
	// checks if there is an icon defined in a label's configuration or if the iconRight prop is defined
	const iconName = confExists && actualConf.icon ? actualConf.icon : (iconRight || null);

	const classes = [
		styles.label_status,
	];
	if (className) {
		classes.push(className);
	}

	if (confExists && actualConf.theme) {
		classes.push(styles[`theme__${actualConf.theme}`]);
	} else {
		classes.push(styles[`theme__${theme}`]);
	}

	let icon = null;
	if (iconName) {
		icon = (
			<Icon
				data-testid={`${otherProps['data-testid']}-icon`}
				fontStyle={EnumFontStyle.SOLID}
				name={iconName}
			/>
		);
		classes.push(styles.icon__right);
	}
	if (hasBackground) classes.push(styles.background);

	const foundText = i18n.exists(`status.${formatedText}`) ? t(`status.${formatedText}`, {
		context: context
	}) : text;
	const labelText = t('format.capitalize', {
		text: foundText
	});

	return (
		<label
			className={classes.join(' ')}
			data-testid={otherProps['data-testid']}
		>
			{labelText}
			{icon}
		</label>
	);
}

export {
	LabelStatus as default,
	LabelStatusThemesConfType,
	LabelStatusProps,
};
