export interface FieldValueJson {
	label?: string;
	text?: string;
	values?: Array<string | FieldValueJson>;
}

export class FieldValue {
	public fieldvalue?: FieldValueJson;

	constructor(
		fieldvalue: FieldValueJson
	) {
		this.fieldvalue = fieldvalue;
	}

	get values(): Array<string | FieldValueJson> | undefined {
		return this.fieldvalue?.values ? this.fieldvalue.values : undefined;
	}

	get text(): string | undefined {
		return this.fieldvalue?.text ? this.fieldvalue.text : undefined;
	}

	get label(): string | undefined {
		return this.fieldvalue?.label ? this.fieldvalue.label : undefined;
	}
}
