import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
export interface PATHSProps {
	// eslint-disable-next-line
	[key: string]: string | any;
}

const PATHS: PATHSProps = {
	_ROOT: '/',
	CLEAR: '/clear',
	DEMANDS: {
		_ROOT: '/demands',
		LEGACY: `${APP_CONF_VARS.sfLegacyPath}/demandSearch/searchMetaDemande`,
		PRIVATE: {
			_ROOT: '/demands/:demandid',
			MANAGE: {
				_ROOT: '/demands/:demandid/manage/',
				STEP: {
					_ROOT: '/demands/:demandid/manage/:step',
					OFFER: {
						_ROOT: '/demands/:demandid/manage/:step/offer/:offerid',
					},
				}
			},
			REPORT: '/demands/:demandid/report',
		},
		PUBLIC: {
			_ROOT: '/:segmenturl/:token',
			STEP: {
				_ROOT: '/:segmenturl/:token/:step',
				OFFER: {
					_ROOT: '/:segmenturl/:token/:step/offer/:offerid',
				},
			}
		}
	},
	ERROR: {
		_ROOT: '/error/:code',
		UNIQUE_CONNECTION_ERROR: '/error/unique_connection_error',
		CONNECTION_LIMIT_EXCEEDED: '/error/connection_limit_exceeded'
	},
	HOMEPAGE: '/home',
	LOGIN: {
		_ROOT: '/login',
		SUCCESS_STEP_2FA_SMS: '/login/auth-sms',
		PASSWORD_EMAIL_SENT: '/login/password/email-sent',
		PASSWORD_FORGOT: '/login/password/forgot',
		PASSWORD_EDIT: '/login/password/edit',
		PASSWORD_SUCCESS: '/login/password/success',
		ERROR_AUTH_IP: '/login/unknown-ip-address'
	},
	LOGOUT: '/logout',
	OBSERVATORY: {
		REPORT: '/reports/:reportid',
	},
	OFFERS: {
		EXPORT_PDF: '/offers/:offerid/export/documents/edit',
		EDIT: {
			_ROOT: '/offers/:offerid/edit',
			CONTACTS: '/offers/:offerid/edit/contacts',
			INFOS: '/offers/:offerid/edit/infos',
			LOCATION: '/offers/:offerid/edit/location',
			LOTS: '/offers/:offerid/edit/lots',
			MEDIA: '/offers/:offerid/edit/media/:mediatype',
			SERVICES: '/offers/:offerid/edit/services',
			SUMMARY: '/offers/:offerid/edit/summary',
			TERMS: '/offers/:offerid/edit/terms',
		},
		MATCHING_DEMANDS: '/offers/:offerid/demands/:step',
		NEW: '/offers/new',
		SHEET: {
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/offer/detail/id/:offerid`
		}
	},
	SEARCH: {
		_ROOT: '/search/:search_engine_name',
		ANALYZE: {
			_ROOT: '/search/analyzes',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/marketResearch/search`
		},
		ASSET_ADD: {
			_ROOT: '/search/assets/add',
			LEGACY: `${APP_CONF_VARS.sfPath}/Actifs/add`
		},
		ASSET_LIST: {
			_ROOT: '/search/assets',
			LEGACY: `${APP_CONF_VARS.sfPath}/Actifs`
		},
		BUILDING: {
			_ROOT: '/search/buildings',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/bien/recherche`
		},
		DEMAND_ADD: {
			_ROOT: '/demands/add',
			LEGACY: `${APP_CONF_VARS.sfPath}/Demands/entry`
		},
		MY_DEMAND: {
			_ROOT: '/demands',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/demandSearch/searchMetaDemande`
		},
		LAND: {
			_ROOT: '/search/lands',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/landSearch/search`
		},
		LEASE_ADD: {
			_ROOT: '/search/leases/add',
			LEGACY: `${APP_CONF_VARS.sfPath}/Actifs/Implantations/add`
		},
		LEASE_LIST: {
			_ROOT: '/search/leases',
			LEGACY: `${APP_CONF_VARS.sfPath}/Actifs/Implantations`
		},
		OCCUPIER: {
			_ROOT: '/search/occupiers',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/occupier/search`
		},
		OFFER: {
			_ROOT: '/search/offers',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/offerSearch/search`
		},
		OFFER_ADD: {
			_ROOT: '/search/offers/add',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/saisieAnnounce/search`
		},
		MY_OFFER: {
			_ROOT: '/search/offers/list',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/myOffer/search`
		},
		OWNER: {
			_ROOT: '/search/owners',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/owner/search`
		},
		PERMIT: {
			_ROOT: '/search/permits',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/buildingPermit/search`
		},
		PROJECT: {
			_ROOT: '/search/projects',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/realEstateProject/search`
		},
		SITE_ADD: {
			_ROOT: '/search/sites/add',
			LEGACY: `${APP_CONF_VARS.sfPath}/Implantations/add`
		},
		SITE_LIST: {
			_ROOT: '/search/sites',
			LEGACY: `${APP_CONF_VARS.sfPath}/Implantations`
		},
		TRANSACTION: {
			_ROOT: '/search/transactions',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/transaction/search`
		},
	},
	TESTS: {
		_ROOT: '/tests',
		IFRAME: '/tests/iframe',
		API: '/tests/api'
	},
	TRAINING_WORKSHOP: {
		_ROOT: '/training-workshop',
		LEGACY: 'https://go.hbs-research.com/LPCalendrierAteliersHBS_Saison2.html'
	},
	USER: {
		PROFILE: {
			_ROOT: '/user/profile',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/profile`
		},
		HISTORY: {
			_ROOT: '/user/history',
			LEGACY: `${APP_CONF_VARS.sfLegacyPath}/history/list`
		},
		LISTS: {
			DYNAMICS: {
				_ROOT: '/user/lists/dynamics',
				LEGACY: `${APP_CONF_VARS.sfLegacyPath}/my_alerts`
			},
			STATICS: {
				_ROOT: '/user/lists/statics',
				LEGACY: `${APP_CONF_VARS.sfLegacyPath}/itemLists`
			}
		},
		TERMS: {
			_ROOT: '/user/terms',
			LEGACY: 'https://www.laplacedelimmobilier-pro.com/LPdI/uploads/legalTerms/CGV_LPDI_2021.pdf'
		},
	},
	ZENDESK: {
		HELP_CENTER: {
			_ROOT: '/zendesk/help-center',
			LEGACY: 'https://hbs-researchhelp.zendesk.com/hc/fr'
		}
	},
};
export default PATHS;
