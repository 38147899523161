import * as React from 'react';

import {
	MouseEvent,
} from 'react';

// MODULES
import {
	Offer,
} from '@@types/index';

// STYLING
import styles from './cartography-popup.module.scss';

// COMPONENTS
import CartographyPopupLayoutOffer from '@components/cartography-popup/cartography-popup-layout-offer';

interface CartographyPopupProps {
	className?: string;
	'data-testid'?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	properties?: Offer;
}

function CartographyPopup({
	'data-testid': dataTestid,
	properties,
	onClick,
}: CartographyPopupProps) {
	let content = null;
	switch (true) {
		case properties instanceof Offer: {
			content = (
				<CartographyPopupLayoutOffer
					data-testid={`${dataTestid}-layout`}
					properties={properties}
				/>
			);
			break;
		}
		default:
			content = (
				<div>No type defined</div>
			);
	}

	const cssClassNames = [
		styles.CartographyPopup,
	];

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		onClick(event);
	};

	return (
		<div
			className={cssClassNames.join(' ')}
			data-testid={dataTestid}
			onClick={handleOnClick}
		>
			{content}
		</div>
	);
}

export {
	CartographyPopup as default,
	CartographyPopupProps,
};
