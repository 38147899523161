// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnrVo8af{align-items:center;display:flex}.ot4qm8Bd{font-size:1.42857143rem;margin-right:0.71428571rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feature": `UnrVo8af`,
	"icon": `ot4qm8Bd`
};
export default ___CSS_LOADER_EXPORT___;
