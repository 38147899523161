import * as React from 'react';
import {
	createElement,
} from 'react';
import {
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	default as ReactSlider,
} from 'react-slick';

// TYPES
import {
	Image as ImageType,
} from '@@types/index';

// ENUMS
import {
	EnumImageFormat,
} from '@enums/image.enum';

// COMPONENTS
import Icon from '@components/icon';
import Image, {
	ImageProps,
} from '@components/image';
import LabelStatus, {
	LabelStatusProps,
} from '@components/label-status';

// STYLES
import styles from './slider.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
interface NavigationArrowProps {
	className?: string;
	dataTestid?: string;
	style?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	iconName?: string;
}

interface SliderProps {
	autoplay?: boolean;
	autoplaySpeed?: number;
	className?: string;
	counter?: boolean;
	customPlaceholder?: {
		image_url?: string;
	};
	'data-testid'?: string;
	displayTitle?: boolean;
	imagesFormat?: ImageProps['format'];
	infinite?: boolean;
	items?: ImageType[];
	labelsTopLeft?: LabelStatusProps[];
	labelsTopRight?: LabelStatusProps[];
	navigation?: boolean;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	onClickArrow?: (event: MouseEvent<HTMLElement>) => void;
	pagination?: boolean;
	size?: string;
}

function Slider({
	autoplay = false,
	autoplaySpeed = 5000,
	className,
	counter = false,
	customPlaceholder,
	'data-testid': dataTestid,
	displayTitle = false,
	imagesFormat = EnumImageFormat.FIT_COVER,
	infinite = false,
	items = [
	],
	labelsTopLeft,
	labelsTopRight,
	onClick,
	onClickArrow,
	pagination = true,
	size,
}: SliderProps) {
	const { t } = useTranslation();
	const sliderRef = useRef();
	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		if (onClick) onClick(event);
	};

	const [
		currentIndexState,
		setCurrentIndexState
	] = useState(0);

	const getLabelsElements = (labels: LabelStatusProps[]) => {
		const labelsElements = labels.map((label: LabelStatusProps, index: number) => {
			return (
				<li
					key={index}
				>
					<LabelStatus
						context={label.context}
						data-testid={`${dataTestid}-label`}
						text={label.text}
					/>
				</li>
			);
		});

		const labelsElementsList = (
			<ul
				data-testid={`${dataTestid}-labels_list`}
			>
				{labelsElements}
			</ul>
		);

		return labelsElementsList;
	};

	// Placeholder for empty items
	const placeholderItemClassNames = [
		styles.placeholder,
		styles.slide__image
	];

	const sliderClassNames = [
		styles.slide,
	];

	let imagePlaceholder = (
		<Image
			className={placeholderItemClassNames.join(' ')}
			data-testid={`${dataTestid}-placeholder`}
			imagePlaceholder={{
				className: styles.placeholder__img,
				icon: 'picture'
			}}
		/>
	);
	if (customPlaceholder) {
		if (customPlaceholder?.image_url) {
			placeholderItemClassNames.push(styles.placeholder__custom);
			imagePlaceholder = (
				<Image
					className={placeholderItemClassNames.join(' ')}
					data-testid={`${dataTestid}-placeholder`}
					src={customPlaceholder.image_url}
				/>
			);
		} else {
			sliderClassNames.push(styles.placeholder__custom);
			imagePlaceholder = null;
		}
	}

	const placeholderItem = (
		<div
			className={sliderClassNames.join(' ')}
			data-testid={`${dataTestid}-slide`}
		>
			{imagePlaceholder}
		</div>
	);

	// Generate item elements
	const itemsElements = items?.length ? items.map((item, index) => {
		const cssImageClasses = [
			styles.slide__image,
			styles[`slide__image__${imagesFormat}`]
		];

		const newImageProps: ImageProps = {
			'data-testid': `${dataTestid}-image`,
			src: item.url,
			alt: item.title || item.filename,
			className: cssImageClasses.join(' '),
			format: imagesFormat,
			imagePlaceholder: {
				className: styles.placeholder__img
			},
		};

		const newImage = createElement(Image, {
			...{
				...newImageProps
			}
		});

		const titleElement = displayTitle ? (
			<div
				className={styles.slide__title}
				data-testid={`${dataTestid}-title`}
			>
				{
					t('format.capitalize', {
						text: item.title || '-'
					})
				}
			</div>
		) : null;

		const cssImageWithTitle = [
			styles.slide__container_without_title
		];

		if (displayTitle) cssImageWithTitle.push(styles.slide__container_with_title);

		return (
			<div
				className={styles.slide}
				data-testid={`${dataTestid}-slide`}
				key={index}
				onClick={handleOnClick}
			>
				<div className={cssImageWithTitle.join(' ')}>
					{newImage}
				</div>
				{titleElement}
			</div>
		);
	}) : placeholderItem;

	const cssClasses = [
		styles.slider_photos
	];

	if (className) cssClasses.push(className);

	// managing slider sizes
	const defaultSize = {
		name: 'default',
		className: styles.size__default
	};
	const existingSizes = [
		{
			name: 'pincard',
			className: styles.size__pincard
		},
		{
			name: 'thumbnail',
			className: styles.size__thumbnail
		},
		defaultSize
	];
	const sizeThemeDefinedExists = existingSizes.filter(existingSize => existingSize.name === size);
	const sizeThemeDefined = sizeThemeDefinedExists.length ? sizeThemeDefinedExists[0] : defaultSize;
	cssClasses.push(sizeThemeDefined.className);

	const NavigationArrow = ({
		className,
		dataTestid,
		onClick,
		iconName,
	}: NavigationArrowProps) => {
		return (
			<div
				className={className}
				data-testid={dataTestid}
				onClick={(event: MouseEvent<HTMLElement>) => {
					event.preventDefault();
					event.stopPropagation();
					onClick(event);
				}}
			>
				<Icon name={iconName} />
			</div>
		);
	};

	const labelsTopLeftElement = (labelsTopLeft?.length) ? <div className={`${styles.labels} ${styles.labels__top_left}`} >{getLabelsElements(labelsTopLeft)}</div> : null;
	const labelsTopRightElement = (labelsTopRight?.length) ? <div className={`${styles.labels} ${styles.labels__top_right}`} >{getLabelsElements(labelsTopRight)}</div> : null;

	const arrowLeft = items?.length ? (
		<NavigationArrow
			className={styles.prev}
			dataTestid={`${dataTestid}-prev`}
			iconName='chevron-left'
			onClick={(event: MouseEvent<HTMLElement>) => {
				(sliderRef.current as ReactSlider).slickPrev();
				if (onClickArrow) onClickArrow(event);
			}}
		/>
	) : null;

	const arrowRight = items?.length ? (
		<NavigationArrow
			className={styles.next}
			dataTestid={`${dataTestid}-next`}
			iconName='chevron-right'
			onClick={(event: MouseEvent<HTMLElement>) => {
				(sliderRef.current as ReactSlider).slickNext();
				if (onClickArrow) onClickArrow(event);
			}}
		/>
	) : null;

	const dotsElement = items?.length && pagination ? (
		<ul className={styles.pagination}>
			{items.map((item, index) => {
				const classNames = [
					styles.dot
				];

				let canBeDisplayed = true;
				const isCurrent = index === currentIndexState;
				// TODO
				/* istanbul ignore next */
				if (isCurrent) {
					classNames.push(styles.dot__active);
				} else {
					if (index <= currentIndexState - 3) {
						canBeDisplayed = false;
					} else if (index >= currentIndexState + 3) {
						canBeDisplayed = false;
					}
					if (index <= currentIndexState - 1) classNames.push(styles.dot__next);
					if (index <= currentIndexState - 2) classNames.push(styles.dot__prev_prev);
					if (index >= currentIndexState + 1) classNames.push(styles.dot__next);
					if (index >= currentIndexState + 2) classNames.push(styles.dot__next_next);
				}
				// TODO
				/* istanbul ignore next */
				const handleOnClickDot = (event: MouseEvent<HTMLElement>) => {
					event.stopPropagation();
					(sliderRef.current as ReactSlider).slickGoTo(index);
				};

				return canBeDisplayed ? (
					<li
						className={classNames.join(' ')}
						data-testid={`${dataTestid}-dot-${index}`}
						key={index}
						onClick={handleOnClickDot}
					></li>
				) : null;
			})}
		</ul>
	) : null;

	// OldIndex is required for the slider even if it's not used
	/* istanbul ignore next */
	const handleOnBeforeChange = (oldIndex: number, newIndex: number) => {
		setCurrentIndexState(newIndex);
	};

	const counterElement = counter && items?.length ? (
		<div
			className={styles.counter}
			data-testid={`${dataTestid}-counter`}
		>
			{`${currentIndexState + 1} / ${items.length}`}
		</div>
	) : null;

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			onClick={handleOnClick}
		>
			{labelsTopLeftElement}
			{labelsTopRightElement}
			{arrowLeft}
			<ReactSlider
				arrows={false} // arrows are managed with custom React components
				autoplay={autoplay}
				autoplaySpeed={autoplaySpeed}
				beforeChange={handleOnBeforeChange}
				infinite={infinite}
				ref={sliderRef}
				slidesToScroll={1}
				slidesToShow={1}
				speed={500}
			>
				{itemsElements}
			</ReactSlider>
			{arrowRight}
			{dotsElement}
			{counterElement}
		</div>
	);
}

export {
	Slider as default,
	SliderProps,
};
