// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XSAZiEJX{border-radius:50%;height:0.71428571rem;width:0.71428571rem}._DYJ9H4R{background-color:var(--color-status-abandoned)}.qVmCqDfh{background-color:var(--color-status-default)}.Ycf5feDO{background-color:var(--color-status-error)}.aI6mAV1s{background-color:var(--color-status-info)}.qjn44o4C{background-color:var(--color-status-naked)}.lgMZsoCR{background-color:var(--color-status-primary)}.xUeckdZV{background-color:var(--color-status-secondary)}.YKU96qmx{background-color:var(--color-status-success)}.qyBeKOSA{background-color:var(--color-status-tertiary)}.m7qx2dsV{background-color:var(--color-status-update)}.GPayxejL{background-color:var(--color-status-wait)}.HOTeq2Ja{background-color:var(--color-status-warning)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `XSAZiEJX`,
	"theme__abandoned": `_DYJ9H4R`,
	"theme__default": `qVmCqDfh`,
	"theme__error": `Ycf5feDO`,
	"theme__info": `aI6mAV1s`,
	"theme__naked": `qjn44o4C`,
	"theme__primary": `lgMZsoCR`,
	"theme__secondary": `xUeckdZV`,
	"theme__success": `YKU96qmx`,
	"theme__tertiary": `qyBeKOSA`,
	"theme__update": `m7qx2dsV`,
	"theme__wait": `GPayxejL`,
	"theme__warning": `HOTeq2Ja`
};
export default ___CSS_LOADER_EXPORT___;
