import {
	ColorsProps,
} from '@components/cartography';

import {
	LayerProps,
} from 'react-map-gl';

const getCirclesClustersLayerConfig = (
	sourceName: string,
	colors: ColorsProps
): LayerProps => {
	return {
		id: `${sourceName}_clusters`,
		type: 'circle',
		source: sourceName,
		filter: [
			'has',
			'point_count'
		],
		paint: {
			// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
			//   * Blue, 20px circles when point count is less than 100
			//   * Yellow, 30px circles when point count is between 100 and 750
			//   * Pink, 40px circles when point count is greater than or equal to 750
			'circle-color': colors.white,
			'circle-radius': [
				'step',
				[
					'get',
					'point_count'
				],
				20,
				5,
				30,
				10,
				50
			],
			'circle-stroke-width': 2,
			'circle-stroke-color': colors.mine_shaft
		}
	};
};

export default getCirclesClustersLayerConfig;
