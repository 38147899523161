
import React from 'react';
import {
	useTranslation,
} from 'react-i18next';

// MODULES
import utilsText from '@modules/text';

interface PriceWithUnitProps {
	chargesUnitTranslationKey?: string;
	price?: number;
	priceText?: string;
	quantitiesAndFrequenciesText?: string;
	transactionType?: string;
	transactionTypeLength?: number;
	vatUnitTranslationKey?: string;
	'data-testid'?: string;
}

function PriceWithUnit({
	chargesUnitTranslationKey,
	price,
	priceText,
	quantitiesAndFrequenciesText,
	transactionType,
	transactionTypeLength = 0,
	vatUnitTranslationKey,
	'data-testid': dataTestid,
}: PriceWithUnitProps) {
	const { t } = useTranslation();

	let feesText = undefined;
	if (price) {
		if (chargesUnitTranslationKey) {
			feesText = (
				<span data-price-unit>
					{t(`format.${chargesUnitTranslationKey}`, {
						text: t(`format.tax_type.${vatUnitTranslationKey}`, {
							text: ''
						})
					})}
				</span>
			);
		} else {
			feesText = (
				<span data-price-unit>
					{t(`format.tax_type.${vatUnitTranslationKey}`, {
						text: ''
					})}
				</span>
			);
		}
	}

	const priceFormated = price ? (
		<span data-price-value>{utilsText.format(price, 'currency') as number}</span>
	) : null;

	let firstLetterTransaction = null;
	if (transactionTypeLength > 1) {
		if (transactionType === 'rent') {
			firstLetterTransaction = t('format.capitalize', {
				text: t('general.rent')
			}).charAt(0);
		} else {
			firstLetterTransaction = t('format.capitalize', {
				text: t('general.sale')
			}).charAt(0);
		}
	}

	const firstLetterElement = <span data-testid={`${dataTestid}-letter`}>({firstLetterTransaction})</span>;

	let result = null;
	if (priceText) {
		result = t('format.capitalize', {
			text: t(`general.${priceText}`)
		});
	} else if (price) {
		result = <>{priceFormated}{quantitiesAndFrequenciesText}{feesText}</>;
	}

	return result ? (
		<div
			data-testid={dataTestid}
		>
			{result}{firstLetterTransaction ? <>&nbsp;{firstLetterElement}</> : null}
		</div>
	) : null;
}

export default PriceWithUnit;
