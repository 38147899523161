import React from 'react';
import {
	distance,
	closest,
} from 'fastest-levenshtein';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	Address, AddressJson,
} from '@@types/index';

interface currencyMappingProps {
	[key: string]: string;
}

const utilsText = {
	capitalize(text: string) {
		return text?.length ? text.charAt(0).toUpperCase() + text.slice(1) : '';
	},
	cleanData(text: string) {
		return text.replace(/[\t\n]/g, '');
	},
	stripHtml(html: string) {
		const tmp = document.createElement('DIV');
		tmp.innerHTML = html;
		const result = tmp.textContent || tmp.innerText || '';
		return result;
	},
	normalizeText(text: string) {
		return text
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/[^a-zA-Z0-9\s]/g, '')
			.toLowerCase();
	},
	getLevenshteinDistance(string1: string, string2: string) {
		return distance(string1.toLowerCase(), string2.toLowerCase());
	},
	getLevenshteinClosest(string1: string, otherStrings: string[]) {
		return closest(string1, otherStrings);
	},
	isWordCloseAtLeastOneWordFromWordlist(firstString: string, wordsList: string[]) {
		let isClose = false;
		let distance = 99;
		const stringIsLong = firstString.length >= APP_CONF_VARS.levenshtein.long.minLength;
		const maxDistance = APP_CONF_VARS.levenshtein[stringIsLong ? 'long' : 'small'].distance;

		const wordListWithoutIgnoredWords = wordsList.filter((word: string) => word.length > APP_CONF_VARS.levenshtein.ignoreLength);
		wordListWithoutIgnoredWords.forEach((word: string) => {
			if (!isClose) {
				distance = utilsText.getLevenshteinDistance(firstString, word);
				if (distance <= maxDistance) {
					isClose = true;
				}
			}
		});
		return isClose;
	},
	format(rawValue: AddressJson | number | string, dataType: string, lng?: string) {
		const language = lng ? lng : 'fr-FR';

		const getCurrencyDOM = () => {
			const currencyMapping = {
				'fr-FR': 'EUR',
				'en-GB': 'GBP'
			} as currencyMappingProps;

			const currencyFormated = new Intl.NumberFormat(language, {
				style: 'currency',
				currency: currencyMapping[language],
				minimumFractionDigits: 0
			});

			const numberString = currencyFormated.formatToParts(rawValue as number).map(({ type, value }) => {
				return `<span data-${type}>${value}</span>`;
			}).reduce((string, part) => string + part);

			return isNaN(rawValue as number) ? rawValue : (
				<span
					dangerouslySetInnerHTML={
						{
							__html: numberString
						}
					}
					data-currency
				></span>
			);
		};

		switch (dataType) {
			case 'address': {
				const newAddress = new Address(rawValue as AddressJson);
				return newAddress.full;
			}
			case 'currency': {
				return getCurrencyDOM();
			}
			default:
				return this.cleanData(rawValue);
		}
	}
};

export default utilsText;
