import {
	EnumMandateType,
} from '@enums/mandate.enum';

export interface MandateJson {
	type?: EnumMandateType;
	is_owner?: boolean;
}

export class Mandate {
	public mandate: MandateJson;

	constructor(
		mandate: MandateJson
	) {
		this.mandate = mandate;
	}

	get is_owner(): boolean {
		return this.mandate.is_owner || false;
	}

	get type(): EnumMandateType | undefined {
		return this.mandate.type;
	}
}
