import React, {
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// ENUMS
import {
	EnumInputSize,
} from '@enums/form.enum';

// COMPONENTS
import Cartography from '@components/cartography';
import {
	CartographyProps,
} from '@components/cartography';
import InputSelect, {
	HTMLSelectElementCustom,
} from '@components/form/input-select';

// STYLES
import styles from './cartography.module.scss';

const CartographyLayout = ({
	className,
	copyright,
	geojsonSource,
	innerRef,
	onClickPopup,
	onClickMarker,
	onClosePopup,
	popupData,
	tilesLayerData
}: CartographyProps): JSX.Element => {
	const { t } = useTranslation();
	const mapRef = useRef(null);

	const cssClasses = [
		styles.cartography
	];

	if (className) cssClasses.push(className);

	const [
		tilesToDisplay,
		setTilesToDisplay
	] = useState(null);

	const optionsTile = [
	] as object[];
	tilesLayerData?.forEach((line: string) => {
		const option = {
			label: t('format.capitalize', {
				text: t(`general.tiles.${line}`)
			}),
			value: line
		};
		optionsTile.push(option as object);
	});

	const handleTransportsLineClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		const selectValues = (document.querySelector(`.${styles.tranport_lines_select} select`) as HTMLSelectElementCustom).values;
		setTilesToDisplay(selectValues);
	};

	const selectLayersElement = (
		<InputSelect
			className={styles.tranport_lines_select}
			hasShadow={true}
			isMultiSelect={true}
			options={optionsTile}
			placeholder={t('format.capitalize', {
				text: t('general.transports')
			})}
			size={EnumInputSize.SMALL}
			onClickOption={handleTransportsLineClick}
		/>
	);

	const cartographyElement = (
		<Cartography
			config={APP_CONF_VARS.mapbox.config}
			copyright={copyright}
			geojsonSource={geojsonSource}
			innerRef={innerRef || mapRef}
			popupData={popupData}
			tilesLayerData={tilesLayerData}
			tilesToDisplay={tilesToDisplay}
			onClickMarker={onClickMarker}
			onClickPopup={onClickPopup}
			onClosePopup={onClosePopup}
		/>
	);

	return (
		<div className={cssClasses.join(' ')}>
			<div className={styles.loading}>
				<>
					{t('format.capitalize', {
						text: t('general.loading')
					})}
				</>
			</div>
			{selectLayersElement}
			{cartographyElement}
		</div>
	);
};

export default withTranslation()(CartographyLayout);
