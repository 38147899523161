import {
	Address,
	AddressJson,
	Area,
	AreaJson,
} from '@@types/index';

import {
	GeoJsonProperties,
} from 'geojson';

export interface BuildingJson {
	address?: AddressJson;
	area?: AreaJson;
	geo_json?: GeoJsonProperties;
}

export class Building {
	public building: BuildingJson;

	constructor(
		building: BuildingJson
	) {
		this.building = building;
	}

	get address(): Address | undefined {
		return this.building?.address ? new Address(this.building.address) : undefined;
	}

	get area(): Area | undefined {
		return this.building?.area ? new Area(this.building.area) : undefined;
	}

	get geo_json(): GeoJsonProperties | undefined {
		return this.building?.geo_json;
	}
}
