// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mS3Ve3Tj .QrxHhKUq{background-color:var(--color-background-tertiary);box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.3);font-size:0.85714286rem;font-weight:600;line-height:1.4em;max-width:17.14285714rem;padding:0.42857143rem 0.57142857rem;text-align:center;width:max-content;z-index:2}.mS3Ve3Tj .QrxHhKUq:before{height:0.35714286rem !important}.mS3Ve3Tj .QrxHhKUq:after{background-color:var(--color-background-tertiary) !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `mS3Ve3Tj`,
	"customTooltip": `QrxHhKUq`
};
export default ___CSS_LOADER_EXPORT___;
