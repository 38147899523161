import {
	ColorsProps,
} from '@components/cartography';

import {
	LayerProps,
} from 'react-map-gl';

const getCirclesLayerConfig = (
	sourceName: string,
	colors: ColorsProps
): LayerProps => {
	return {
		id: `${sourceName}_circles`,
		type: 'circle',
		source: sourceName,
		layout: {
			'visibility': 'visible',
		},
		filter: [
			'!',
			[
				'has',
				'point_count'
			]
		],
		paint: {
			'circle-radius': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'hover',
					],
					false
				],
				11,
				8,
			],
			'circle-color': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'active'
					],
					false
				],
				colors.white,
				colors.mine_shaft,
			],
			'circle-stroke-width': 2,
			'circle-stroke-color': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'active'
					],
					false
				],
				colors.mine_shaft,
				colors.white,
			],
		},
	};
};

export default getCirclesLayerConfig;
