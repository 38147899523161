export enum EnumThemeLink {
	NAKED = 'naked',
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TAB = 'tab',
}

export enum EnumTargetLink {
	BLANK = '_blank',
	SELF = '_self',
}
