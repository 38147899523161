export enum EnumSort {
	ASC = 'asc',
	DESC = 'desc',
}

export enum EnumSortFn {
	ALPHANUMERIC = 'alphanumeric',
	ALPHANUMERIC_CASE_SENSITIVE = 'alphanumericCaseSensitive',
	BASIC = 'basic',
	DATETIME = 'datetime',
	TEXT = 'text',
	TEXT_CASE_SENSITIVE = 'textCaseSensitive',
}
