// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-placeholder-background": `#797676`,
	"color-placeholder-flash": `#e1e1e1`,
	"color-status-error": `#c00`,
	"color-status-info": `#078fef`,
	"color-status-success": `#2f9400`,
	"color-theme-primary": `#2c8748`,
	"color-theme-secondary": `#1d6fb9`,
	"color-theme-tertiary": `#1d6fb9`,
	"azureradiance": `#078fef`,
	"alabaster": `#fafafa`,
	"boulder": `#797676`,
	"chelseaGem": `#a05c03`,
	"chromeWhite": `#e1f0cf`,
	"denim": `#1d6fb9`,
	"electricViolet": `#6200ee`,
	"guardsmanRed": `#c00`,
	"iceberg": `#d6f1f3`,
	"limeade": `#2f9400`,
	"mercury": `#e1e1e1`,
	"seaGreen": `#2c8748`,
	"trinidad": `#e65000`,
	"mineShaft": `#393939`,
	"wildSand": `#f5f5f5`,
	"white": `#fff`
};
export default ___CSS_LOADER_EXPORT___;
