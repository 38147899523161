import * as React from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';

// COMPONENT
import Icon from '@components/icon';

// STYLES
import styles from './checkbox.module.scss';

interface CheckboxProps {
	checked?: boolean;
	className?: string;
	'data-testid'?: string;
	disabled?: boolean;
}

const Checkbox = ({
	checked,
	className,
	'data-testid': dataTestid,
	disabled
}: CheckboxProps): JSX.Element => {

	const cssClasses = [
		styles.checkbox
	];
	if (className) cssClasses.push(className);

	if (checked) {
		cssClasses.push(styles.checkbox__checked);
	}

	if (disabled) {
		cssClasses.push(styles.checkbox__disabled);
	}

	return (
		<Icon
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			fontStyle={EnumFontStyle.SOLID}
			name='check'
		/>
	);
};

export {
	Checkbox as default,
	CheckboxProps,
};

