import * as React from 'react';
import {
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENT
import Button, {
} from '@components/button';
import DropdownItem, {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';

// STYLES
import styles from './dropdown.module.scss';

interface DropdownProps {
	className?: string;
	corners?: 'semi-square' | null;
	hasClear?: boolean;
	hasEllipsis?: boolean;
	isMultiSelect?: boolean;
	items: DropdownItemProps[];
	onClickItem?: (event: MouseEvent<HTMLElement>, itemData: DropdownItemProps) => void;
	onClickClear?: (event: MouseEvent<HTMLElement>, args?: DropdownItemProps) => void;
	selectedIndex?: number;
	selectedIndexes?: number[];
	size?: 'default' | 'full';
	theme?: EnumTheme.PRIMARY | EnumTheme.SECONDARY;
	title?: {
		label?: string;
		titleLevel?: number;
	};

	'data-testid'?: string;
}

const Dropdown = ({
	className,
	corners,
	'data-testid': dataTestid,
	hasClear = false,
	hasEllipsis = false,
	isMultiSelect = false,
	items,
	onClickItem,
	onClickClear,
	selectedIndex,
	selectedIndexes = [
	],
	size = 'default',
	theme = EnumTheme.PRIMARY,
	title,
}: DropdownProps): JSX.Element | undefined => {
	const classes = [
		styles.dropdown
	];

	if (className) classes.push(className);
	if (corners) classes.push(styles[`corners__${corners}`]);
	classes.push(styles[`size__${size}`]);

	let dropDownElement = undefined;

	const [
		selectedIndexState,
		setSelectedIndexState
	] = useState(selectedIndex);

	/* istanbul ignore next */
	const handleOnClickItem = (event: MouseEvent<HTMLElement>, itemData: DropdownItemProps) => {
		const index = items?.findIndex((item: DropdownItemProps) => {
			return item.label === itemData.label ? item : null;
		});
		setSelectedIndexState(index);
		if (itemData?.onClick) itemData.onClick(event, itemData);
		if (onClickItem) onClickItem(event, itemData);
	};
	/* istanbul ignore next */
	const handleOnClickClearButton = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setSelectedIndexState(undefined);

		(onClickClear) ? onClickClear(event) : undefined;
	};

	const clearButton = hasClear ? (
		<Button
			data-testid={`${dataTestid}-clear`}
			hasBoxShadow={false}
			iconLeft='trash-alt'
			iconStyle={EnumFontStyle.LIGHT}
			theme={EnumTheme.NAKED}
			onClick={handleOnClickClearButton}
		/>
	) : null;
	const titleElement = title?.label || hasClear ? (
		<div
			className={styles.header}
			data-testid={`${dataTestid}-title`}
		>
			<div
				className={`${styles.title} ${title?.titleLevel ? 'typo_h' + title.titleLevel : 'typo_c1'}`}
			>{title?.label}</div>
			{clearButton}
		</div>
	) : null;

	if (items?.length) {
		const mergedItems = [
		].concat(...items as ConcatArray<never>[]); // merge all sub arrays into one

		dropDownElement = (
			<div
				className={classes.join(' ')}
				data-testid={dataTestid}
			>
				{titleElement}
				<ul className={styles.dropdown__list}>
					{
						mergedItems.map((childData: DropdownItemProps, index) => {
							return (
								<DropdownItem
									{...childData}
									data-testid={`${dataTestid}-item`}
									enabled={childData?.labelEnabled && selectedIndexState !== null && selectedIndexState === index ? true : false}
									hasEllipsis={hasEllipsis}
									itemData={childData}
									key={index}
									selected={(selectedIndexState !== null && selectedIndexState === index) || selectedIndexes.includes(index)}
									theme={childData?.theme ? childData.theme : theme}
									withCheckbox={isMultiSelect}
									onClick={handleOnClickItem}
								/>
							);
						})
					}
				</ul>
			</div>
		);
	}

	return dropDownElement;
};

export {
	Dropdown as default,
	DropdownProps,
};
