import * as React from 'react';

import {
	MouseEvent,
	ReactNode,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputIconPosition,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// COMPONENTS
import Icon from '@components/icon';
import Text from '@components/text';

// STYLES
import styles from './input-label.module.scss';

interface InputLabelProps {
	className?: string;
	children?: string | ReactNode;
	'data-testid'?: string;
	iconPosition?: EnumInputIconPosition;
	iconName?: string;
	iconStyle?: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
	id?: string;
	invalid?: boolean;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	tabIndex?: number;
	textEllipsis?: boolean;
}

const InputLabel = ({
	className,
	children,
	'data-testid': dataTestid,
	iconPosition = EnumInputIconPosition.RIGHT,
	iconName,
	iconStyle = EnumFontStyle.LIGHT,
	id,
	invalid,
	onClick,
	tabIndex,
	textEllipsis = false,
}: InputLabelProps): JSX.Element => {

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		if (onClick) onClick(event);
	};

	const cssClasses = [
		styles.label,
	];

	if (className) cssClasses.push(className);

	const iconLabel = (
		<Icon
			aria-hidden="true"
			aria-invalid={invalid}
			className={styles.icon}
			data-testid={`${dataTestid}-icon`}
			fontStyle={iconStyle}
			name={iconName}
		/>
	);

	return (
		<label
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			htmlFor={id}
			tabIndex={tabIndex}
			onClick={handleOnClick}
		>
			{(iconName && iconPosition === EnumInputIconPosition.LEFT) ? iconLabel : null}
			{typeof children === 'string' ? (
				<Text
					className={styles.text}
					ellipsis={textEllipsis}
				>{children}</Text>
			) : children}
			{(iconName && iconPosition === EnumInputIconPosition.RIGHT) ? iconLabel : null}
		</label >
	);
};

InputLabel.displayName = EnumComponentType.INPUT_LABEL;

export {
	InputLabel as default,
	InputLabelProps,
};
