// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vjhz4rvB{align-items:center;background-color:var(--color-background-primary);border-radius:1em;color:var(--color-white);display:inline-flex;font-family:arial,helvetica,sans-serif;justify-content:center;line-height:1em;min-width:1.42857143rem;padding:0.28571429rem 0.5rem;font-size:0.85714286rem;font-weight:600}.LN3J1gRh{min-width:1.07142857rem;padding:0.14285714rem 0.28571429rem;font-size:0.78571429rem;font-weight:400;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgecounter": `vjhz4rvB`,
	"size__small": `LN3J1gRh`
};
export default ___CSS_LOADER_EXPORT___;
