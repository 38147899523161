// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gFiOcS_N{background-color:var(--color-background-default);bottom:0;height:100vh;position:fixed;right:100%;top:0;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.gFiOcS_N{height:100%;overflow-y:scroll;position:absolute;top:0}}.QbrylWWn{height:calc(100vh - 4.28571429rem);overflow-y:auto;position:relative;z-index:1}@media screen and (min-width: 768Px)and (orientation: landscape){.QbrylWWn{height:auto;margin:0;padding:0 1.42857143rem}}.fV5oxGMx{align-items:center;background:var(--color-white);display:flex;height:4.28571429rem;width:100%;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.fV5oxGMx:first-letter{display:block;text-transform:uppercase}@media screen and (min-width: 768Px)and (orientation: landscape){.fV5oxGMx{position:relative;top:inherit;z-index:4}}.gFiOcS_N .RSQbmhfx{display:flex;font-weight:400;margin:auto 1.14285714rem auto auto}@media screen and (min-width: 768Px)and (orientation: landscape){.gFiOcS_N .RSQbmhfx{margin-right:1.42857143rem}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PanelDetails": `gFiOcS_N`,
	"PanelDetails__body": `QbrylWWn`,
	"PanelDetails__header": `fV5oxGMx`,
	"share": `RSQbmhfx`
};
export default ___CSS_LOADER_EXPORT___;
