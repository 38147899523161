import {
	ImageJson,
	Person,
	PersonJson,
} from '@@types/index';

export interface ContactJson extends PersonJson {
	civility_key: string;
	company_name?: string;
	enable: boolean;
	full_name?: string;
	id?: number;
	job?: string;
	logo?: ImageJson;
}

export class Contact extends Person {
	public contact: ContactJson;

	constructor(
		contact: ContactJson
	) {
		super({
			email: contact?.email,
			firstname: contact?.firstname,
			lastname: contact?.lastname,
			mobile: contact?.mobile,
			phone: contact?.phone,
		});
		this.contact = contact;
	}

	get civility_key(): string {
		return this.contact?.civility_key || 'mister';
	}

	get company_name(): string | undefined {
		return this.contact?.company_name ? this.contact.company_name : undefined;
	}

	get enable(): boolean {
		return this.contact?.enable || false;
	}

	get id(): number | undefined {
		return this.contact?.id ? this.contact.id : undefined;
	}

	get job(): string | undefined {
		return this.contact?.job ? this.contact.job : undefined;
	}

	get logo(): ImageJson | undefined {
		return this.contact?.logo ? this.contact.logo : undefined;
	}
}
