// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mWK6lUmc{background-color:#fff;border-radius:0.57142857rem;box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);cursor:default;max-height:34.71428571rem;max-width:31.42857143rem;min-width:15rem;overflow-y:auto}.l3czNRxm{display:flex;flex-flow:column wrap}.wD2Ood6q{border-radius:0 0.57142857rem 0.57142857rem 0}.WyEZ9zR6{display:flex;justify-content:space-between}.PirlL7GU{width:max-content}.gMT1075s{width:100%}.wWY6wa9T{font-weight:600;margin-bottom:0;padding:1rem 1.42857143rem;text-transform:uppercase}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `mWK6lUmc`,
	"dropdown__list": `l3czNRxm`,
	"corners__semi-square": `wD2Ood6q`,
	"header": `WyEZ9zR6`,
	"size__default": `PirlL7GU`,
	"size__full": `gMT1075s`,
	"title": `wWY6wa9T`
};
export default ___CSS_LOADER_EXPORT___;
