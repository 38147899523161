import i18n from '@modules/i18n';
import {
	MouseEvent,
} from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// LAYOUTS
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';

// TYPES
import {
	CollectionJson,
	DocumentTemplatesProps,
} from '@@types/Collection';
import {
	ContactJson,
} from '@@types/Contact';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';

// STORES
import {
	DeviceDisplays, DeviceInstance,
} from '@stores/_slices/device';
import {
	EnumTargetLink, 
} from '@enums/link.enum';

export const getItemsOfferData = (
	brokers?: CollectionJson<ContactJson>,
	device?: DeviceInstance,
	documentTemplates?: CollectionJson<DocumentTemplatesProps>,
	offerId?: number,
	onClickModal?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void
) => {
	const handleOnClickModal = (event: MouseEvent<HTMLElement>) => {
		const newBodyRequest = {
			brokers: brokers?.collection as ContactJson[],
			document_templates: documentTemplates.collection as DocumentTemplatesProps[],
			offerId: offerId,
		};

		if (onClickModal) onClickModal(event, newBodyRequest);
	};
	return [
		{
			disabled: documentTemplates?.collection?.length > 0 ? false : true,
			iconLeft: 'arrow-to-bottom',
			label: i18n.t('format.capitalize', {
				text: device.display === DeviceDisplays.DESKTOP ? i18n.t('general.action.download_offer') : i18n.t('general.action.download')
			}),
			name: 'download_template',
			onClick: handleOnClickModal,
			iconStyle: EnumFontStyle.LIGHT,
		},
		{
			href: `${APP_CONF_VARS.sfLegacyPath}/offer/${offerId}/update`,
			target: EnumTargetLink.BLANK,
			iconLeft: 'edit',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.modify')
			}),
			iconStyle: EnumFontStyle.LIGHT,
		}
	];
};
