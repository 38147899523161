import textUtils from '@modules/text';
import i18n, {
	InterpolationOptions,
} from 'i18next';
import parsePhoneNumber from 'libphonenumber-js';
import {
	initReactI18next,
} from 'react-i18next';
import getIndefiniteArticle from './getIndefiniteArticle';

import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import local_gb from '@localizations/en-GB.json';
import local_fr from '@localizations/fr-FR.json';

interface InterpolationOptionsProps extends InterpolationOptions {
	decimals?: number;
}

// the translations
// (tip move them in a JSON file and import them)
export const resources = {
	'en-GB': {
		translation: local_gb
	},
	'fr-FR': {
		translation: local_fr,
	},
} as const;
export const defaultNS = 'fr-FR';

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// .use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		lng: localStorage.getItem('i18nextLng') ? /* istanbul ignore next */ localStorage.getItem('i18nextLng') : 'fr-FR', // keep it. We will decide to remove it if we don't use the localStorage when the authentication will be done
		fallbackLng: 'fr-FR',
		supportedLngs: [
			'fr-FR',
			'en-GB',
		],
		load: 'currentOnly',
		// detectLngFromHeaders: false,
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			format: (
				// eslint-disable-next-line
				value: any,
				rawFormat: string,
				lng: string,
				options: InterpolationOptionsProps
			) => {
				const [
					format,
					...additionalValues
				] = rawFormat.split(',').map((v) => v.trim());

				switch (format) {
					case 'capitalize': {
						return textUtils.capitalize(value);
					}
					case 'indefinite': {
						return getIndefiniteArticle(value);
					}
					case 'lowercase': {
						return value.toLowerCase();
					}
					case 'number': {
						interface FormatConf {
							style: string;
							maximumFractionDigits: number;
							minimumFractionDigits: number;
							currency?: string;
						}

						const formatConf = {
							style: 'decimal',
							maximumFractionDigits: 0,
							minimumFractionDigits: 0,
						} as FormatConf;

						// Check if currency iso 4217 code is ok
						const availableCurrencyAdditionalValues = [
							'EUR',
							'GBP'
						];
						const hasCurrencyCode = additionalValues[0] && availableCurrencyAdditionalValues.includes(additionalValues[0]) ? additionalValues[0] : false;
						if (hasCurrencyCode) {
							formatConf.style = 'currency';
							formatConf.currency = hasCurrencyCode; // currency code must be ISO 4217 compliant. Ex : EUR, GBP, ...
						}

						// Option to add decimals to number
						if (options?.decimals) {
							formatConf.maximumFractionDigits = options.decimals;
							formatConf.minimumFractionDigits = options.decimals;
						}
						return Intl.NumberFormat(lng, formatConf as Intl.NumberFormatOptions).format(value);
					}
					case 'tel': {
						const phoneNumber = parsePhoneNumber(value, 'FR');
						const formatedPhoneNUmber = phoneNumber ? phoneNumber.formatNational() : null;
						return formatedPhoneNUmber;
					}
					case 'uppercase': {
						return value.toUpperCase();
					}
				}
				return value;
			}
		},
		react: {
			useSuspense: true,
		},
		// compatibilityJSON: 'v3', // for plural
	});

export default i18n;
