// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fwoz7NqX{flex-direction:column;margin-bottom:1.42857143rem;position:relative;width:100%}.fwoz7NqX .J_Txxbka{display:flex;flex-direction:row;justify-content:space-between;margin-top:1.14285714rem}.fwoz7NqX .eTBoIlYH{display:flex;flex-wrap:wrap}.fwoz7NqX .eTBoIlYH .orpc4NUh{margin-right:1.42857143rem;max-width:16.14285714rem}.fwoz7NqX .eTBoIlYH .orpc4NUh a{line-height:2em;word-break:break-all}.fwoz7NqX .eTBoIlYH .orpc4NUh.ImQda8AG a,.fwoz7NqX .eTBoIlYH .orpc4NUh.IvqroD7q a{-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:1;line-height:2.2em;min-height:2em;overflow:hidden;text-overflow:ellipsis}.u5So7peD{font-size:0.85714286rem;color:var(--color-boulder);flex:1;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fwoz7NqX`,
	"search_criterias": `J_Txxbka`,
	"element": `eTBoIlYH`,
	"child": `orpc4NUh`,
	"locations": `ImQda8AG`,
	"natures": `IvqroD7q`,
	"title": `u5So7peD`
};
export default ___CSS_LOADER_EXPORT___;
