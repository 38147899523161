interface mapUtilsProps {
		geometry?: {
			coordinates?: string[];
		};
}

const mapUtils = {
	getBounds(featuresList: mapUtilsProps[]) {
		if (!Array.isArray(featuresList) || !featuresList.length) return;

		// default bounds
		const bounds = [
			[
			],
			[
			]
		] as Array<string[]>;
		featuresList.forEach((feature: mapUtilsProps) => {
			const longitude = feature.geometry.coordinates[0];
			const latitude = feature.geometry.coordinates[1];
			/* istanbul ignore next */
			bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
			/* istanbul ignore next */
			bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
			/* istanbul ignore next */
			bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
			/* istanbul ignore next */
			bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
		});
		return bounds;
	}
};
export default mapUtils;
