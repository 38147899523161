import utilsText from '@modules/text';

export interface AddressJson {
	building_name?: string;
	city?: string;
	full?: string;
	postal_code?: string;
	street?: string;
	street_numbers?: Array<string>;
	street_raw?: string;
	street_type?: string;
}

export class Address {
	public address: AddressJson;

	constructor(
		address: AddressJson
	) {
		this.address = address;
	}

	get building_name(): string | undefined {
		return this.address.building_name ? utilsText.capitalize(this.address.building_name) : undefined;
	}

	get building_name_street_numbers(): string {
		return `${this.building_name && this.street_numbers_street_type_street.length ? `${this.building_name} - ` : ''}${this.street_numbers_street_type_street}`;
	}

	get city(): string | undefined {
		return this.address.city ? utilsText.capitalize(this.address.city) : undefined;
	}

	get full() {
		let fullAddress = '';
		if (this.street_raw?.length) {
			fullAddress = this.building_name ? `${this.building_name} - ${this.street_raw}` : this.street_raw;
		} else {
			const results: Array<string> = [
			];

			const buildingNameAndNumberAndStreet: string = this.building_name_street_numbers;
			if (buildingNameAndNumberAndStreet?.length) results.push(buildingNameAndNumberAndStreet);

			const postalCodeAndCity: string = this.postal_code_city;
			if (postalCodeAndCity.length) results.push(postalCodeAndCity);

			fullAddress = results.join(', ');
		}

		return fullAddress;
	}

	get postal_code(): string | undefined {
		return this.address?.postal_code;
	}

	get postal_code_city(): string {
		let result = '';
		const postalCodeAndCity = `${this.postal_code}\u00A0${this.city}`;
		if (this.postal_code && this.city) {
			result = postalCodeAndCity;
		} else if (this.postal_code && !this.city) {
			result = this.postal_code;
		} else if (this.city) {
			result = utilsText.capitalize(this.city);
		}
		return result;
	}

	get street(): string | undefined {
		return this.address.street;
	}

	get street_numbers_city_postal_code(): string {
		let result = '';
		if (this.street_raw?.length) {
			result = this.street_raw;
		} else {
			const separator: string = this.street_numbers_street_type_street.length && this.postal_code_city.length ? ', ' : '';
			const postalCodeCity: string = this.postal_code_city;
			const street: string = this.street_numbers_street_type_street;
			result = `${street}${separator}${postalCodeCity}`;
		}
		return result;
	}

	get street_numbers_street_type_street(): string {
		let result = '';
		if (this.street) {
			result = `${this.street_numbers_value ? `${this.street_numbers_value} ` : ''}${this.street_type_street}`;
		}
		return result;
	}

	get street_numbers_value(): string {
		let numbersString = '';
		const firstNumber: string = this.address?.street_numbers?.length ? this.address.street_numbers[0] : '';
		const lastNumber: string = this.address?.street_numbers?.length ? this.address.street_numbers[this.address.street_numbers.length - 1] : '';
		if (firstNumber?.length && lastNumber?.length && firstNumber !== lastNumber) {
			numbersString = `${firstNumber}-${lastNumber}`;
		} else {
			numbersString = this.address?.street_numbers ? this.address?.street_numbers[0] : '';
		}
		return numbersString;
	}

	get street_raw(): string | undefined {
		return this.address.street_raw;
	}

	get street_type(): string | undefined {
		return this.address.street_type;
	}

	get street_type_street(): string | undefined {
		return this.street_type ? `${this.street_type} ${this.street}` : this.street;
	}
}
