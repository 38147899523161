import {
	ImageJson,
	Modules,
	Person,
	PersonJson,
} from '@@types/index';

export interface ObservatoryReport {
	id: number;
	name: string;
	permission_name: string;
	is_private: boolean;
	demo: {
		description: string;
		title: string;
		url: string;
	};
}

export interface UserJson extends PersonJson {
	civility_key?: string;
	full_name?: string;
	id?: number;
	inactivity?: {
		date?: number;
	};
	logo?: ImageJson;
	mobile?: string;
	modules?: Modules;
	notified?: boolean;
	observatory_reports?: ObservatoryReport[];
	username?: string;
}
export class User extends Person {
	public user: UserJson;

	constructor(
		user: UserJson
	) {
		super({
			email: user?.email,
			firstname: user?.firstname,
			lastname: user?.lastname,
			phone: user?.phone,
			mobile: user?.mobile,
		});

		this.user = user;
	}

	get civility_key(): string {
		return this.user.civility_key || 'mister';
	}

	get id(): number | undefined {
		return this.user.id;
	}

	get logo(): ImageJson | undefined {
		return this.user.logo?.url ? {
			url: this.user.logo?.url,
		} : undefined;
	}
}
