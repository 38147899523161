import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
const FETCHES = {
	private: {
		addresses: {
			id: `${APP_CONF_VARS.privatePath}/private/addresses?id=eq::address_id`,
			street_city: `${APP_CONF_VARS.privatePath}/private/addresses?street=eq::street_raw&city=eq::city_raw`,
		},
		brokers: `${APP_CONF_VARS.privatePath}/private/me/company/brokers`,
		contacts_search: `${APP_CONF_VARS.privatePath}/private/contacts/search?q=eq::query`,
		marketers_create: `${APP_CONF_VARS.privatePath}/private/marketers`,
		marketers: `${APP_CONF_VARS.privatePath}/private/marketers?company=eq::company`,
		document_templates: `${APP_CONF_VARS.privatePath}/private/document_templates`,
		generate_download: `${APP_CONF_VARS.privatePath}/private/document_templates/:templateid/generate`,
		generate_preview: `${APP_CONF_VARS.privatePath}/private/document_templates/:templateid/generate?pdf=true`,
		demands: {
			_ROOT: `${APP_CONF_VARS.privatePath}/private/demands`,
			alert_broker: `${APP_CONF_VARS.sfPath}/Demands/:demandid/alert/update?type=self_alert`,
			brokers: `${APP_CONF_VARS.privatePath}/private/demands/brokers`,
			delete: `${APP_CONF_VARS.sfLegacyPath}/demandSearch/MultipleDelete`,
			export: `${APP_CONF_VARS.sfLegacyPath}/demandSearch/searchResultsMetaDemandeCSV?filter[announcement_demand_ids]=[:selectedids]&print=CSV`,
			geo_json: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/geojson?commercialization_step=eq::step`,
			id: `${APP_CONF_VARS.privatePath}/private/demands/:demandid`,
			offer: {
				_ROOT: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid`,
				application: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid/applications`,
				contacts: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid/contacts`,
				event_history: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid/event_history`,
				lots: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid/surfaces`,
				viewed: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid/viewed`,
				visit: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers/:offerid/visits`,
			},
			offers: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers`,
			step: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/offers?commercialization_step=eq::step&items_per_page=${APP_CONF_VARS.api.demand.offers.limit}`,
			update_view_mode: `${APP_CONF_VARS.privatePath}/private/demands/:demandid/settings/table`,
		},
		logout: `${APP_CONF_VARS.privatePath}/logout`,
		observatory: {
			report: `${APP_CONF_VARS.privatePath}/private/reports/:reportid`,
			reports: `${APP_CONF_VARS.privatePath}/private/reports`,
		},
		offers: {
			_ROOT: `${APP_CONF_VARS.privatePath}/private/offers`,
			search: `${APP_CONF_VARS.privatePath}/private/offers/search`,
			contacts: {
				__ROOT: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/contacts`,
				contact_id: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/contacts/:contactid`,
			},
			default_values: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/default_values`,
			edit: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit`,
			enums: `${APP_CONF_VARS.privatePath}/private/offers/enums`,
			id: `${APP_CONF_VARS.privatePath}/private/offers/:offerid`,
			lots: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/lots`,
			lots_edit: {
				__ROOT: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/lots`,
				archive: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/lots/archive`,
				lot_id: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/lots/:lotid`,
				total: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/lots/total`,
			},
			step: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/demands?commercialization_step=eq::step`,
			matching: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/demands`,
			media: `${APP_CONF_VARS.privatePath}/private/offers/:offerid/edit/medias`,
		},
		create_request: `${APP_CONF_VARS.privatePath}/demos/create_request`,
	},
	public: {
		demands: {
			_ROOT: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token`,
			offers: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token/offers`,
			geo_json: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token/offers/geojson?commercialization_step=eq::step`,
			offer: {
				_ROOT: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token/offers/:offerid`,
				viewed: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token/offers/:offerid/viewed`,
			},
			step: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token/offers?commercialization_step=eq::step&items_per_page=${APP_CONF_VARS.api.demand.offers.limit}`,
			viewed: `${APP_CONF_VARS.privatePath}/public/:segmenturl/shared_demand/:token/viewed`,
		},
		authentification: {
			authentificator_2fa_request: `${APP_CONF_VARS.sfPath}/2fa/usercode/send`,
			authentificator_2fa_code: `${APP_CONF_VARS.sfPath}/2fa_check/webapp/sms`,
			login: `${APP_CONF_VARS.privatePath}/login`,
			reset_password_request: `${APP_CONF_VARS.privatePath}/public/user/password/reset_request`,
			please_disconnect: `${APP_CONF_VARS.privatePath}/public/please_disconnect`,
			reset_password_token: `${APP_CONF_VARS.privatePath}/public/user/password/reset_form`,
		},
		me: `${APP_CONF_VARS.privatePath}/me`,
		session: `${APP_CONF_VARS.privatePath}/session`,
	}
};

export default FETCHES;
