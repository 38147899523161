import {
	Address,
	AddressJson,
	Image,
	ImageJson,
	Price,
	PriceJson,
} from '@@types/index';

export interface Lease {
	end_date?: string;
	rental_price?: Price;
	type?: string;
}
export interface LeaseJson {
	end_date?: string;
	rental_price?: PriceJson;
	type?: string;
}

export interface CompanyJson {
	activity?: string;
	address?: AddressJson;
	establishment_id?: number;
	id?: number;
	image?: ImageJson;
	lease?: LeaseJson;
	logo_squared?: ImageJson;
	logo_standard?: ImageJson;
	name?: string;
	phone?: string;
	segment_url?: string;
	theme_color_primary_hex?: string;
	theme_color_primary_hsl?: string;
	website_url?: string;
}

export class Company {
	public company: CompanyJson;
	public language: string;

	constructor(
		company: CompanyJson,
		language = 'fr-FR'
	) {
		this.company = company;
		this.language = language;
	}

	get activity(): string | undefined {
		return this.company?.activity;
	}

	get address(): Address | undefined {
		return this.company?.address ? new Address(this.company?.address) : undefined;
	}

	get establishment_id(): number | undefined {
		return this.company?.establishment_id;
	}

	get id(): number | undefined {
		return this.company.id;
	}

	get image(): Image | undefined {
		return this.company?.image ? new Image(this.company.image) : undefined;
	}

	get lease(): Lease | undefined {
		return {
			end_date: this?.company?.lease?.end_date,
			rental_price: this?.company?.lease?.rental_price ? new Price(this?.company?.lease?.rental_price, this.language) : undefined,
			type: this?.company?.lease?.type,
		};
	}

	get logo_squared(): ImageJson | undefined {
		return {
			category: this.company.logo_squared?.category,
			filename: this.company.logo_squared?.filename,
			main: this.company.logo_squared?.main,
			title: this.company.logo_squared?.title,
			url: this.company.logo_squared?.url,
		};
	}

	get logo_standard(): ImageJson | undefined {
		return {
			category: this.company.logo_standard?.category,
			filename: this.company.logo_standard?.filename,
			main: this.company.logo_standard?.main,
			title: this.company.logo_standard?.title,
			url: this.company.logo_standard?.url,
		};
	}

	get name(): string | undefined {
		return this.company.name;
	}

	get phone(): string | undefined {
		return this.company.phone;
	}

	get segment_url(): string | undefined {
		return this.company.segment_url;
	}

	get theme_color_primary_hex(): string | undefined {
		return this.company.theme_color_primary_hex;
	}

	get theme_color_primary_hsl(): string | undefined {
		return this.company.theme_color_primary_hsl;
	}

	get website_url(): string | undefined {
		return this.company.website_url;
	}
}
