import {
	ColorsProps,
} from '@components/cartography';

import {
	LayerProps,
} from 'react-map-gl';

const getCirclesClustersCountersLayerConfig = (
	sourceName: string,
	colors: ColorsProps
): LayerProps => {
	return {
		id: `${sourceName}_cluster_counter`,
		type: 'symbol',
		source: sourceName,
		filter: [
			'has',
			'point_count'
		],
		layout: {
			'text-field': '{point_count_abbreviated}',
			'text-font': [
				'DIN Offc Pro Medium',
				'Arial Unicode MS Bold'
			],
			'text-size': 12
		},
		paint: {
			'text-color': colors.mine_shaft
		}
	};
};

export default getCirclesClustersCountersLayerConfig;
