const EVENTS = {
	LOCATION_CHANGED: 'LOCATION_CHANGED', // WHEN THE ROUTE HAS CHANGED
	USER_HELP_CENTER_CLICK: 'USER_HELP_CENTER_CLICK', // WHEN THE USER CLICK TO HELP CENTER
	USER_HELP_OPEN_CLICK: 'USER_HELP_OPEN_CLICK',
	USER_LOGGED_IN: 'USER_LOGGED_IN',
	USER_LOGGED_OUT: 'USER_LOGGED_OUT',
	USER_MANAGE_COOKIES_CLICK: 'USER_MANAGE_COOKIES_CLICK', // WHEN THE USER CLICK TO MANAGE ITS COOKIES PREFERENCES
	USER_SIMPLE_SEARCH_CLICK_RESULT: 'USER_SIMPLE_SEARCH_CLICK_RESULT', // WHEN USER CLICK ON A RESULT FROM SIMPLE SEARCH FIELD
	USER_SIMPLE_SEARCH_GET_NO_RESULT: 'USER_SIMPLE_SEARCH_GET_NO_RESULT', // WHEN USER GET NO RESULT WITH HIS SEARCH FROM SIMPLE SEARCH FIELD
	USER_SIMPLE_SEARCH_GET_RESULTS: 'USER_SIMPLE_SEARCH_GET_RESULTS', // WHEN USER GET AT LEAST 1 RESULT WITH HIS SEARCH FROM SIMPLE SEARCH FIELD
	USER_TRAINING_WORKSHOP_CLICK: 'USER_TRAINING_WORKSHOP_CLICK', // WHEN THE USER CLICK TO TRAINING WORKSHOP
};
export default EVENTS;
