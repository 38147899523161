import * as React from 'react';

// ENUM
import {
	EnumFontStyle,
} from '@enums/font.enum';

// COMPONENTS
import Icon from '@components/icon';

// STYLES
import styles from './feature.module.scss';

interface FeatureProps {
	icon: string;
	iconStyle?: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
	children: React.ReactNode;
	className?: string;
	'data-testid'?: string;
}

const Feature = ({
	icon,
	iconStyle = EnumFontStyle.LIGHT,
	className,
	children,
	'data-testid': dataTestid,
}: FeatureProps): JSX.Element => {

	const cssClasses = [
		styles.feature,
	];

	if (className) {
		cssClasses.push(className);
	}

	const iconElement = (
		<Icon
			className={styles.icon}
			data-testid={`${dataTestid}-icon`}
			fontStyle={iconStyle}
			name={icon}
		/>
	);

	const childrenElement = (
		<div
			data-testid={`${dataTestid}-children`}
		>
			{children}
		</div>
	);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{iconElement}
			{childrenElement}
		</div>
	);
};

export {
	Feature as default,
	FeatureProps,
};

