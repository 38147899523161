export interface AreaJson {
	value: number;
	unit?: string;
}

export interface AreaSizeJson {
	max: AreaJson;
	min: AreaJson;
}

export class Area {
	public areaJson: AreaJson;

	constructor(areaJson: AreaJson) {
		this.areaJson = areaJson;
	}

	get value(): number {
		return this.areaJson.value;
	}
	get unit(): string | undefined {
		return this.areaJson?.unit;
	}
}
