import * as React from 'react';
import {
	ReactNode,
} from 'react';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// STYLES
import styles from './text.module.scss';

interface TextProps {
	children?: ReactNode;
	className?: string;
	'data-testid'?: string;
	ellipsis?: boolean;
	theme?: EnumStatusTheme;
}

const Text = ({
	children,
	className,
	'data-testid': dataTestid,
	ellipsis,
	theme,
}: TextProps): JSX.Element => {

	const cssClasses = [
		styles.text
	];
	if (ellipsis) cssClasses.push(styles.ellipsis);
	if (className) cssClasses.push(className);
	if (theme) cssClasses.push(styles[`${'theme__' + theme}`]);

	return (
		<span
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{children}
		</span>
	);
};

export {
	Text as default,
	TextProps,
};
